import { Logo as DsLogo } from '@change/tomato-core-ui';

import { useLogo } from './hooks/useLogo';

export const Logo: React.FC = () => {
	const {
		data: { logoUrl },
	} = useLogo();

	return (
		<>
			<div className="hidden md:block">
				<DsLogo
					src={logoUrl}
					href="/?redirect=false"
					color="primary"
					size="small"
					aria-label="Change.org"
					qaAttribute="header-home-link"
				/>
			</div>

			<div className="block md:hidden">
				<DsLogo
					src={logoUrl}
					href="/?redirect=false"
					color="primary"
					size="x-small"
					aria-label="Change.org"
					qaAttribute="header-home-link"
				/>
			</div>
		</>
	);
};
