import type { ErrorReporter } from '@change-corgi/core/errorReporter/common';
import type { FcmUtils } from '@change-corgi/core/fcm';
import { createFcmUtils as _createFcmUtils } from '@change-corgi/core/fcm';
import type { GqlClient } from '@change-corgi/core/gql';

import type { Session } from 'src/shared/session';

type Options = Readonly<{
	gql: GqlClient;
	session: Session;
	errorReporter: ErrorReporter;
}>;

export function createFcmUtils({ gql, session, errorReporter }: Options): FcmUtils {
	const {
		country: { countryCode },
		locale: { localeCode: locale },
	} = session;

	return _createFcmUtils({
		gqlFetch: gql.fetch.bind(gql),
		reportError: errorReporter.report.bind(errorReporter),
		locale,
		countryCode,
		logError: process.env.NODE_ENV === 'development',
	});
}
