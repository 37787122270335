import { useCallback, useEffect, useState } from 'react';

import { createStorageEventsHandler } from './events';

type StorageUtilsHooks = Readonly<{
	useStorageCheck: () => void;
	useStorageValueBase: (key: string) => [string | null, (value: string | null) => void];
	useStorageValueAsyncBase: () => [string | null | undefined, (value: string | null) => void];
	useStorageValueSetter: (key: string) => (value: string | null) => void;
	useListener: (key: string, setValue: (value: string | null) => void) => void;
}>;

export function createStorageUtilsHooks(storage: Storage | undefined | null): StorageUtilsHooks {
	const eventsHandler = createStorageEventsHandler(storage || undefined);

	return {
		useStorageCheck: () => {
			if (storage === undefined)
				throw new Error('Storage not available in SSR env - use xxxAsync version of hook instead');
		},
		useStorageValueBase: (key: string) => {
			const [value, setValue] = useState(storage ? storage.getItem(key) : null);
			const setNullValue = useCallback(() => setValue(null), []);
			return [value, storage ? setValue : setNullValue];
		},
		useStorageValueAsyncBase: () => {
			const [value, setValue] = useState<string | null | undefined>(undefined);
			const setNullValue = useCallback(() => setValue(null), []);
			return [value, storage ? setValue : setNullValue];
		},
		useStorageValueSetter: (key: string) =>
			useCallback(
				(val: string | null) => {
					if (!storage) return;
					if (val === null) {
						storage.removeItem(key);
					} else {
						storage.setItem(key, val);
					}
					eventsHandler.trigger(key);
				},
				[key],
			),
		useListener: (key: string, setValue: (value: string | null) => void) => {
			useEffect(() => {
				setValue(storage ? storage.getItem(key) : null);
				const listener = () => setValue(storage ? storage.getItem(key) : null);
				return eventsHandler.subscribe(key, listener);
			}, [key, setValue]);
		},
	};
}
