import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { PetitionStarterFreePromotionsQuery, PetitionStarterFreePromotionsQueryVariables } from './api.graphql';
import { PetitionStarterFreePromotions } from './api.graphql';

export type PetitionStarterFreePromotionsResponse = NonNullable<
	NonNullable<PetitionStarterFreePromotionsQuery['petition']>['freePromotions']
>;

export async function getPetitionStarterFreePromotions(
	utilityContext: UtilityContext,
	{ slugOrId }: { slugOrId: string },
): Promise<PetitionStarterFreePromotionsResponse | undefined> {
	const {
		gql: { fetch },
	} = utilityContext;

	try {
		const { data } = await fetch<PetitionStarterFreePromotionsQuery, PetitionStarterFreePromotionsQueryVariables>({
			query: PetitionStarterFreePromotions,
			variables: { slugOrId },
			rejectOnError: false,
		});

		if (
			!data ||
			!data.petition ||
			!data.petition.freePromotions ||
			data.petition.freePromotions.length === 0 ||
			!data.petition.freePromotions.find((promotion) => promotion.sourceLocation === 'auto_free_promotion')
		) {
			return undefined;
		}

		return data.petition.freePromotions;
	} catch {
		// do nothing, we don't care if this fails
		return undefined;
	}
}
