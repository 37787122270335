import { FCM_DS2_EXPERIMENT } from '@change-corgi/fcm/configs';

import { createExperimentFcmConfig } from 'src/app/shared/hooks/experiment';

export const FCM_EXPERIMENT_DS2 = createExperimentFcmConfig({
	fcmConfigs: {
		config: FCM_DS2_EXPERIMENT,
	},
	experimentName: ({ fcm: { config } }) => config?.experimentName,
	isEnabled: ({ fcm: { config } }) => {
		return Boolean(config?.experimentName);
	},
});
