import type { Locale } from '@change-corgi/config/locales';

import type { LocaleResolverContext } from 'src/shared/routes';

import { getPetitionLocale } from './api/petitionLocale';

export async function resolvePetitionL10nForBotsFromSlugParam({
	params: { slug },
	utilities,
	isBot,
}: LocaleResolverContext): Promise<{ locale?: Locale; countryCode?: string } | undefined> {
	if (!isBot()) return undefined;
	return {
		// FIXME: should we do the same for the country code?
		locale: await getPetitionLocale(slug, utilities),
	};
}
