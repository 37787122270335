import { useI18n } from '@change-corgi/core/react/i18n';
import { Button } from '@change-corgi/design-system/components/actions';
import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import type { PageRenderingButton, SupporterVideo } from '../../shared/types';

import { useLikeVideo } from './hooks/useLikeVideo';
import { LikeIcon, UnLikeIcon } from './LikeVideoIcons';

type LikeVideoProps = {
	video: SupporterVideo;
	petitionId: string;
	renderedOn: PageRenderingButton;
};

export function LikeVideo({ video, petitionId, renderedOn }: LikeVideoProps): React.JSX.Element | null {
	const {
		data: { isVideoLiked, videoLikeCount, isGuest },
		actions: { handleLikeClick },
	} = useLikeVideo({ video, petitionId, renderedOn });

	const { translate } = useI18n();

	if (isGuest) {
		return null;
	}

	const ariaLabel = isVideoLiked
		? translate('corgi.components.petition_video.actions.aria_label.unlike_video', {
				user_name: video.user?.shortDisplayName ?? '',
			})
		: translate('corgi.components.petition_video.actions.aria_label.like_video', {
				user_name: video.user?.shortDisplayName ?? '',
			});

	return (
		<Button
			size="small"
			aria-label={ariaLabel}
			data-testid={`supporter-video-${video.id}-like-button-combo`}
			sx={{
				border: 'none',
				background: 'primary-greyBackground',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:hover': {
					background: 'rgba(0,0,0,0.1)',
				},
			}}
			onClick={handleLikeClick}
		>
			<Flex sx={{ alignItems: 'center' }}>
				{isVideoLiked ? <UnLikeIcon videoId={video.id} /> : <LikeIcon videoId={video.id} />}
				<Text
					fontWeight="bold"
					size="small"
					data-testid={`supporter-video-${video.id}-like-count`}
					ml={8}
					color={isVideoLiked ? 'primary-changeRed' : 'primary-black'}
				>
					{videoLikeCount}
				</Text>
			</Flex>
		</Button>
	);
}
