import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	BanditsSharedRewardBandit,
	type BanditsSharedRewardBanditMutation,
	type BanditsSharedRewardBanditMutationVariables,
} from './reward.graphql';

type Input = {
	experimentName: string;
	variantName: string;
	increment?: number;
};

export async function rewardBandit(
	{ experimentName, variantName, increment }: Input,
	{ gql: { fetch } }: UtilityContext,
	{
		silent,
	}: {
		// if true, won't throw on error
		silent?: boolean;
	} = {},
): Promise<void> {
	let data: BanditsSharedRewardBanditMutation | null | undefined;
	try {
		const result = await fetch<BanditsSharedRewardBanditMutation, BanditsSharedRewardBanditMutationVariables>({
			query: BanditsSharedRewardBandit,
			variables: {
				input: {
					banditId: experimentName,
					variantName,
					increment: increment || null,
					rewardType: 'DEFAULT',
				},
			},
			rejectOnError: false,
			batched: false,
		});
		({ data } = result);
	} catch (err) {
		if (silent) return;
		throw new Error('Bandit reward failed');
	}

	if (silent) return;
	if (!data?.rewardBandit) {
		throw new Error('Bandit reward failed');
	}
}
