import { Translate } from '@change-corgi/core/react/i18n';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

export function Content(): React.JSX.Element {
	return (
		<Flex sx={{ flexDirection: 'column', gap: 16 }}>
			<Box>
				<Text fontWeight="bold" sx={{ fontSize: 24 }}>
					<Translate value="corgi.app_download.bottom_banner.headline" />
				</Text>
			</Box>
			<Translate value="corgi.app_download.bottom_banner.description" />
			<Text fontWeight="bold">
				<Translate value="corgi.app_download.bottom_banner.ask" />
			</Text>
		</Flex>
	);
}
