import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	RestrictedAccessPetitionDecisionMaker,
	type RestrictedAccessPetitionDecisionMakerQuery,
	type RestrictedAccessPetitionDecisionMakerQueryVariables,
} from './isDecisionMaker.graphql';

export async function getIsPetitionDecisionMaker(
	utilities: Pick<UtilityContext, 'gql'>,
	slugOrId: string,
	decisionMakerHash?: string,
): Promise<boolean> {
	const { data } = await utilities.gql.fetch<
		RestrictedAccessPetitionDecisionMakerQuery,
		RestrictedAccessPetitionDecisionMakerQueryVariables
	>({
		query: RestrictedAccessPetitionDecisionMaker,
		variables: { slugOrId, decisionMakerHash: decisionMakerHash || '', fetchDmByHash: !!decisionMakerHash },
		rejectOnError: true,
	});

	if (decisionMakerHash) {
		return !!data?.petition?.viewerIsDecisionMaker || !!data?.petition?.decisionMakerByHash?.id;
	}

	return !!data?.petition?.viewerIsDecisionMaker;
}
