import { useCallback, useMemo } from 'react';

import { useIsFirstAppRender } from '@change-corgi/core/react/ssr/render';
import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import type { StateWithoutError } from 'src/app/shared/utils/async';

function createValueOrThrowIfNoExperimentsHook<V, P extends unknown[]>(
	valueGetter: (utilityContext: UtilityContext, ...args: P) => V,
) {
	return (...args: P) => {
		const utilityContext = useUtilityContext();
		try {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			const value = useMemo(() => valueGetter(utilityContext, ...args), [utilityContext, valueGetter, ...args]);
			// the proper throw is handled in the catch
			if (!utilityContext.experiments.getAll()) throw new Error();
			return value;
		} catch (e) {
			// valueGetter can throw when experiments are not available
			if (!utilityContext.experiments.getAll()) {
				throw new Error(
					'Experiments not available in SSR env with user data disabled - use xxxAsync version of hook instead',
				);
			}
			throw e;
		}
	};
}

function createValueAsyncHook<V, P extends unknown[]>(valueGetter: (utilityContext: UtilityContext, ...args: P) => V) {
	return (...args: P): StateWithoutError<{ value: V }> => {
		const utilityContext = useUtilityContext();
		const isFirstAppRender = useIsFirstAppRender();
		/* eslint-disable react-hooks/exhaustive-deps */
		const getRealValue = useCallback(
			() => valueGetter(utilityContext, ...args),
			[utilityContext, valueGetter, ...args],
		);
		/* eslint-enable react-hooks/exhaustive-deps */
		return useMemo(
			() =>
				isFirstAppRender || !utilityContext.experiments.getAll()
					? {
							status: 'loading' as const,
						}
					: { status: 'loaded' as const, value: getRealValue() },
			[isFirstAppRender, utilityContext, getRealValue],
		);
	};
}

function getTreatableExperimentVariation<VARIATION extends string = string>(
	{ experiments }: UtilityContext,
	id: string,
): () => VARIATION {
	const experiment = experiments.get<VARIATION>(id);
	return () => {
		void experiment.treat();
		return experiment.variation;
	};
}
/**
 * Useful to only treat the experiment when necessary
 *
 * @example
 * const getTreatedVariation = useTreatableExperimentVariation<'a' | 'b'>('foo');
 *
 * if (fcmEnabled && getTreatedVariation() === 'a') {
 *   //...
 * }
 */
export const useTreatableExperimentVariation = createValueOrThrowIfNoExperimentsHook(getTreatableExperimentVariation);
export const useTreatableExperimentVariationAsync = createValueAsyncHook(getTreatableExperimentVariation);

function getExperimentVariation<VARIATION extends string = string>({ experiments }: UtilityContext, id: string) {
	return experiments.get<VARIATION>(id).variation;
}
export const useExperimentVariation = createValueOrThrowIfNoExperimentsHook(getExperimentVariation);
export const useExperimentVariationAsync = createValueAsyncHook(getExperimentVariation);

function getTreatedExperimentVariation<VARIATION extends string = string>({ experiments }: UtilityContext, id: string) {
	const experiment = experiments.get<VARIATION>(id);
	void experiment.treat();
	return experiment.variation;
}
export const useTreatedExperimentVariation = createValueOrThrowIfNoExperimentsHook(getTreatedExperimentVariation);
export const useTreatedExperimentVariationAsync = createValueAsyncHook(getTreatedExperimentVariation);
