import { getWindow } from '@change-corgi/core/window';

import type { WebappInfo } from 'src/webappInfo';
import webappInfo from 'src/webappInfo';

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		webappInfo: WebappInfo;
		version: string;
	}
}

export function decorateWindowWithInfo(): void {
	const win = getWindow();

	win.webappInfo = webappInfo;
	win.version = `${webappInfo.name}-${webappInfo.version}`;
}
