import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Link as RouterLink } from '@change-corgi/core/react/router';
import { Separator } from '@change-corgi/design-system/components/content';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconNavigateBefore } from '@change-corgi/design-system/icons';
import { Container, Flex } from '@change-corgi/design-system/layout';
import { linkResetStyles } from '@change-corgi/design-system/reset';

type Props = {
	slug: string;
	onClick?: () => void;
};

export function StarterDashboardNavLink({ slug, onClick }: Props): JSX.Element | null {
	return (
		<>
			<Flex sx={{ alignItems: 'center' }}>
				<Container mx={[0, 'auto']}>
					<RouterLink to={`/p/${slug}/dashboard`} sx={linkResetStyles} onClick={onClick}>
						<Flex sx={{ alignItems: 'center' }} my={16}>
							<Icon icon={iconNavigateBefore} mr={8} size="xxl" />
							<Translate value="fe.components.petition_manage.starter_navigation.back_link" />
						</Flex>
					</RouterLink>
				</Container>
			</Flex>
			<Separator my={0} />
		</>
	);
}
