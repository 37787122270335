import { HOMEPAGE_LOCALES } from 'config/routes-l10n';

import type { AppRoute } from 'src/shared/routes';

import { Homepage } from '../index';

import { resolveShortcutLocales } from './shared/resolveShortcutLocales';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'homepage-v2',
		component: Homepage,
		frame: {
			footer: true,
			header: true,
		},
		restrictedAccess: {
			authOnly: true,
			role: 'staff',
		},
		path: [
			'/v2',
			// react-router 6 removed support of regexs - there's an ongoing discussion to re-add them (https://github.com/remix-run/react-router/discussions/9844)
			// `/v2/:locale(${HOMEPAGE_LOCALES.join('|')})?`,
			...HOMEPAGE_LOCALES.map((locale) => `/v2/${locale}`),
		],
		ssr: {
			enabled: true,
			seo: true,
			resolveL10n: resolveShortcutLocales,
		},
	},
];
