import { useI18n } from '@change-corgi/core/react/i18n';
import { Image } from '@change-corgi/design-system/components/media';
import { Box } from '@change-corgi/design-system/layout';

export function PhoneImage(): React.JSX.Element {
	const { translate } = useI18n();
	return (
		<Box
			sx={{
				minWidth: 245,
				justifyContent: 'center',
				position: 'relative',
				overflow: 'hidden',
				height: 236,
				display: [null, 'none', 'block'],
				marginBottom: -32,
			}}
		>
			<picture sx={{ position: 'absolute', width: '100%' }}>
				<source
					type="image/webp"
					srcSet="https://static.change.org/images/mobile-app-phone-mockup-1x.webp 1x, https://static.change.org/images/mobile-app-phone-mockup-2x.webp 2x"
				/>
				<Image
					src="https://static.change.org/images/mobile-app-phone-mockup-1x.png"
					srcSet="https://static.change.org/images/mobile-app-phone-mockup-1x.png 1x, https://static.change.org/images/mobile-app-phone-mockup-2x.png 2x"
					alt={translate('corgi.app_download.bottom_banner.phone_mockup.image_alt_text')}
					sx={{ minWidth: 229, width: 229 }}
				/>
			</picture>
		</Box>
	);
}
