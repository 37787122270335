import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'local-v2',
		path: '/r/:slug',
		component: loadable(async () => import(/* webpackChunkName: "pages/local_v2" */ './index'), {
			exportName: 'Local',
			fallback: Loading,
		}),
		frame: true,
		ssr: {
			enabled: true,
			seo: true,
		},
	},
];
