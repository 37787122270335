import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'membership-ppmu-thankYou',
		path: '/s/:membershipSlug/post_promote_thank_you',
		component: loadable(async () => import(/* webpackChunkName: "pages/membership_ppmu_thankYou" */ './index'), {
			exportName: 'ThankYou',
			fallback: Loading,
		}),
		frame: {
			header: true,
			footer: true,
		},
		ssr: {
			enabled: true,
			seo: false,
		},
	},
];
