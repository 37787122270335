import { useCallback, useMemo } from 'react';

import type { PerimeterXEnforcerChallenge } from '@change-corgi/core/perimeterx';
import { useTracking } from '@change-corgi/core/react/tracking';
import type { TrackingProperties } from '@change-corgi/tracking-properties';

type Result = Readonly<{
	trackShow: (operationName?: string) => void;
	trackSuccess: (pxResponse: PerimeterXEnforcerChallenge, operationName?: string) => void;
	trackFailure: (pxResponse: PerimeterXEnforcerChallenge, operationName?: string) => void;
}>;

export function usePerimeterXTracking(form: string): Result {
	const track = useTracking();

	const getProps = useCallback(
		(pxResponse: PerimeterXEnforcerChallenge, operationName?: string): TrackingProperties => ({
			form,
			operation_name: operationName,
			pxRefId: pxResponse.uuid,
			pxVid: pxResponse.vid,
		}),
		[form],
	);

	return useMemo(
		() => ({
			trackShow: (operationName) => {
				void track('px_captcha_shown', {
					form,
					operation_name: operationName,
				});
			},
			trackSuccess: (pxResponse: PerimeterXEnforcerChallenge, operationName?: string) => {
				void track('px_captcha_solved', getProps(pxResponse, operationName));
			},
			trackFailure: (pxResponse: PerimeterXEnforcerChallenge, operationName?: string) => {
				void track('px_captcha_failed', getProps(pxResponse, operationName));
			},
		}),
		[form, getProps, track],
	);
}
