import { FCM_COUNTRY_BANNER } from '@change-corgi/fcm/configs';

import { createFcmFunctions } from 'src/app/shared/hooks/fcm';

export const { useFcm, prefetchFcm } = createFcmFunctions(
	{
		banner: FCM_COUNTRY_BANNER,
	},
	{ hook: true, prefetch: true },
);
