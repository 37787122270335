import { loadable } from '@change-corgi/core/react/loadable';

import { HOMEPAGE_LOCALES } from 'config/routes-l10n';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

import { dashboardStarterRedirect } from './shared/dashboardStarterRedirect';
import { resolveShortcutLocales } from './shared/resolveShortcutLocales';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'homepage',
		path: [
			// this path is on purpose, to make sure that route regex generation include ^\/$
			'/',
			// react-router 6 removed support of regexs - there's an ongoing discussion to re-add them (https://github.com/remix-run/react-router/discussions/9844)
			// `/:locale(${HOMEPAGE_LOCALES.join('|')})?`,
			...HOMEPAGE_LOCALES.map((locale) => `/${locale}`),
		],
		component: loadable(async () => import(/* webpackChunkName: "pages/homepage" */ '../entryPoint/HomepageWrapper'), {
			exportName: 'HomepageWrapper',
			fallback: Loading,
		}),
		beforeServerRender: dashboardStarterRedirect,
		frame: true,
		ssr: {
			enabled: true,
			seo: true,
			resolveL10n: resolveShortcutLocales,
			config: ({ loginState }) => {
				if (loginState === 'GUEST') {
					return {
						disallowUserData: true,
						cache: {
							enabled: true,
							lifetimeInSeconds: 5 * 60, // 5min
							queryParams: ['petition_not_found'],
						},
					};
				}
				// avoid caching for non-guest
				// mainly so that starters see their changes right away
				return {};
			},
		},
	},
];
