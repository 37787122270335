import type { TrackingFn } from '@change-corgi/core/react/tracking';
import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import { openWindow } from '@change-corgi/core/window';

import { copyToClipboard } from 'src/app/shared/utils/clipboard';

import { trackShareSuccess } from './track';

type Options = {
	trackingData: Record<string, string | number | boolean | undefined>;
	trackingEvents: {
		success: string;
	};
};

// eslint-disable-next-line max-params
export async function shareReddit(
	title: string,
	url: string,
	{ trackingEvents, trackingData }: Options,
	track: TrackingFn,
	i18nContext: UtilityContext['i18n'],
): Promise<void> {
	await copyToClipboard(url, i18nContext);
	const redditUrl = `https://www.reddit.com/submit?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`;
	openWindow(redditUrl);
	trackShareSuccess(trackingEvents.success, trackingData, track);
}
