import { Box, Container, Flex } from '@change-corgi/design-system/layout';
import { derivedStyle } from '@change-corgi/design-system/theme';

import { useBanner } from '../shared/hooks/useBanner';

import { ChangeIcon } from './components/ChangeIcon';
import { Content } from './components/Content';
import { DismissButton } from './components/DismissButton';

type Props = {
	sourceLocation: string;
	setBannerClosed: () => void;
};

export function IOSBanner({ sourceLocation, setBannerClosed }: Props): React.JSX.Element | null {
	const {
		actions: { onClickClose, onClickDownload },
	} = useBanner({ sourceLocation, setBannerClosed });

	return (
		<Box
			id="app-download-bottom-banner"
			sx={{
				display: ['block', 'none'],
				position: 'fixed',
				backgroundColor: '#FAF7ED',
				boxShadow: '0 0 1px 1px rgb(0 0 0 / 20%)',
				padding: 16,
				paddingBottom: 0,
				width: '100%',
				bottom: 0,
				left: 0,
				zIndex: derivedStyle(({ zIndices }) => zIndices.cookieWall - 1),
			}}
			data-testid="app-download-bottom-banner"
		>
			<DismissButton onClick={onClickClose} />
			<Container>
				<Flex
					sx={{
						display: 'grid',
						gridTemplateColumns: 'auto 1fr auto',
						gap: 16,
						alignItems: 'flex-start',
						marginRight: 32,
					}}
				>
					<ChangeIcon />
					<Content onClickDownload={onClickDownload} />
				</Flex>
			</Container>
		</Box>
	);
}
