import {
	FCM_CAMPAIGN_ACTIONS_PAGE_CONFIG,
	FCM_COFFEE_GEO_PROFILE_QUALITY,
	FCM_COPY_LINK_ON_UNSIGNED,
	FCM_CREAM_PETITION_UPDATE_PROMOTION_ASK,
	FCM_DISABLE_GEOLOCATION_GUEST_SIGN_FORM,
	FCM_DM_STATUS,
	FCM_DOUBLE_OPT_IN_ENABLED,
	FCM_ENABLE_PETITION_STRENGTH_TIPS,
	FCM_GDPR_SIGN_OPT_IN,
	FCM_GDPR_SIGN_OPT_IN_COPY,
	FCM_HIDE_SIGNEES,
	FCM_MOBILE_APP_DOWNLOAD_BOTTOM_BANNER_ENABLED,
	FCM_PETITION_DISPLAY_RECRUITER_NAME,
	FCM_PETITION_FEED_ACCESS_CONFIG,
	FCM_PETITION_PAGE_CONTACT_DM,
	FCM_PETITION_PAGE_DM_CARD_CONFIG,
	FCM_PETITION_PAGE_NEWLY_CREATED_PETITIONS,
	FCM_PETITION_PAGE_TOP_SUPPORTERS,
	FCM_PETITION_SHOW_DISPLAY_CHAT_DAYS_POST_PUBLISH,
	FCM_PETITION_STARTER_MEDIA_INQUIRY_CONFIG,
	FCM_PETITIONS_MEDIA_HITS_CONFIG,
	FCM_PSF_REASON_FOR_SIGNING,
	FCM_REVENUE_VICTORIOUS_PETITION_PROMOTION_ENABLED,
	FCM_SAP_CTA_ON_PETITION_SHOW,
	FCM_SIGN_INTENT_VALID_SAMPLE_RATE,
	FCM_SIGN_VALIDATION_ERROR_SAMPLE_RATE,
	FCM_SIGNATURES_DISTRIBUTION_CONFIG,
	FCM_STARFISH_STARTER_DM_RECOMMENDER,
	FCM_STICKY_SIGNATURE_BLOCK,
	FCM_SUPPORTER_VOICES_DETAILS_PAGE,
} from '@change-corgi/fcm/configs';

export const configs = {
	sapCtaEnabled: FCM_SAP_CTA_ON_PETITION_SHOW,
	unsignedCopyLinkEnabled: FCM_COPY_LINK_ON_UNSIGNED,
	signeesHidden: FCM_HIDE_SIGNEES,
	statsTopSupportersEnabled: FCM_PETITION_PAGE_TOP_SUPPORTERS,
	statsRecruiterNameEnabled: FCM_PETITION_DISPLAY_RECRUITER_NAME,
	signValidationErrorTrackSampleRate: FCM_SIGN_VALIDATION_ERROR_SAMPLE_RATE,
	signValidationIntentValidTrackSampleRate: FCM_SIGN_INTENT_VALID_SAMPLE_RATE,
	storeReasonForSigning: FCM_PSF_REASON_FOR_SIGNING,
	promotionCtaEnabled: FCM_CREAM_PETITION_UPDATE_PROMOTION_ASK,
	chatDisplayDaysPostPublish: FCM_PETITION_SHOW_DISPLAY_CHAT_DAYS_POST_PUBLISH,
	suggestedVictoriousPetitionsEnabled: FCM_REVENUE_VICTORIOUS_PETITION_PROMOTION_ENABLED,
	strengthTipsEnabled: FCM_ENABLE_PETITION_STRENGTH_TIPS,
	campaignActionsPageConfig: FCM_CAMPAIGN_ACTIONS_PAGE_CONFIG,
	supporterVoicesDetailsPageConfig: FCM_SUPPORTER_VOICES_DETAILS_PAGE,
	petitionsMediaHitsConfig: FCM_PETITIONS_MEDIA_HITS_CONFIG,
	signaturesDistributionConfig: FCM_SIGNATURES_DISTRIBUTION_CONFIG,
	starfishStarterDmRecommender: FCM_STARFISH_STARTER_DM_RECOMMENDER,
	contactDMConfig: FCM_PETITION_PAGE_CONTACT_DM,
	geolocGuestDisabled: FCM_DISABLE_GEOLOCATION_GUEST_SIGN_FORM,
	geoFieldsEnabled: FCM_COFFEE_GEO_PROFILE_QUALITY,
	gdprOptInEnabled: FCM_GDPR_SIGN_OPT_IN,
	gdprOptInCopy: FCM_GDPR_SIGN_OPT_IN_COPY,
	doubleOptInEnabled: FCM_DOUBLE_OPT_IN_ENABLED,
	newlyCreatedPetitionsEnabled: FCM_PETITION_PAGE_NEWLY_CREATED_PETITIONS,
	dmStatusConfig: FCM_DM_STATUS,
	petitionStarterMediaInquiryConfig: FCM_PETITION_STARTER_MEDIA_INQUIRY_CONFIG,
	dmCardConfig: FCM_PETITION_PAGE_DM_CARD_CONFIG,
	petitionFeedAccess: FCM_PETITION_FEED_ACCESS_CONFIG,
	appDownloadBottomBannerEnabled: FCM_MOBILE_APP_DOWNLOAD_BOTTOM_BANNER_ENABLED,
	stickySignatureBlockEnabled: FCM_STICKY_SIGNATURE_BLOCK,
};
