import type { FormValues, NormalizedFormValues } from 'src/app/pages/petitionGamma/details/shared/types';

import { isStateRequired } from '../isStateRequired';

export function normalizeStateCode({
	countryCode,
	stateCode,
}: Pick<FormValues, 'countryCode' | 'stateCode'>): NormalizedFormValues['stateCode'] {
	return (isStateRequired({ countryCode }) && stateCode) || null;
}
