import type { ErrorReporter } from '@change-corgi/core/errorReporter/common';
import type { HttpClient } from '@change-corgi/core/http';
import type { Translations } from '@change-corgi/core/i18n';
import { getTranslations as _getTranslations } from '@change-corgi/core/i18n';

import { getServerTranslationsUrl } from './getServerTranslationsUrl';

export type GetTranslationsOptions = {
	getAsset: HttpClient['getAsset'];
	reportError: ErrorReporter['report'];
	/**
	 * Whether to retrieve translations from the front-end server instead of the S3 bucket directly
	 * This is useful for:
	 * - i18n phrases filtering (to remove phrases not used in the front-end)
	 * - en-US reference fallback (while waiting for phrases to be on OneSky)
	 */
	useServerTranslations: boolean;
	cacheBuster?: string;
};

export async function getTranslations(
	locale: string,
	{ getAsset, reportError, useServerTranslations, cacheBuster }: GetTranslationsOptions,
): Promise<Translations> {
	// if translations are filtered, attempt to get filtered translations, falling back to default translations
	return _getTranslations(locale, {
		getAsset,
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		reportError,
		cacheBuster,
		i18nUrl: useServerTranslations ? getServerTranslationsUrl(locale, cacheBuster) : undefined,
	});
}
