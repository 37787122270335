import qs from 'qs';

import type { AppRouteBeforeServerRenderContext } from 'src/shared/routes';

import { getTopicSlug } from '../api/topics';

export const handleTopicsRedirect = async ({
	utilities,
	redirect,
	params: { slug },
	query,
}: AppRouteBeforeServerRenderContext) => {
	const { slug: topicSlug } = await getTopicSlug(utilities, slug);
	const queryString = qs.stringify(query, { addQueryPrefix: true });

	// NOTE: If updating this link also update: apps/front-end/src/app/pages/tag/layout/hooks/useTopicRedirect/index.ts
	if (topicSlug && topicSlug.length > 0) {
		redirect(`/topic/${slug}${queryString}`, true);
	}
};
