import React, { useMemo } from 'react';

import { Helmet } from 'react-helmet-async';

import { getHrefLang, getLocales } from '@change-corgi/config/locales';

export const HrefLangsHomepage: React.FC = () => {
	const hrefLangs = useMemo(() => getLocales('nonBeta').map((locale) => getHrefLang(locale)), []);

	return (
		<Helmet>
			<link rel="alternate" hrefLang="en-US" href="https://www.change.org/" />
			{hrefLangs.map((hrefLang) => {
				// Skip en-US as it is best practice primary market first
				if (hrefLang === 'en-US') return null;

				return <link key={hrefLang} rel="alternate" hrefLang={hrefLang} href={`https://www.change.org/${hrefLang}`} />;
			})}
			<link rel="alternate" hrefLang="x-default" href="https://www.change.org/" />
		</Helmet>
	);
};
