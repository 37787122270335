import type { JSX } from 'react';

import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, RouterProvider } from 'react-router';

import { StaticPageStateProvider } from 'src/shared/pageState';

import { ErrorBoundary } from 'src/app/app/error';

import { ErrorApp, ErrorInnerApp } from './ErrorApp';
import type { AppOptions } from './types';

export function createClientErrorApp(options: AppOptions): () => JSX.Element | null {
	const { hasMainTranslations } = options.utilities.i18n;

	const pageStateContext = { state: {} };

	if (options.ssr) {
		throw new Error('Invalid AppOptions');
	}

	options.utilities.googleAnalytics.ga('send', 'event', 'errors', '500 page');

	const cache = options.cache || {
		fcm: { cache: {}, queue: [] },
		shortUrls: { cache: {}, queue: [] },
	};

	const router = createBrowserRouter([
		{
			id: 'root',
			element: <ErrorApp hasTranslations={hasMainTranslations} {...options} cache={cache} />,
			ErrorBoundary: () => {
				return (
					<ErrorApp hasTranslations={hasMainTranslations} {...options} cache={cache}>
						<ErrorInnerApp hasTranslations={hasMainTranslations} {...options} />
					</ErrorApp>
				);
			},
			children: [
				{
					path: '/*',
					element: <ErrorInnerApp hasTranslations={hasMainTranslations} {...options} />,
					ErrorBoundary,
				},
			],
		},
	]);

	return function RoutedError(): JSX.Element | null {
		return (
			<StaticPageStateProvider context={pageStateContext}>
				<HelmetProvider>
					<RouterProvider router={router} />
				</HelmetProvider>
			</StaticPageStateProvider>
		);
	};
}
