import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	LikeSupporterVideo,
	type LikeSupporterVideoMutation,
	type LikeSupporterVideoMutationVariables,
} from './index.graphql';

export const likeSupporterVideo = async ({
	videoId,
	like,
	utilityContext: {
		gql: { fetch },
	},
}: {
	videoId: string;
	like: boolean;
	utilityContext: UtilityContext;
}): Promise<LikeSupporterVideoMutation | null | undefined> => {
	const { data: likeResult } = await fetch<LikeSupporterVideoMutation, LikeSupporterVideoMutationVariables>({
		query: LikeSupporterVideo,
		variables: {
			input: {
				commentId: videoId,
				like,
			},
		},
		rejectOnError: false,
	});
	return likeResult;
};
