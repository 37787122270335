import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { PetitionInput } from 'src/app/pages/startAPetition/pageContext';

import { pollPetitionStarterFreePromotions } from './api/petitionStarterFreePromotions';
import type {
	SapCreatePublishedPetitionMutation,
	SapCreatePublishedPetitionMutationVariables,
} from './createPublishedPetition.graphql';
import { SAPCreatePublishedPetition } from './createPublishedPetition.graphql';
import { SapPetitionSaveError } from './errors';
import { schema } from './schema';

type CreatePublishedPetitionParams = { petition: PetitionInput; isKickstartExperimentEnabled: boolean };
type CreatePublishedPetitionReturn = Extract<
	SapCreatePublishedPetitionMutation['createPublishedPetition'],
	{ __typename: 'CreatePublishedPetitionSuccess' }
>['petition'];

async function delay(ms: number): Promise<void> {
	// eslint-disable-next-line promise/avoid-new
	return new Promise((resolve) => {
		setTimeout(resolve, ms);
	});
}
export async function createPublishedPetition(
	{ petition, isKickstartExperimentEnabled }: CreatePublishedPetitionParams,
	utilityContext: UtilityContext,
): Promise<CreatePublishedPetitionReturn> {
	const loadingStartTime = Date.now();
	const {
		gql: { fetch },
		errorReporter,
	} = utilityContext;

	try {
		const { data: petitionInput, error: validationError } = schema.safeParse(petition);

		// creating a new error with a custom message instead of using ZodError so we can easily search in logs
		if (validationError) throw new SapPetitionSaveError('InvalidInput', { cause: validationError });

		const [{ data }] = await Promise.all([
			fetch<SapCreatePublishedPetitionMutation, SapCreatePublishedPetitionMutationVariables>({
				query: SAPCreatePublishedPetition,
				variables: {
					input: petitionInput,
				},
				rejectOnError: true,
			}),
			// stay on the loading screen for 5 seconds post publish
			delay(isKickstartExperimentEnabled ? 0 : 5000),
		]);

		if (!data) throw new SapPetitionSaveError('NoResponseData');

		if (data.createPublishedPetition.__typename !== 'CreatePublishedPetitionSuccess') {
			const typename = data.createPublishedPetition.__typename;
			throw new SapPetitionSaveError(typename);
		}

		// Poll for auto free promotions after petition creation
		// this call also handles the post publish loading screen
		if (isKickstartExperimentEnabled)
			await pollPetitionStarterFreePromotions({
				utilityContext,
				slugOrId: data.createPublishedPetition.petition.id,
				pollingStartTime: loadingStartTime,
				data: undefined,
			});

		return data.createPublishedPetition.petition;
	} catch (err) {
		const error = err instanceof SapPetitionSaveError ? err : new SapPetitionSaveError(undefined, { cause: err });
		void errorReporter.report({ error });
		throw error;
	}
}
