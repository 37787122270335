import type { JSX } from 'react';
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import type { DesignSystemStyleObject } from '@change-corgi/design-system/theme';

import { parseSourceInfoFromUrl } from '../../shared/utils/videoSource';

import { VideoStateIndicator } from './components/VideoStateIndicator';
import { useResponsiveVideo } from './hooks/useResponsiveVideo';

type VideoProps = React.ClassAttributes<HTMLVideoElement> & React.VideoHTMLAttributes<HTMLVideoElement>;
type AllowedVideoProps = Omit<
	VideoProps,
	'src' | 'ref' | 'style' | 'sx' | 'preload' | 'onLoadedMetadata' | 'height' | 'width'
>;

type Props = {
	/**
	 * The name of the user who created the video is displayed in the accessibility label.
	 */
	creator?: string;
	/**
	 * The id of the comment record where the video is stored.
	 */
	videoId: string;
	/**
	 * For now the videoUrl is passed directly to the `src` and must be a MP4 or WEBM, but we plan to
	 * try other options like cloudflare HLS/DASH streaming.
	 */
	videoUrl: string;
} & AllowedVideoProps;

/**
 * Normally you can get away with applying a border radius to wrapper element and use `overflow:
 * hidden` to mask any children that would have corners. However, this element needs to adjust its
 * sizing based on the media elements it contains. Using `overflow: hidden` would prevent the
 * children from affecting the wrapper's size.
 */
const borderStyle: DesignSystemStyleObject = {
	borderRadius: 10,
};

/**
 * This component wraps a HTMLVideoElement to ensure standardized features:
 *
 * - Sizing: The video will fill the available space while preserving its aspect ratio.
 * - Poster: A poster (thumbnail) image shows a preview of the video and lets us pick a good ratio
 *   before loading the video. Currently only supported for Cloudflare Stream.
 * - Preloading: If the video has a poster image, then we can avoid preloading the video which saves
 *   on streaming costs.
 * - Styling: Rounded border.
 * - Accessibility: Label describing the creator of the video.
 *
 * Note on sizing: This element will not resize when its container (or the window) changes size. It
 * _should_ be possible to handle sizing with plain CSS, but browsers (Safari) are inconsistent. If
 * we want to make this JS solution respond to container size changes, we'd need to use a
 * ResizeObserver.
 */
export const VideoPlayer = forwardRef<HTMLVideoElement, Props>(function VideoPlayer(
	{ creator, videoId, videoUrl, ...videoProps },
	forwardedRef,
): JSX.Element | null {
	const sourceInfo = useMemo(() => parseSourceInfoFromUrl(videoUrl), [videoUrl]);

	// This is a technique that allows this component to control a ref while also
	// exposing the element to its parent.
	const videoRef = useRef<HTMLVideoElement>(null);
	useImperativeHandle(
		forwardedRef,
		() =>
			// React's internals handle creating the ref
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			videoRef.current!,
		[],
	);

	const wrapperRef = useRef<HTMLDivElement>(null);
	const {
		data: { sizeStyle },
		actions: { updateMediaDimensions },
	} = useResponsiveVideo({ wrapperRef });

	const { translate } = useI18n();

	const ariaLabel = creator
		? translate('corgi.components.petition_video.actions.aria_label.video_from_user', {
				user_name: creator,
			})
		: translate('corgi.components.petition_video.actions.aria_label.video_from_petition_supporter');

	return (
		<div
			ref={wrapperRef}
			sx={{
				position: 'relative',
				display: 'flex',
				justifyContent: 'center',
				...sizeStyle,
				...borderStyle,
			}}
		>
			{/* TODO: Caption generation for user-uploaded videos. */}
			{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
			<video
				aria-label={ariaLabel}
				data-testid={`supporter-video-player-${videoId}`}
				onLoadedMetadata={updateMediaDimensions}
				onLoadedData={updateMediaDimensions}
				poster={sourceInfo.poster}
				preload={sourceInfo.preload}
				ref={videoRef}
				src={sourceInfo.src}
				sx={{
					height: '100%',
					maxHeight: '100%',
					maxWidth: '100%',
					aspectRatio: sizeStyle.aspectRatio,
					...borderStyle,
				}}
				{...videoProps}
			/>

			{sourceInfo.poster ? (
				// This element is a placeholder poster. There is no interface for the poster image the
				// `video` loads, so we need to render an `img` and inspect that. This element is
				// effectively hidden once the video renders.
				//
				// eslint-disable-next-line jsx-a11y/alt-text
				<img
					aria-hidden
					data-testid={`supporter-video-player-${videoId}-poster`}
					onLoad={updateMediaDimensions}
					src={sourceInfo.poster}
					sx={{
						display: 'block',
						height: '100%',
						position: 'absolute',
						top: '0',
						zIndex: -1,
						pointerEvents: 'none',
						...borderStyle,
					}}
				/>
			) : null}

			<div
				sx={{
					display: 'flex',
					placeItems: 'center',
					placeContent: 'center',
					height: '100%',
					width: '100%',
					position: 'absolute',
					top: '0',
					pointerEvents: 'none',
				}}
			>
				<VideoStateIndicator videoRef={videoRef} />
			</div>
		</div>
	);
});
