import type { LinkGroup } from './types';
import { useCommunityLinks } from './useCommunityLinks';
import { useCompanyLinks } from './useCompanyLinks';
import { useConnectLinks } from './useConnectLinks';
import { useGuideLinks } from './useGuideLinks';
import { useSupportLinks } from './useSupportLinks';

export function useFooterLinks(): readonly LinkGroup[] {
	return [
		// force multi-line
		useCompanyLinks(),
		useCommunityLinks(),
		useSupportLinks(),
		useConnectLinks(),
		useGuideLinks(),
	].filter((group) => !!group);
}

export type FooterLinkGroup = LinkGroup;
