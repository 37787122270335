import type { Utilities } from '@change-corgi/core/react/utilityContext';

import {
	UserPetitionsAndSigns,
	type UserPetitionsAndSignsQuery,
	type UserPetitionsAndSignsQueryVariables,
} from './utils.graphql';

type UserPetition = NonNullable<UserPetitionsAndSignsQuery['userPetitions']>[0];

type OpenPetitionsResult = {
	petitionCount: number;
	petitionSlug?: string;
};

const CLOSE_PETITION_STATUS: ReadonlyArray<UserPetition['status']> = ['CLOSED', 'VICTORY'];
const NINETY_DAYS = 90 * 24 * 60 * 60 * 1000;

export async function getOpenUserPetitions(
	userId: string,
	{ gql: gqlClient }: Pick<Utilities, 'gql' | 'http'>,
): Promise<OpenPetitionsResult> {
	try {
		const { data } = await gqlClient.fetch<UserPetitionsAndSignsQuery, UserPetitionsAndSignsQueryVariables>({
			query: UserPetitionsAndSigns,
			variables: {
				userId,
				limit: 10,
				last: 1,
			},
			rejectOnError: true,
		});

		if (!data) return { petitionCount: 0 };
		const petitions = getOpenPetitions(data);
		return {
			petitionCount: petitions.length || 0,
			petitionSlug: getPetitionWithRecentActivity(petitions)?.slug,
		};
	} catch (e) {
		return { petitionCount: 0 };
	}
}

function getOpenPetitions(data: UserPetitionsAndSignsQuery): UserPetition[] {
	const today = new Date().getTime();
	if (!data.userPetitions?.length) return [];
	return data.userPetitions.filter((petition) => {
		const differenceInDays = today - new Date(petition.createdAt).getTime();
		return !CLOSE_PETITION_STATUS.includes(petition.status) && differenceInDays < NINETY_DAYS;
	});
}

function getPetitionWithRecentActivity(petitions: UserPetition[]): UserPetition | undefined {
	const petitionsWithActivity = petitions
		.filter((petition) => petition.recentSignersConnection.edges.length)
		.sort((petitionA: UserPetition, petitionB: UserPetition) => {
			const lastSignA = new Date(petitionA.recentSignersConnection.edges[0].timestamp);
			const lastSignB = new Date(petitionB.recentSignersConnection.edges[0].timestamp);
			return lastSignB.getTime() - lastSignA.getTime();
		});
	return (petitionsWithActivity.length ? petitionsWithActivity : petitions)[0];
}
