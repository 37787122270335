import { DesktopBanner } from '../DesktopBanner';
import { IOSBanner } from '../IOSBanner';

import { useBanner } from './hooks/useBanner';

type Props = {
	sourceLocation: string;
};

export const Banner = ({ sourceLocation }: Props): React.JSX.Element | null => {
	const {
		data: { isIPhoneUserAgent, isMobileUserAgent, bannerClosed },
		actions: { setBannerClosed },
	} = useBanner();

	if (bannerClosed) return null;

	return (
		<>
			{isIPhoneUserAgent && <IOSBanner sourceLocation={sourceLocation} setBannerClosed={setBannerClosed} />}
			{!isMobileUserAgent && <DesktopBanner sourceLocation={sourceLocation} setBannerClosed={setBannerClosed} />}
		</>
	);
};
