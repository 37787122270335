import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { SetConsent, type SetConsentMutation, type SetConsentMutationVariables } from './api.graphql';

export type ConsentInput = SetConsentMutationVariables['consentInput'];

export async function setConsent(
	{ text, name, consentGiven }: ConsentInput,
	{ gql: { fetch } }: UtilityContext,
): Promise<boolean> {
	const { data } = await fetch<SetConsentMutation, SetConsentMutationVariables>({
		query: SetConsent,
		variables: {
			consentInput: {
				text, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
				name,
				consentGiven,
			},
		},
		rejectOnError: false,
	});
	return !!data?.respondToConsentRequest.success;
}
