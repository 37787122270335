/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ServerError } from '@apollo/client';

import { GQLResponsePerimeterXChallengeError } from '@change-corgi/core/gql';

import type { SignPetitionResult } from './types';

// TODO error typing?
export function normalizeSignPetitionErrorResponse(response: any): SignPetitionResult {
	if (response instanceof GQLResponsePerimeterXChallengeError) {
		return {
			status: 'error',
			reason: 'perimeterx',
			pxResponse: response.pxChallenge,
		};
	}
	if (typeof response !== 'object' || !(response as ServerError).statusCode) {
		throw response;
	}
	if ((response as ServerError).statusCode === 403) {
		return { status: 'error', reason: 'badSession' };
	}
	throw response;
}
