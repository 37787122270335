import { useI18n } from '@change-corgi/core/react/i18n';
import { Image } from '@change-corgi/design-system/components/media';
import { Flex } from '@change-corgi/design-system/layout';

export function QRCode(): React.JSX.Element {
	const { translate } = useI18n();
	return (
		<Flex sx={{ justifyContent: 'center' }}>
			<picture sx={{ height: 180 }}>
				<source
					type="image/webp"
					srcSet="https://static.change.org/images/mobile-app-qr-code-1x.webp 1x, https://static.change.org/images/mobile-app-qr-code-2x.webp 2x"
				/>
				<Image
					src="https://static.change.org/images/mobile-app-qr-code-1x.png"
					srcSet="https://static.change.org/images/mobile-app-qr-code-1x.png 1x, https://static.change.org/images/mobile-app-qr-code-2x.png 2x"
					alt={translate('corgi.app_download.bottom_banner.qr_code.image_alt_text')}
					sx={{ width: 180 }}
				/>
			</picture>
		</Flex>
	);
}
