import type { AppRouteBeforeServerRenderContext } from 'src/shared/routes';

import { getOpenUserPetitions } from './utils';

export const EXPERIMENT_NAME = 'dashboard_starter_redirect_v1';
export const VARIATIONS = {
	A: 'experience_a',
	B: 'experience_b',
	CONTROL: 'control',
};
const REDIRECT_URLS = {
	getPetitionsPageUrl: () => '/u/me',
	getPetitionDashboardUrl: (slug: string) => `/p/${encodeURIComponent(slug)}/dashboard`,
};

export async function dashboardStarterRedirect({
	getSessionInfo,
	getSessionUtilities,
	query,
	redirect,
	utilities,
}: AppRouteBeforeServerRenderContext): Promise<void> {
	const { loginState, user } = await getSessionInfo();
	const isNotAuthenticated = loginState === 'GUEST';
	const shouldNotRedirect = query.redirect === 'false';
	// Only for authenticated users that have not cliked in the logo to go to Home page
	if (isNotAuthenticated || shouldNotRedirect) {
		return;
	}

	const { experiments } = await getSessionUtilities();
	const { petitionCount, petitionSlug } = await getOpenUserPetitions(user.id, utilities);
	// Treat experiment only if user fulfills criteria (at least one open petition)
	if (!petitionSlug) {
		return;
	}

	// Experimentation block
	// -------------
	// Experiments.get can return a dummy expriment with variation control in case the experiment does not exist or it is paused
	const dashboardStarterRedirectExperiment = experiments.get(EXPERIMENT_NAME);
	void dashboardStarterRedirectExperiment.treat();
	switch (dashboardStarterRedirectExperiment.variation) {
		case VARIATIONS.A:
			redirect(
				petitionCount > 1 ? REDIRECT_URLS.getPetitionsPageUrl() : REDIRECT_URLS.getPetitionDashboardUrl(petitionSlug),
			);
			return;
		case VARIATIONS.B:
			redirect(REDIRECT_URLS.getPetitionDashboardUrl(petitionSlug));
			break;
		case VARIATIONS.CONTROL:
		default:
			break;
	}
	// -------------
}
