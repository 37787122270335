import { createCsrfUtils as _createCsrfUtils } from '@change-corgi/core/csrf';
import type { ErrorReporter } from '@change-corgi/core/errorReporter/common';
import type { Utilities } from '@change-corgi/core/react/utilityContext';

import type { Session } from 'src/shared/session';

type Options = Readonly<{
	errorReporter: ErrorReporter;
	session: Session;
}>;

export function createCsrfUtils({ errorReporter, session }: Options): Utilities['csrf'] {
	const reportError = errorReporter.createSampledReporter(0.01);
	return _createCsrfUtils({
		initialCsrfToken: session.csrfToken,
		fetch,

		reportError,
		// we want to be the token to be read ASAP for event tracking (using sendBeacon)
		fetchPolicy: 'immediate',
		// disable cache auto-clear to match fe's behavior
		// otherwise that might result on click events no being sent exclusively on corgi
		cacheExpirationMs: 0,
	}).init();
}
