import type { ChangeEvent, MutableRefObject } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import { useTrackView } from 'src/app/shared/hooks/tracking';

import { useMembershipBottomBannerFcm, useMembershipBottomBannerState } from '../../../../context';
import { useBannerClosedStateStorage } from '../../../hooks/useBannerClosedStateStorage';

type Result = ModelHookResult<
	{
		visible: boolean;
		ref: MutableRefObject<HTMLElement | null>;
		paymentTerm: 'one-time' | 'monthly' | 'yearly';
		showOneTimePaymentTerm: boolean;
		showYearlyPaymentTerm: boolean;
	},
	{
		onClickClose: () => void;
		onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	}
>;

export function useLayout(): Result {
	const [visible, setVisible] = useState(true);
	const ref = useRef<HTMLElement | null>(null);
	const track = useTracking();
	useTrackView('petition_membership_popup_shown');
	const { setBannerClosed } = useBannerClosedStateStorage();
	const [{ paymentTerm }, { setPaymentTerm }] = useMembershipBottomBannerState();

	const { showOneTimeContributions, yearlyContributionsAmountsConfig } = useMembershipBottomBannerFcm();

	const onClickClose = useCallback(() => {
		void track('petition_membership_popup_closed');
		setVisible(false);
		setBannerClosed('true');
	}, [track, setBannerClosed]);

	useEffect(() => {
		const handleClick = (event: MouseEvent) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if (ref.current && !ref.current.contains(event.target as Node)) {
				onClickClose();
			}
		};

		document.addEventListener('click', handleClick);
		return () => document.removeEventListener('click', handleClick);
	}, [ref, onClickClose]);

	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		setPaymentTerm(event.target.value as 'one-time' | 'monthly' | 'yearly');
		void track('petition_membership_popup_payment_term_selected', { payment_term: event.target.value });
	};

	return {
		data: {
			visible,
			ref,
			paymentTerm,
			showYearlyPaymentTerm: yearlyContributionsAmountsConfig?.enabled ?? false,
			showOneTimePaymentTerm: showOneTimeContributions,
		},
		actions: {
			onClickClose,
			onChange,
		},
	};
}
