import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { Link } from '@change-corgi/design-system/components/actions';
import { Image } from '@change-corgi/design-system/components/media';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

export function Content({ onClickDownload }: { onClickDownload: () => void }): React.JSX.Element {
	const { translate } = useI18n();
	return (
		<Flex sx={{ flexDirection: 'column', gap: 8, minWidth: 100, flexGrow: 1 }}>
			<Box>
				<Text fontWeight="bold" sx={{ fontSize: 16 }}>
					<Translate value="corgi.app_download.bottom_banner.mobile.headline" />
				</Text>
			</Box>
			<Text sx={{ fontSize: 14 }}>
				<Translate value="corgi.app_download.bottom_banner.mobile.description" />
			</Text>
			<Box sx={{ marginTop: 8, marginBottom: 8 }}>
				<Link to="https://apps.apple.com/us/app/MyApp/id1144132710" target="_blank" onClick={onClickDownload}>
					<Image
						src="https://static.change.org/images/apple-download-on-the-app-store-badge-EN-black-092917.svg"
						alt={translate('corgi.app_download.bottom_banner.download_on_app_store.image_alt_text')}
						sx={{ minWidth: 180, width: 180 }}
					/>
				</Link>
			</Box>
		</Flex>
	);
}
