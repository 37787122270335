import { useMemo } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import type { Session } from 'src/shared/session';

import { useGetL10nChanged } from 'src/app/shared/hooks/session';

type Result = {
	trackAttempt: () => void;
	trackSuccess: (newSession: Session) => void;
	trackFailure: (reason: string) => void;
	trackReload: (reason: string, newSession?: Session) => void;
};

export function useFacebookAutoLoginTracking(): Result {
	const track = useTracking();

	const getL10nChanged = useGetL10nChanged();

	return useMemo(
		() => ({
			trackAttempt: () => {
				void track('fb_auto_login');
			},
			trackSuccess: (newSession: Session) => {
				void track('fb_auto_login_success', {
					l10n_changed: getL10nChanged(newSession),
				});
			},
			trackFailure: (reason: string) => {
				void track('fb_auto_login_failure', {
					reason,
				});
			},
			trackReload: (reason: string, newSession?: Session) => {
				void track('fb_auto_login_reload', {
					reason,
					l10n_changed: newSession ? getL10nChanged(newSession) : undefined,
				});
			},
		}),
		[track, getL10nChanged],
	);
}
