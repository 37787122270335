import { useCallback } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import type { ReportContext } from '../../shared/types';

export function useReportAbuseTracking(reportContext: ReportContext): (context: { success: boolean }) => void {
	const track = useTracking();

	return useCallback(
		({ success }) => {
			switch (reportContext.type) {
				case 'petition':
					void track('petition_report_abuse', {
						petition_id: reportContext.petitionId,
						successful: success,
						experiment_variation: 'control',
					});
					return undefined;
				case 'comment':
					void track('comment_report_abuse', {
						comment_id: reportContext.comment.id,
						likes: reportContext.comment.likes,
						role: reportContext.comment.role.toLowerCase(),
						petition_id: reportContext.petitionId,
						report_abuse_location: reportContext.location,
						successful: success,
					});
					return undefined;
				case 'supporter_comment':
					void track('supporter_comment_report_abuse', {
						comment_id: reportContext.comment.id,
						likes: reportContext.comment.likes,
						role: reportContext.comment.role.toLowerCase(),
						petition_id: reportContext.petitionId,
						report_abuse_location: reportContext.location,
						successful: success,
					});
					return undefined;
				case 'video_comment':
					void track('video_comment_report_abuse', {
						comment_id: reportContext.comment.id,
						likes: reportContext.comment.likes,
						role: reportContext.comment.role.toLowerCase(),
						petition_id: reportContext.petitionId,
						report_abuse_location: reportContext.location,
						successful: success,
					});
					return undefined;
				default:
					return undefined;
			}
		},
		[track, reportContext],
	);
}
