import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	PromotePageLoginModalLoginByEmail,
	type PromotePageLoginModalLoginByEmailMutation,
	type PromotePageLoginModalLoginByEmailMutationVariables,
} from './loginByEmail.graphql';

export type LoginByEmailResult = PromotePageLoginModalLoginByEmailMutation['loginByEmail'];

export async function loginByEmail(
	input: PromotePageLoginModalLoginByEmailMutationVariables['input'],
	{ gql: { fetch } }: UtilityContext,
): Promise<PromotePageLoginModalLoginByEmailMutation['loginByEmail']> {
	const { data, errors } = await fetch<
		PromotePageLoginModalLoginByEmailMutation,
		PromotePageLoginModalLoginByEmailMutationVariables
	>({
		path: '/login',
		query: PromotePageLoginModalLoginByEmail,
		variables: {
			input,
		},
		rejectOnError: false,
		batched: false, // to ensure this rate limited mutation is not batched
	});

	if (errors) throw new Error('Unknown error');
	if (!data || !data.loginByEmail) throw new Error('Unknown error');
	return data.loginByEmail;
}
