import type { TranslationKey } from '@change-corgi/core/i18n';
import { translationKey } from '@change-corgi/core/i18n';

import type { OnlineShareChannel } from 'src/app/pages/starterOnboarding/shared/types';

type ShareButtonConfig = Readonly<Record<OnlineShareChannel, { label: TranslationKey; successLabel?: TranslationKey }>>;
export const SHARE_BUTTON_CONFIG: ShareButtonConfig = {
	facebook: {
		label: translationKey('corgi.petition.starter_onboarding.share_channels.facebook.button_text'),
	},
	facebookMessenger: {
		label: translationKey('corgi.petition.starter_onboarding.share_channels.facebook_messenger.button_text'),
	},
	twitter: {
		label: translationKey('corgi.petition.starter_onboarding.share_channels.twitter.button_text'),
	},
	whatsapp: {
		label: translationKey('corgi.petition.starter_onboarding.share_channels.whatsapp.button_text'),
	},
	instagram: {
		label: translationKey('corgi.petition.starter_onboarding.share_channels.instagram.button_text'),
	},
	line: {
		label: translationKey('corgi.petition.starter_onboarding.share_channels.line.button_text'),
	},
	email: {
		label: translationKey('corgi.petition.starter_onboarding.share_channels.email.button_text'),
	},
	sms: {
		label: translationKey('corgi.petition.starter_onboarding.share_channels.sms.button_text'),
	},
	copy: {
		label: translationKey('corgi.petition.starter_onboarding.share_channels.copy_link.button_text'),
		successLabel: translationKey('corgi.petition.starter_onboarding.share_channels.copy_link.button_success_text'),
	},
	vk: {
		label: translationKey('corgi.petition.starter_onboarding.share_channels.vk.button_text'),
	},
	nextdoor: {
		label: translationKey('corgi.petition.starter_onboarding.share_channels.nextdoor.button_text'),
	},
	mobileNativeShare: {
		label: translationKey('corgi.petition.starter_onboarding.share_channels.native_share.button_text'),
	},
	reddit: {
		label: translationKey('corgi.petition.starter_onboarding.share_channels.reddit.button_text'),
	},
};
