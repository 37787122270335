import { useMemo } from 'react';
import type { JSX, PropsWithChildren } from 'react';

import { CacheProvider } from '@emotion/react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';

import { createNavigationUtils } from '@change-corgi/core/react/navigation';
import { AppRenderInfoProvider } from '@change-corgi/core/react/ssr/render';
import { UtilityContextProvider } from '@change-corgi/core/react/utilityContext';
import { DesignSystem } from '@change-corgi/design-system/core';

import { ErrorPage } from 'src/app/app/error';
import { DefaultLayout, SkipToMainContent } from 'src/app/app/layout';
import { Head } from 'src/app/shared/components/head';
import { FcmCacheProvider } from 'src/app/shared/hooks/fcm';
import { L10n } from 'src/app/shared/hooks/l10n';
import { SessionProvider } from 'src/app/shared/hooks/session';
import { useWebappTrackLoad } from 'src/app/shared/hooks/webapp';

import { AppGlobalStyle } from './GlobalStyle';
import type { AppOptions } from './types';

const fullyExternalNavigation = createNavigationUtils({ internalRoutes: [] });

export function ErrorInnerApp(appOptions: AppOptions & { hasTranslations: boolean }): JSX.Element | null {
	const { hasTranslations, hideBrowseLink, errorStatus, errorId } = appOptions;

	const ErrorWithId = useMemo(
		() =>
			// eslint-disable-next-line @typescript-eslint/no-shadow
			function ErrorWithId() {
				return <ErrorPage status={errorStatus || 500} errorId={errorId} suppressHydrationWarning />;
			},
		[errorStatus, errorId],
	);

	return (
		<>
			{hasTranslations ? (
				<>
					<SkipToMainContent />
					<DefaultLayout hideBrowseLink={!!hideBrowseLink} suppressHydrationWarning>
						<ErrorWithId />
					</DefaultLayout>
				</>
			) : (
				<ErrorWithId />
			)}
		</>
	);
}

export function ErrorApp(appOptions: PropsWithChildren<AppOptions & { hasTranslations: boolean }>): JSX.Element | null {
	const {
		utilities,
		l10n: { locale, countryCode },
		session,
		sessionAvailableAtFirstRender,
		emotionCache,
		cache,
		children,
	} = appOptions;

	const utilitiesWithErrorPageNavigation = useMemo(
		() => ({ ...utilities, navigation: fullyExternalNavigation }),
		[utilities],
	);

	useWebappTrackLoad(appOptions);

	return (
		<CacheProvider value={emotionCache}>
			<DesignSystem>
				<AppGlobalStyle />
				<AppRenderInfoProvider>
					<UtilityContextProvider utilities={utilitiesWithErrorPageNavigation}>
						<Helmet
							titleTemplate="%s · Change.org"
							defaultTitle="Change.org"
							bodyAttributes={{
								// necessary for focus fallback
								tabindex: '-1',
							}}
						>
							<html lang={locale} />
						</Helmet>
						<L10n locale={locale} countryCode={countryCode}>
							<SessionProvider session={session} sessionAvailableAtFirstRender={sessionAvailableAtFirstRender}>
								<FcmCacheProvider initialState={cache?.fcm}>
									<Head />
									{children || <Outlet />}
								</FcmCacheProvider>
							</SessionProvider>
						</L10n>
					</UtilityContextProvider>
				</AppRenderInfoProvider>
			</DesignSystem>
		</CacheProvider>
	);
}
