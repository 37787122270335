import { shouldPolyfill } from '@formatjs/intl-pluralrules/should-polyfill';

import { INTL_LOCALE_CHECKS } from '../intlLocale';

import { loadPolyfill } from './polyfill';

const polyfill = (() => {
	const promises: Partial<Record<string, Promise<void>>> = {};

	return async function innerPolyfill(locale: string) {
		if (promises[locale]) {
			return promises[locale];
		}

		promises[locale] = loadPolyfill(locale, PLURAL_RULES_CHECKS.dependencies);
		return promises[locale];
	};
})();

export const PLURAL_RULES_CHECKS = {
	dependencies: [...INTL_LOCALE_CHECKS],
	checkNativeIntlFeature: (locale: string): boolean => !!window.Intl && !shouldPolyfill(locale),
	polyfill,
};

export const INTL_PLURAL_RULES_CHECKS = [PLURAL_RULES_CHECKS];
