import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	LoginModalLoginByEmail,
	type LoginModalLoginByEmailMutation,
	type LoginModalLoginByEmailMutationVariables,
} from './loginByEmail.graphql';

export async function loginByEmail(
	input: LoginModalLoginByEmailMutationVariables['input'],
	{ gql: { fetch } }: UtilityContext,
): Promise<void> {
	const { data, errors } = await fetch<LoginModalLoginByEmailMutation, LoginModalLoginByEmailMutationVariables>({
		path: '/login',
		query: LoginModalLoginByEmail,
		variables: {
			input,
		},
		rejectOnError: false,
		batched: false, // to ensure this rate limited mutation is not batched
	});

	if (errors) throw new Error('Unknown error');
	if (!data || !data.loginByEmail) throw new Error('Unknown error');
	if (data.loginByEmail.status !== 'LOGGED_IN') throw new Error(`Unexpected status: ${data.loginByEmail.status}`);
}
