import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	VerifyNewUserEmail,
	type VerifyNewUserEmailMutation,
	type VerifyNewUserEmailMutationVariables,
} from './verifyNewUserEmail.graphql';

export async function verifyNewUserEmail(
	redirectUrl: string,
	{ gql: { fetch } }: UtilityContext,
	userId: string,
): Promise<boolean> {
	try {
		const { data } = await fetch<VerifyNewUserEmailMutation, VerifyNewUserEmailMutationVariables>({
			query: VerifyNewUserEmail,
			variables: {
				input: {
					userId,
					redirectUrl,
				},
			},
			rejectOnError: false,
		});

		if (!data) throw new Error('no data');
		if (data.verifyNewUserEmail.__typename === 'VerifyNewUserEmailError') return false;
		return true;
	} catch (e) {
		return false;
	}
}
