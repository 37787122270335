import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	BanditsSharedPullBandit,
	type BanditsSharedPullBanditMutation,
	type BanditsSharedPullBanditMutationVariables,
} from './pull.graphql';

type Input = {
	experimentName: string;
	variantName: string;
};

export async function pullBandit(
	{ experimentName, variantName }: Input,
	{ gql: { fetch } }: UtilityContext,
	{
		silent,
	}: {
		// if true, won't throw on error
		silent?: boolean;
	} = {},
): Promise<void> {
	let data: BanditsSharedPullBanditMutation | null | undefined;
	try {
		const result = await fetch<BanditsSharedPullBanditMutation, BanditsSharedPullBanditMutationVariables>({
			query: BanditsSharedPullBandit,
			variables: {
				input: {
					banditId: experimentName,
					variantName,
					increment: null,
				},
			},
			rejectOnError: false,
			batched: false,
		});
		({ data } = result);
	} catch (err) {
		if (silent) return;
		throw new Error('Bandit pull failed');
	}

	if (silent) return;
	if (!data?.pullBandit) {
		throw new Error('Bandit pull failed');
	}
}
