import { Translate } from '@change-corgi/core/react/i18n';
import { Button } from '@change-corgi/design-system/components/actions';
import { iconClose } from '@change-corgi/design-system/icons';
import { Box } from '@change-corgi/design-system/layout';

type Props = {
	onClick: () => void;
};

export function DismissButton({ onClick }: Props): React.JSX.Element {
	return (
		<Box sx={{ position: 'fixed', right: 20 }}>
			<Button
				data-qa="app-download-popup-close-button"
				sx={{ cursor: 'pointer', backgroundColor: 'transparent', borderColor: 'transparent' }}
				onClick={onClick}
				color="black"
				icon={iconClose}
				variant="secondary"
				mode="icon"
				size="small"
			>
				<Translate value="corgi.app_download.bottom_banner.dismiss_button.text" />
			</Button>
		</Box>
	);
}
