import { useMemo } from 'react';

import type { SignPetitionInput } from 'src/app/shared/api/signature';
import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoading } from 'src/app/shared/utils/async';

type Result = Pick<SignPetitionInput, 'trafficMetadata' | 'mitTrackingValue' | 'fhtTrackingValue' | 'trackingData'>;

export function useTrackingSignData({ bandits }: { bandits?: boolean } = {}): Result {
	const sessionState = useSessionAsync();

	// eslint-disable-next-line complexity
	return useMemo(() => {
		if (isLoading(sessionState)) {
			return {
				trafficMetadata: {
					currentSource: null,
					currentMedium: null,
					referringDomain: null,
				},
				mitTrackingValue: null,
				fhtTrackingValue: null,
				trackingData: {
					webapp_name: 'corgi',
				},
			};
		}

		const session = sessionState.value;

		const currentContent = session.trackingData.current_content || null;
		const currentSource = session.trackingData.current_source || null;
		const currentMedium = session.trackingData.current_medium || null;
		const referringDomain = session.trackingData.referring_domain || null;

		return {
			trafficMetadata: {
				currentSource,
				currentMedium,
				referringDomain,
			},
			mitTrackingValue: bandits && currentContent?.startsWith('mit-') ? currentContent : null,
			fhtTrackingValue: bandits && currentContent?.startsWith('fht-') ? currentContent : null,
			trackingData: {
				webapp_name: 'corgi',
			},
		};
	}, [sessionState, bandits]);
}
