/* eslint-disable @typescript-eslint/consistent-indexed-object-style */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { useCallback, useState } from 'react';

import type { PrefetchableQueryHookResult } from '@change-corgi/core/react/async';
import { prefetchQuery, usePrefetchableQuery } from '@change-corgi/core/react/async';
import { createMandatoryContext } from '@change-corgi/core/react/context';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { HttpError, RedirectError } from 'src/shared/error';
import type { PrefetchContext } from 'src/shared/prefetch';

import { getInitialPrefetchUserDataQueryId, usePrefetchUserDataQueryId } from 'src/app/shared/hooks/query';
import { useMappedLoadedState } from 'src/app/shared/hooks/state';

import type { ContextData, Map, OptionsPrefetchable, ResultPrefetchable, RethrowableErrorState } from './types';
import { useCheckHookDeps } from './useCheckHookDeps';

function createContext<
	DATA extends Record<string, unknown>,
	DATA_PROPERTY extends string = 'data',
	OLD_VERSION extends boolean = false,
>(name: string, dataProperty: DATA_PROPERTY) {
	return createMandatoryContext<
		ContextData<DATA, OLD_VERSION>,
		{
			[key in DATA_PROPERTY]: ContextData<DATA, OLD_VERSION>;
		}
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,
	>(undefined, {
		name,
		processProviderProps: (props: {
			[key in DATA_PROPERTY]: ContextData<DATA, OLD_VERSION>;
		}): ContextData<DATA, OLD_VERSION> => props[dataProperty],
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} as any);
}

// eslint-disable-next-line max-lines-per-function
export function createPrefetchableAsyncDataContext<
	DATA extends Record<string, unknown>,
	PARAMS extends unknown[] = [],
	DATA_PROPERTY extends string = 'data',
	OLD_VERSION extends boolean = false,
	ERROR extends Map = EmptyIntersection,
>({
	name,
	getUniqueId: getUniqueIdBase = () => '',
	getData,
	hasUserData,
	dataProperty: dataPropertyP,
	errorHandler: errorHandlerP,
	hookDeps,
	loadingBetweenQueries,
	oldVersion,
}: OptionsPrefetchable<DATA, PARAMS, DATA_PROPERTY, OLD_VERSION, ERROR>): ResultPrefetchable<
	DATA,
	PARAMS,
	DATA_PROPERTY,
	OLD_VERSION,
	ERROR
> {
	const dataProperty = dataPropertyP || ('data' as DATA_PROPERTY);
	const { Context, Provider, useContext } = createContext<DATA, DATA_PROPERTY, OLD_VERSION>(name, dataProperty);

	const useUniqueId = hasUserData
		? (...params: PARAMS) => {
				const id = getUniqueIdBase(...params);
				const queryId = usePrefetchUserDataQueryId();
				return `${id}:${queryId}`;
			}
		: (...params: PARAMS) => getUniqueIdBase(...params);

	const getUniqueId = hasUserData
		? (...params: PARAMS) => {
				const id = getUniqueIdBase(...params);
				const queryId = getInitialPrefetchUserDataQueryId();
				return `${id}:${queryId}`;
			}
		: (...params: PARAMS) => getUniqueIdBase(...params);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const errorHandler: (e: any) => ERROR = (error: any) => {
		// not catching HTTP errors that should instead be handled by the server, or the ErrorBoundary
		if (error instanceof HttpError || error instanceof RedirectError) {
			throw error;
		}
		return errorHandlerP ? errorHandlerP(error) : ({} as ERROR);
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const hookErrorHandler: (e: any) => ERROR = (error: any) => {
		try {
			return errorHandler(error);
		} catch (err) {
			// using a "hidden" field in the returned state, because the error is thrown within a useEffect
			// which would not fall into a ErrorBoundary
			// eslint-disable-next-line @typescript-eslint/naming-convention
			return { __errorToRethrow: err } satisfies RethrowableErrorState as unknown as ERROR;
		}
	};

	const res = {
		Context,
		Provider,
		useContext,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		useAsyncData: (prefetchedState: any, ...params: PARAMS) => {
			const utilityContext = useUtilityContext();
			const [refreshCount, setRefreshCount] = useState(0);

			const uniqueId = useUniqueId(...params);
			const uniqueIdWithRefresh = refreshCount ? `${uniqueId}:refresh_${refreshCount}` : uniqueId;

			const deps = hookDeps ? hookDeps(...params) : params;

			useCheckHookDeps(deps, utilityContext);

			const refreshData = useCallback(() => setRefreshCount((count) => count + 1), []);

			const dataState = usePrefetchableQuery(
				async () => ({
					[dataProperty]: await getData(utilityContext, ...params),
				}),
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[...deps, uniqueIdWithRefresh, utilityContext],
				{
					id: uniqueIdWithRefresh,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					prefetchedState,
					errorHandler: hookErrorHandler,
					loadingBetweenQueries,
				},
			);

			// eslint-disable-next-line @typescript-eslint/no-explicit-any,
			const hookRes = useMappedLoadedState(dataState, (data: any) => ({
				[dataProperty]: {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
					data: data[dataProperty],
					actions: {
						refreshData,
					},
				},
			})) as PrefetchableQueryHookResult<
				{
					[key in DATA_PROPERTY]: ContextData<DATA, false>;
				},
				ERROR
			>;

			if (hookRes.status === 'error' && (hookRes as unknown as RethrowableErrorState).__errorToRethrow) {
				throw (hookRes as unknown as RethrowableErrorState).__errorToRethrow;
			}

			return hookRes;
		},
		prefetchAsyncData: async (prefetchContext: PrefetchContext, ...params: PARAMS) => {
			return prefetchQuery(
				async () => ({ [dataProperty || 'data']: await getData(prefetchContext.utilityContext, ...params) }),
				{
					id: getUniqueId(...params),
					errorHandler,
				},
			);
		},
	};

	if (!oldVersion) return res;

	return {
		...res,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		useAsyncData: (prefetchedState: any, ...params: PARAMS) => {
			const dataState = res.useAsyncData(prefetchedState, ...params);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any,
			return useMappedLoadedState(dataState, (data: any) => ({
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
				[dataProperty]: data[dataProperty].data,
			})) as PrefetchableQueryHookResult<
				{
					[key in DATA_PROPERTY]: ContextData<DATA, false>;
				},
				ERROR
			>;
		},
	};
}
