import { useState } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useCookiePrefAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';
import { isIPhone, isMobile } from 'src/app/shared/utils/userAgent';

type Result = ModelHookResult<
	{
		isMobileUserAgent: boolean;
		isIPhoneUserAgent: boolean;
		bannerClosed: boolean;
	},
	{ setBannerClosed: () => void }
>;

const APP_DOWNLOAD_BOTTOM_BANNER_COOKIE = 'app_download_banner_closed_count';
const APP_DOWNLOAD_BOTTOM_BANNER_COOKIE_EXPIRATION_DAYS = 365; // 1 year

function getBannerClosedCount(cookies: ReturnType<typeof useUtilityContext>['cookies']) {
	const dismissedCountStr = cookies.get(APP_DOWNLOAD_BOTTOM_BANNER_COOKIE);
	let dismissedCount: number;
	try {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		dismissedCount = JSON.parse(dismissedCountStr || '0') as number;
	} catch (e) {
		dismissedCount = 0;
	}
	return dismissedCount;
}

export function useBanner(): Result {
	const utilityContext = useUtilityContext();
	const { cookies } = utilityContext;

	const isMobileUserAgent = isMobile(utilityContext);
	const isIPhoneUserAgent = isIPhone(utilityContext);

	const initialBannerClosedState = getBannerClosedCount(cookies) >= 2 || (isMobileUserAgent && !isIPhoneUserAgent);
	const [bannerClosed, setBannerClosedState] = useState(initialBannerClosedState);
	const cookieAllowedState = useCookiePrefAsync('preferences');
	const cookieAllowed = isLoaded(cookieAllowedState) ? cookieAllowedState.value : false;

	return {
		data: {
			isMobileUserAgent,
			isIPhoneUserAgent,
			bannerClosed,
		},
		actions: {
			setBannerClosed: () => {
				setBannerClosedState(true);

				if (!cookieAllowed) return;

				cookies.set(APP_DOWNLOAD_BOTTOM_BANNER_COOKIE, JSON.stringify(getBannerClosedCount(cookies) + 1), {
					expires: APP_DOWNLOAD_BOTTOM_BANNER_COOKIE_EXPIRATION_DAYS,
					path: '/',
				});
			},
		},
	};
}
