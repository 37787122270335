import type { TrackingFn } from '@change-corgi/core/react/tracking';
import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import type { ModalHookResult } from '@change-corgi/design-system/modals';

import { copyToClipboard } from 'src/app/shared/utils/clipboard';

import { trackShare } from './track';

type Options = {
	trackingData: Record<string, string | number | boolean | undefined>;
	trackingEvents: Record<string, string>;
	openEducationModal: ModalHookResult<EmptyObject, { successfulShare: boolean }>;
};

export async function shareInstagram(
	url: string,
	{ trackingEvents, trackingData, openEducationModal }: Options,
	track: TrackingFn,
	i18nContext: UtilityContext['i18n'],
): Promise<boolean> {
	await copyToClipboard(url, i18nContext);

	trackShare(trackingEvents.success, trackingData, track);
	trackShare(trackingEvents.intent, trackingData, track);

	const successfulShare = await (async () => {
		try {
			const modalResult = await openEducationModal({}).waitForResult();
			return !!modalResult?.successfulShare;
		} catch (error) {
			return false;
		}
	})();

	if (successfulShare) {
		trackShare(trackingEvents.continue, trackingData, track);
	} else {
		trackShare(trackingEvents.cancel, trackingData, track);
	}

	return successfulShare;
}
