import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	PetitionDetailsPageStats,
	type PetitionDetailsPageStatsQuery,
	type PetitionDetailsPageStatsQueryVariables,
} from './stats.graphql';

export type QueryVariables = {
	petitionSlugOrId: string;
	maxSigners: number;
	shouldFetchRecruiter: boolean;
	recruiterId: string;
};

export type PetitionStats = NonNullable<PetitionDetailsPageStatsQuery['petitionStats']>;

export async function getPetitionStats(
	petitionSlugOrId: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PetitionStats | undefined> {
	const { data } = await fetch<PetitionDetailsPageStatsQuery, PetitionDetailsPageStatsQueryVariables>({
		query: PetitionDetailsPageStats,
		variables: {
			petitionSlugOrId,
		},
		rejectOnError: false,
	});
	if (!data?.petitionStats) {
		return undefined;
	}
	return data.petitionStats;
}
