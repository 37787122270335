import { useCallback } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import type { TrackingProperties } from '@change-corgi/tracking-properties';

import type { ActionErrorName, ActionEventName } from '../eventNames/action';
import type { GammaEventName } from '../eventNames/gamma';
import type { MediaHitErrorName, MediaHitEventName } from '../eventNames/mediaHits';
import type { PetitionFeedEventName } from '../eventNames/petitionFeed';
import type { SupporterVoicesErrorName, SupporterVoicesEventName } from '../eventNames/supporterVoices';

import { checkError } from './utils/checkError';

/**
 *  PATTERN FOR NAMING EVENTS
 * - campaign_[feature]_[eventName]
 * - campaign_[feature]_[eventName]_failure => when a desired action fails to complete,
 * 		but no Error object is thrown. eg. api request returns {success: false}.
 * - campaign_[feature]_[eventName]_error => an Error object is thrown while attempting to perform an action.
 */
export type CampaignTrackingEventName =
	| ActionEventName
	| GammaEventName
	| MediaHitEventName
	| PetitionFeedEventName
	| SupporterVoicesEventName;
export type CampaignTrackingErrorName = ActionErrorName | MediaHitErrorName | SupporterVoicesErrorName;

export type CampaignTrackingErrorData = {
	params?: Record<string, unknown>;
	context?: Record<string, unknown>;
};

type Result = {
	track: (eventName: CampaignTrackingEventName, data?: TrackingProperties) => void;
	trackError: (eventName: CampaignTrackingErrorName, e?: unknown, errorData?: CampaignTrackingErrorData) => void;
};

/**
 * Utility hook for tracking events within the Campaign domain. The track functions accept a limited
 * set of event names which are defined within this file: the `EventName` and `ErrorName` types.
 */
export function useCampaignTracking(): Result {
	const baseTrack = useTracking();
	const { errorReporter } = useUtilityContext();

	const track = useCallback(
		(eventName: CampaignTrackingEventName, data?: TrackingProperties) => {
			void baseTrack(eventName, data);
		},
		[baseTrack],
	);

	const trackError = useCallback(
		/* The optional arguments trigger code coverage. Remove when there are more uses of this util. */
		/* istanbul ignore next */
		(eventName: CampaignTrackingErrorName, e?: unknown, errorData: CampaignTrackingErrorData = {}) => {
			const error = checkError(e);
			void errorReporter.report({
				error,
				params: errorData.params,
				context: {
					eventName,
				},
			});
			void baseTrack(eventName, { message: error.message });
		},
		[errorReporter, baseTrack],
	);

	return { track, trackError };
}
