import type { FcmAccessControlConfigValueType } from '@change-corgi/fcm/configs';

import { useSession } from 'src/app/shared/hooks/session';

type Result = {
	isOpen: boolean;
};

export function useUserInSet(featureAccessConfigValue: FcmAccessControlConfigValueType): Result {
	const session = useSession();

	if (session.user && session.user.id) {
		const userId = session.user.id;
		const { users } = featureAccessConfigValue;
		const allowed = users.some(({ id }) => id === userId);
		return { isOpen: allowed };
	}

	return {
		isOpen: false,
	};
}
