import { Box, Container, Flex } from '@change-corgi/design-system/layout';
import { derivedStyle } from '@change-corgi/design-system/theme';

import { useBanner } from '../shared/hooks/useBanner';

import { Content } from './components/Content';
import { DismissButton } from './components/DismissButton';
import { PhoneImage } from './components/PhoneImage';
import { QRCode } from './components/QRCode';

type Props = {
	sourceLocation: string;
	setBannerClosed: () => void;
};

export function DesktopBanner({ sourceLocation, setBannerClosed }: Props): React.JSX.Element | null {
	const {
		actions: { onClickClose },
	} = useBanner({ sourceLocation, setBannerClosed });

	return (
		<Box
			id="app-download-bottom-banner"
			sx={{
				display: ['none', 'block'],
				position: 'fixed',
				backgroundColor: '#FAF7ED',
				boxShadow: '0 0 1px 1px rgb(0 0 0 / 20%)',
				padding: 32,
				width: '100%',
				bottom: 0,
				left: 0,
				zIndex: derivedStyle(({ zIndices }) => zIndices.cookieWall - 1),
			}}
			data-testid="app-download-bottom-banner"
		>
			<DismissButton onClick={onClickClose} />
			<Container>
				<Flex
					sx={{
						display: 'grid',
						gridTemplateColumns: 'auto 1fr auto',
						gap: [16, 24],
						alignItems: 'center',
					}}
				>
					<QRCode />
					<Content />
					<PhoneImage />
				</Flex>
			</Container>
		</Box>
	);
}
