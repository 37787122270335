import type { Mutator } from 'final-form';

import type { FormValues } from 'src/app/pages/petitionGamma/details/shared/types';

type SignFormMutators = Record<'setValue', Mutator<FormValues>>;

export const signFormMutators: SignFormMutators = {
	// expect (field, value) args from the mutator
	setValue: ([field, value], state, { changeValue }) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument
		changeValue(state, field, () => value);
	},
};
