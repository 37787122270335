import { useEffect, useState } from 'react';

import { useBannerClosedStateStorage } from '../useBannerClosedStateStorage';

type Result = { displayed: boolean };

export function useMembershipBottomBanner(): Result {
	const [waitTimeCompleted, setWaitTimeCompleted] = useState<boolean>(false);
	const { bannerClosed } = useBannerClosedStateStorage();

	useEffect(() => {
		if (bannerClosed) return undefined;

		// Prevent the membership banner from showing if the app download banner
		// is being displayed.  This prevents the membership banner from appearing
		// behind the app download banner.  This is a temporary workaround to prevent
		// interference between the two banners, and should not be replicated elsewhere.
		if (document.getElementById('app-download-bottom-banner')) return undefined;

		const timer = setTimeout(() => {
			setWaitTimeCompleted(true);
		}, 10000);

		return () => {
			clearTimeout(timer);
		};
	}, [bannerClosed]);

	return {
		displayed: waitTimeCompleted,
	};
}
