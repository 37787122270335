import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { getFacebookLocale } from '@change-corgi/config/facebook';

import { useLocale } from 'src/app/shared/hooks/l10n';

export function MetaLocaleContainer(): JSX.Element {
	const locale = useLocale();

	return (
		<Helmet>
			{/* Override this in child components of App if necessary */}
			<meta property="og:locale" content={getFacebookLocale(locale) || getFacebookLocale('en-US')} />
		</Helmet>
	);
}
