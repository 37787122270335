import type {
	PrefetchContext,
	PrefetchedDataProps,
	PrefetchedUserDataProps,
	PrefetchUserContext,
} from 'src/shared/prefetch';
import { isAtLeastIdentified } from 'src/shared/session';

import { DECISION_MAKER_HASH_QUERY_PARAM_NAME } from 'src/app/pages/petitionGamma/details/shared/constants';
import { prefetchDs2ExperimentUserData, useAsyncDs2ExperimentData } from 'src/app/shared/hooks/ds2Experiment';
import type { Ds2ExperimentData } from 'src/app/shared/hooks/ds2Experiment';
import { useCountryCode, useLocale } from 'src/app/shared/hooks/l10n';
import { useQueryString } from 'src/app/shared/hooks/location';
import { useSessionAsync } from 'src/app/shared/hooks/session';
import type { MergedState } from 'src/app/shared/hooks/state';
import { useMergedStates } from 'src/app/shared/hooks/state';
import { isLoaded } from 'src/app/shared/utils/async';
import { getQueryStringSingleValue } from 'src/app/shared/utils/query';

import { prefetchPetitionDetailsPageDataFromApi, usePetitionDetailsPageDataFromApi } from './pageData';
import { prefetchPetitionDetailsPageFcm, useAsyncPetitionDetailsPageFcm } from './pageFcm';
import { prefetchPetitionDetailsPageUserDataFromApi, usePetitionDetailsPageUserDataFromApi } from './pageUserData';

export type PetitionDetailsPagePrefetchedContext = Readonly<{
	pageData: Parameters<typeof usePetitionDetailsPageDataFromApi>[0];
}>;

export type PetitionDetailsPagePrefetchedUserContext = {
	pageUserData: NonNullable<Parameters<typeof usePetitionDetailsPageUserDataFromApi>[0]>;
	ds2ExperimentData: Ds2ExperimentData;
};

export type PetitionDetailsPageCombinedState = MergedState<
	ReturnType<typeof usePetitionDetailsPageDataFromApi>,
	ReturnType<typeof usePetitionDetailsPageUserDataFromApi>,
	ReturnType<typeof useAsyncPetitionDetailsPageFcm>,
	ReturnType<typeof useAsyncDs2ExperimentData>
>;

export function usePageContext(
	slug: string,
	prefetchedData?: PetitionDetailsPagePrefetchedContext,
	prefetchedUserData?: PetitionDetailsPagePrefetchedUserContext,
): PetitionDetailsPageCombinedState {
	const sessionState = useSessionAsync();
	const session = isLoaded(sessionState) ? sessionState.value : undefined;
	const sessionIsAtLeastIdentified = session ? isAtLeastIdentified(session) : false;
	const query = useQueryString();

	return useMergedStates(
		usePetitionDetailsPageDataFromApi(prefetchedData?.pageData, slug, {
			countryCode: useCountryCode(),
			locale: useLocale(),
			loadBandits: sessionIsAtLeastIdentified,
		}),
		usePetitionDetailsPageUserDataFromApi(prefetchedUserData?.pageUserData, slug, {
			decisionMakerHash: getQueryStringSingleValue(query, DECISION_MAKER_HASH_QUERY_PARAM_NAME),
			isGuest: !sessionIsAtLeastIdentified,
		}),
		useAsyncPetitionDetailsPageFcm(),
		useAsyncDs2ExperimentData(prefetchedUserData?.ds2ExperimentData),
	);
}

export async function prefetchPageContext(
	slug: string,
	context: PrefetchContext,
): Promise<PetitionDetailsPagePrefetchedContext> {
	const [pageData] = await Promise.all([
		prefetchPetitionDetailsPageDataFromApi(context, slug, {
			...context.l10n,
			loadBandits: context.session ? isAtLeastIdentified(context.session) : false,
		}),
		prefetchPetitionDetailsPageFcm(context),
	]);

	return { pageData };
}

export async function prefetchPageUserContext(
	slug: string,
	context: PrefetchUserContext,
): Promise<PetitionDetailsPagePrefetchedUserContext> {
	const [pageUserData, ds2ExperimentData] = await Promise.all([
		prefetchPetitionDetailsPageUserDataFromApi(context, slug, {
			decisionMakerHash: getQueryStringSingleValue(context.query, DECISION_MAKER_HASH_QUERY_PARAM_NAME),
			isGuest: context.session.loginState === 'GUEST',
		}),
		prefetchDs2ExperimentUserData(context),
	]);

	return {
		pageUserData,
		ds2ExperimentData,
	};
}

export type PetitionDetailsPagePrefetchedDataProps = PrefetchedDataProps<PetitionDetailsPagePrefetchedContext> &
	PrefetchedUserDataProps<PetitionDetailsPagePrefetchedUserContext>;
