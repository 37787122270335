import { useMemo } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import { useTrackingCommonProps } from './useTrackingCommonProps';

type SocialConnectSuccess = {
	newUser: boolean;
	userId: string | undefined;
	userFlow: string;
};

type Result = {
	trackFbLoginSuccess: (props: SocialConnectSuccess) => void;
	trackGoogleConnectSuccess: (props: SocialConnectSuccess) => void;
};

export function useSocialAuthSuccessTracking(): Result {
	const track = useTracking();
	const getCommonProps = useTrackingCommonProps();

	return useMemo(
		() => ({
			trackGoogleConnectSuccess: ({ newUser, userId, userFlow }: SocialConnectSuccess) => {
				void track(
					'google_connect',
					{
						...getCommonProps(),
						context: 'combined',
						google_logged_in: true,
						connected_to_google: true,
						new_user: newUser,
						user_flow: userFlow,
					},
					{
						defaultOverrides: {
							user_id: userId === undefined ? undefined : parseInt(userId, 10),
						},
					},
				);
			},

			trackFbLoginSuccess: ({ newUser, userId, userFlow }: SocialConnectSuccess) => {
				void track(
					'fb_login_success',
					{
						...getCommonProps(),
						context: newUser ? 'signup' : 'login',
						connected_to_facebook: true,
						fb_logged_in: true,
						new_user: newUser,
						user_flow: userFlow,
					},
					{
						defaultOverrides: {
							user_id: userId === undefined ? undefined : parseInt(userId, 10),
						},
					},
				);
			},
		}),
		[getCommonProps, track],
	);
}
