import type {
	FormValues,
	NormalizedFormValues,
	SignFormExperiments,
} from 'src/app/pages/petitionGamma/details/shared/types';

import { normalizeCaptcha } from './normalizeCaptcha';
import { normalizeGdprConsent } from './normalizeGdprConsent';
import { normalizePostalCode } from './normalizePostalCode';
import { normalizePublic } from './normalizePublic';
import { normalizeStateCode } from './normalizeStateCode';

export function normalizeSignData(values: FormValues, experiments: SignFormExperiments): NormalizedFormValues {
	const { city, countryCode, firstName, lastName, email, reasonForSigning, phoneNumber } = values;
	return {
		city,
		countryCode,
		firstName,
		lastName,
		email,
		phoneNumber,
		reasonForSigning: reasonForSigning || null,
		stateCode: normalizeStateCode(values),
		postalCode: normalizePostalCode(values),
		shareInfoWithOrganization: false,
		public: normalizePublic(values),
		marketingCommsConsent: normalizeGdprConsent(values, experiments),
		recaptchaResponse: normalizeCaptcha(values),
	};
}
