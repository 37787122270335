import {
	FCM_CORGI_PERIMETERX_SENSOR_SCRIPT_ENABLED,
	FCM_ENABLE_SUBSCRIPTIONS_FOR,
	FCM_NEWRELIC_BROWSER_ENABLED,
	FCM_OPTIMIZELY_PAGES,
} from '@change-corgi/fcm/configs';

import { createFcmFunctions } from 'src/app/shared/hooks/fcm';

export const { useFcm: useCommonFcm, prefetchFcm: prefetchCommonFcm } = createFcmFunctions(
	{
		optimizelyLocationMatch: FCM_OPTIMIZELY_PAGES,
		newRelicEnabled: FCM_NEWRELIC_BROWSER_ENABLED,
		pxSensorScriptEnabled: FCM_CORGI_PERIMETERX_SENSOR_SCRIPT_ENABLED,
		membershipEnabled: FCM_ENABLE_SUBSCRIPTIONS_FOR,
	},
	{ hook: true, prefetch: true },
);
