import type { LinkDesc, LinkGroup } from './types';

type PartialLinkDesc = Readonly<{
	href?: string;
	title: string;
	target?: string;
}>;

type PartialLinkGroup = {
	title: string;
	links: readonly PartialLinkDesc[];
};

export function processLinkGroup(group: PartialLinkGroup): LinkGroup | null {
	const links = group.links.filter((link): link is LinkDesc => !!link.href);
	if (!links.length) {
		return null;
	}
	return {
		...group,
		links,
	};
}
