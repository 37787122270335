import Cookies from 'js-cookie';

import type { Experiments } from '@change-corgi/core/experiments';
import {
	createExperiments as _createExperiments,
	extractExperimentOverridesFromQueryString,
} from '@change-corgi/core/experiments';
import type { GqlClient } from '@change-corgi/core/gql';
import { getLocation } from '@change-corgi/core/window';

import type { Session } from 'src/shared/session';

type Options = Readonly<{
	gql: GqlClient;
	session: Session;
}>;

export function createExperiments({ gql, session }: Options): Experiments {
	return _createExperiments({
		gqlFetch: gql.fetch.bind(gql),
		cookies: Cookies,
		experiments: session.experiments || null,
		overrides: extractExperimentOverridesFromQueryString(getLocation().search, {
			ignoreQueryPrefix: true,
		}),
	}).decorateWindow();
}
