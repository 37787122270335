import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'petitionGuidesLandingPage',
		path: '/petition-guides',
		component: loadable(async () => import(/* webpackChunkName: "pages/petitionGuidesLandingPage" */ './index'), {
			exportName: 'PetitionGuidesLandingPage',
			fallback: Loading,
		}),
		frame: true,
		ssr: {
			enabled: true,
			seo: true,
		},
	},
];
