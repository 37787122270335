import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	RestrictedAccessStarterRoleStarterId,
	type RestrictedAccessStarterRoleStarterIdQuery,
	type RestrictedAccessStarterRoleStarterIdQueryVariables,
} from './getStarterId.graphql';

export async function getStarterId(
	utilities: Pick<UtilityContext, 'gql'>,
	slugOrId: string,
): Promise<string | undefined> {
	const { data } = await utilities.gql.fetch<
		RestrictedAccessStarterRoleStarterIdQuery,
		RestrictedAccessStarterRoleStarterIdQueryVariables
	>({
		query: RestrictedAccessStarterRoleStarterId,
		variables: { slugOrId },
		rejectOnError: true,
	});

	return data?.petition?.user.id || undefined;
}
