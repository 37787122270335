import type { JSX, PropsWithChildren } from 'react';
import { useMemo } from 'react';

import { useIsFirstAppRender } from '@change-corgi/core/react/ssr/render';

import { useSessionState } from '../stateContext/context';

import { SessionContextProvider as SessionContextProviderBase } from './context';

export type SessionContextProviderProps = {
	/**
	 * when rendering on the server for server-caching, user/session data is not available
	 * so it should also not be available at first render during hydration on the client
	 */
	sessionAvailableAtFirstRender: boolean;
};

export function SessionContextProvider({
	children,
	sessionAvailableAtFirstRender,
}: PropsWithChildren<SessionContextProviderProps>): JSX.Element {
	const [{ session, sessionUpdates }, mutations] = useSessionState();
	const firstRender = useIsFirstAppRender();
	const value = useMemo(() => {
		return {
			sync: session,
			async:
				session && (!firstRender || sessionAvailableAtFirstRender)
					? {
							status: 'loaded' as const,
							value: session,
						}
					: { status: 'loading' as const },
			sessionUpdates,
			mutations,
		};
	}, [session, sessionUpdates, sessionAvailableAtFirstRender, mutations, firstRender]);

	return <SessionContextProviderBase value={value}>{children}</SessionContextProviderBase>;
}
