import { useCallback } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import type { ReportContext } from '../../shared/types';

type Result = ModelHookResult<
	undefined,
	{
		trackReportAbuse: (context: { success: boolean }) => void;
	}
>;

export function useReportAbuseTracking({ reportContext }: { reportContext: ReportContext }): Result {
	const track = useTracking();

	return {
		actions: {
			trackReportAbuse: useCallback(
				({ success }) => {
					switch (reportContext.type) {
						case 'petition':
							void track('petition_report_abuse', {
								petition_id: reportContext.petitionId,
								successful: success,
								experiment_variation: 'variant',
							});
							return undefined;
						default:
							return undefined;
					}
				},
				[track, reportContext],
			),
		},
	};
}
