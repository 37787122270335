import { useLocation } from 'react-router';

import { Avatar, Link, ListItem, Menu, MenuItem, Separator, Text } from '@change/tomato-core-ui';
import { MenuIcon } from '@change/tomato-icons';
import { HeaderLayoutButton } from '@change/tomato-layout';

import { useI18n } from '@change-corgi/core/react/i18n';

import { useDropdown } from './hooks/useDropdown';

export const Dropdown: React.FC<Props> = ({ hideBrowseLink, mobile }) => {
	const {
		data: { avatarUrl, navLinks, atLeastIdentified, logoutUrl, userName },
	} = useDropdown({ mobile: !!mobile, hideBrowseLink });
	const { translate } = useI18n();
	const { pathname, search } = useLocation();

	// FIXME temporary until we switch to using react-aria's RouterContext
	const currentUrl = `${pathname}${search}`;

	return (
		<Menu
			triggerAriaLabel={translate('fe.components.header.menu')}
			triggerContent={
				!atLeastIdentified || mobile ? (
					// aria-label set to empty string to satisfy required property + prevent a11y warning due to aria-label on div
					<HeaderLayoutButton as="div" aria-label="" iconOnly leadingIcon={<MenuIcon />} />
				) : (
					(open) => (
						<Avatar
							src={avatarUrl}
							alt=""
							size="small"
							className="h-8 w-8"
							insetBorder
							hoverBorder
							forceHoverBorder={open}
						/>
					)
				)
			}
			placement="top right"
			contentClassName="py-0.5 w-[208px]"
			triggerClassName="rounded-full"
			triggerQaAttribute="header-dropdown-button"
			contentQaAttribute="header-dropdown-menu"
		>
			{atLeastIdentified && (
				<MenuItem
					as={Text}
					key="user-name"
					interactive={false}
					className="my-1 flex min-h-9 items-center px-3 font-bold"
					data-qa="header-user-name"
				>
					{userName}
				</MenuItem>
			)}
			{navLinks.map((navLink) => (
				<MenuItem
					as={Link}
					variant="primary"
					key={navLink.href}
					href={navLink.href}
					data-qa={navLink.qaAttribute}
					onClick={navLink.onClick}
					className="my-1 block px-1.5 no-underline hover:text-content-highlight"
					closeOnSelect={navLink.href === currentUrl}
				>
					<ListItem label={navLink.label} forceInteractive />
				</MenuItem>
			))}
			<MenuItem role="presentation" key="separator" interactive={false} className="my-1">
				<Separator className="border-stroke-secondary" />
			</MenuItem>
			{atLeastIdentified ? (
				<MenuItem
					key="logout"
					as={Link}
					variant="primary"
					href={logoutUrl}
					data-qa="header-logout-link"
					className="my-1 block px-1.5 no-underline hover:text-content-highlight"
					closeOnSelect={logoutUrl === currentUrl}
				>
					<ListItem label={translate('fe.components.header.log_out')} forceInteractive />
				</MenuItem>
			) : (
				<MenuItem
					key="login"
					as={Link}
					variant="primary"
					href="/login_or_join?user_flow=nav"
					data-qa="header-login-link"
					className="my-1 block px-1.5 no-underline hover:text-content-highlight"
					closeOnSelect={currentUrl === '/login_or_join?user_flow=nav'}
				>
					<ListItem label={translate('fe.pages.login.title')} forceInteractive />
				</MenuItem>
			)}
		</Menu>
	);
};

type Props = {
	hideBrowseLink: boolean;
	mobile?: boolean;
};
