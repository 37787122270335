import { getLegalUrl } from '@change-corgi/config/help';
import { useI18n } from '@change-corgi/core/react/i18n';

import { useLocale } from 'src/app/shared/hooks/l10n';
import { useRoutes } from 'src/app/shared/hooks/routes';

import { processLinkGroup } from './processLinkGroup';
import type { LinkGroup } from './types';
import { useCountryCodes } from './useCountryCodes';

export function useCompanyLinks(): LinkGroup | null {
	const { translate } = useI18n();
	const routes = useRoutes();
	const locale = useLocale();
	const countryCodes = useCountryCodes();

	return processLinkGroup({
		title: translate('fe.components.footer.company'),
		links: [
			{ href: routes.about, title: translate('fe.components.footer.about') },
			{
				href: getLegalUrl(locale, countryCodes),
				title: translate('fe.components.footer.legal_disclosure'),
			},
			{ href: routes.impact, title: translate('fe.components.footer.impact') },
			{ href: routes.careers, title: translate('fe.components.footer.careers') },
			{ href: '/about/team', title: translate('fe.components.footer.team') },
		],
	});
}
