import noop from 'lodash/fp/noop';

import { createStorageUtilsHooks } from './storageUtils';

type Options = Readonly<{
	/**
	 * if true, value will not be updated after it has been read once
	 *
	 * useful if the setter being called might trigger unwanted circular logic
	 */
	readOnce?: boolean;
}>;

type StorageHooks = Readonly<{
	useStorageValue: (key: string, options?: Options) => readonly [string | null, (val: string | null) => void];
	useStorageValueAsync: (
		key: string,
		options?: Options,
	) => readonly [string | null | undefined, (val: string | null) => void];
}>;

export function createStorageHooks(storage: Storage | undefined): StorageHooks {
	const { useListener, useStorageCheck, useStorageValueAsyncBase, useStorageValueBase, useStorageValueSetter } =
		createStorageUtilsHooks(storage);

	return {
		useStorageValue: (key, { readOnce } = {}) => {
			useStorageCheck();
			const [value, setValue] = useStorageValueBase(key);
			const setStorageValue = useStorageValueSetter(key);
			useListener(key, readOnce ? noop : setValue);
			return [value, setStorageValue];
		},
		useStorageValueAsync: (key, { readOnce } = {}) => {
			const [value, setValue] = useStorageValueAsyncBase();
			const setStorageValue = useStorageValueSetter(key);
			useListener(key, readOnce ? noop : setValue);
			return [value, setStorageValue];
		},
	};
}
