import Cookies from 'js-cookie';

import { resolveUrl } from '@change-corgi/core/dom';
import type { EnvironmentUtils } from '@change-corgi/core/environment';
import type { ErrorReporter } from '@change-corgi/core/errorReporter/common';
import type { FakeHistory } from '@change-corgi/core/react/router';
import type { Utilities } from '@change-corgi/core/react/utilityContext';

import type { Session } from 'src/shared/session';

import type { HydrationData } from '../types';

import { getSession } from './session';
import {
	createEventTracker,
	createExperiments,
	createFacebook,
	createFcmUtils,
	createGoogleAnalytics,
	createGoogleAuth,
	createGqlClient,
	createHttpClient,
	createNavigationUtils,
	createOptimizely,
	createUserAgentUtils,
	decorateWindowForPerimeterX,
	initOptimizely,
} from './utilities';
import { createCsrfUtils } from './utilities/csrf';

type Options = Readonly<{
	hydrationData: HydrationData | undefined;
	history: FakeHistory;
	environment: EnvironmentUtils;
	errorReporter: ErrorReporter;
}>;

type Result = Readonly<{
	utilities: Omit<Utilities, 'i18n'>;
	session: Session;
	sessionError?: true;
}>;

// eslint-disable-next-line max-lines-per-function
export async function getUtilitiesAndSession({
	hydrationData,
	history,
	environment,
	errorReporter,
}: Options): Promise<Result> {
	/**
	 * create necessary utils for session retrieval
	 */
	const gql = createGqlClient({ errorReporter, environment });
	const http = createHttpClient({ errorReporter });

	/**
	 * retrieve session
	 */
	const { session, sessionError } = await getSession({ gql, errorReporter, hydrationData });

	/**
	 * create remaining utils
	 */
	const csrf = createCsrfUtils({ errorReporter, session });
	const googleAnalytics = createGoogleAnalytics({ errorReporter, session, environment, history });
	const userAgent = createUserAgentUtils();
	const experiments = createExperiments({ gql, session });
	const fcm = createFcmUtils({ errorReporter, gql, session });
	const facebook = createFacebook({ errorReporter, session, environment });
	const optimizely = createOptimizely({ environment, fcm });
	const tracker = createEventTracker({
		csrf,
		environment,
		session,
		optimizely,
		experiments,
		userAgent,
		http,
		facebook,
	});
	const navigation = createNavigationUtils(hydrationData);
	const googleAuth = createGoogleAuth({ environment, errorReporter, userAgent });

	if (environment.getEnvironment() !== 'production') {
		decorateWindowForPerimeterX();
	}

	const utilities = {
		csrf,
		environment,
		errorReporter,
		experiments,
		facebook,
		optimizely: initOptimizely(optimizely, { tracker }),
		googleAnalytics,
		fcm,
		gql,
		http,
		tracker,
		navigation,
		userAgent,
		cookies: Cookies,
		resolveUrl,
		googleAuth,
	};

	return { utilities, session, sessionError };
}
