import type { PropsWithChildren } from 'react';

import { isLoaded } from 'src/app/shared/utils/async';

import { MembershipBottomBannerDataContextProvider } from './data';
import { MembershipBottomBannerFcmContextProvider } from './fcm';
import type { MembershipBottomBannerPrefetchedContext } from './hook';
import { useContext } from './hook';
import { useIsEligible } from './hooks/useIsEligible';
import { MembershipBottomBannerStateContextProvider } from './state';

type Props = {
	prefetchedData: MembershipBottomBannerPrefetchedContext | undefined;
};

export function MembershipBottomBannerContextProvider({
	children,
	prefetchedData,
}: PropsWithChildren<Props>): React.JSX.Element | null {
	const contextState = useContext(prefetchedData);
	const isEligible = useIsEligible();

	// not displaying a loader when in a loading state because it might result in nothing
	if (!isLoaded(contextState)) return null;

	const { data: componentData, fcm } = contextState;

	if (
		!isEligible({
			subscriptionConfig: componentData.data.subscriptionConfig,
			membershipBottomBannerEnabled: fcm.membershipBottomBannerEnabled,
		})
	)
		return null;

	return (
		<MembershipBottomBannerDataContextProvider data={componentData}>
			<MembershipBottomBannerFcmContextProvider fcm={fcm}>
				<MembershipBottomBannerStateContextProvider> {children} </MembershipBottomBannerStateContextProvider>
			</MembershipBottomBannerFcmContextProvider>
		</MembershipBottomBannerDataContextProvider>
	);
}
