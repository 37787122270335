import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { isDecisionMaker } from './decisionMaker';
import { isGdprConsentProvided } from './gdprConsentProvided';
import { isPolicyAbuseExperimentEnabled } from './isPolicyAbuseExperimentEnabled';
import { isStickySignatureBlockExperimentEnabled } from './isStickySignatureBlockExperimentEnabled';

export type PetitionDetailsPageUserData = {
	decisionMaker: boolean;
	gdprConsentProvided: boolean;
	policyAbuseExperimentEnabled: boolean;
	stickySignatureBlockExperimentEnabled: boolean;
};

export async function getPetitionDetailsPageUserData(
	utilityContext: UtilityContext,
	slugOrId: string,
	{ decisionMakerHash, isGuest }: { decisionMakerHash?: string; isGuest: boolean },
): Promise<PetitionDetailsPageUserData> {
	const [decisionMaker, gdprConsentProvided, policyAbuseExperimentEnabled, stickySignatureBlockExperimentEnabled] =
		await Promise.all([
			!isGuest || decisionMakerHash ? isDecisionMaker(utilityContext, { slugOrId, decisionMakerHash }) : false,
			isGdprConsentProvided(utilityContext),
			isPolicyAbuseExperimentEnabled(utilityContext),
			isStickySignatureBlockExperimentEnabled(utilityContext),
		]);

	return {
		decisionMaker,
		gdprConsentProvided,
		policyAbuseExperimentEnabled,
		stickySignatureBlockExperimentEnabled,
	};
}
