/* eslint-disable @typescript-eslint/consistent-indexed-object-style */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { useCallback, useEffect, useState } from 'react';

import omit from 'lodash/omit';

import type { PrefetchableQueryHookResult } from '@change-corgi/core/react/async';
import { prefetchQuery, usePrefetchableQuery } from '@change-corgi/core/react/async';
import { createMandatoryContext } from '@change-corgi/core/react/context';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { HttpError, RedirectError } from 'src/shared/error';
import type { PrefetchContext } from 'src/shared/prefetch';

import { getInitialPrefetchUserDataQueryId, usePrefetchUserDataQueryId } from 'src/app/shared/hooks/query';
import { useMappedLoadedState } from 'src/app/shared/hooks/state';
import { isLoaded } from 'src/app/shared/utils/async';

import type {
	Map,
	OptionsPrefetchable,
	PaginatedContextModelData,
	PaginatedData,
	PaginatedDataItemType,
	ResultPrefetchable,
	RethrowableErrorState,
} from './paginatedTypes';
import { useCheckHookDeps } from './useCheckHookDeps';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createContext<PAGINATED_DATA extends PaginatedData<any>, DATA_PROPERTY extends string = 'data'>(
	name: string,
	dataProperty: DATA_PROPERTY,
) {
	return createMandatoryContext<
		PaginatedContextModelData<PAGINATED_DATA>,
		{
			[key in DATA_PROPERTY]: PaginatedContextModelData<PAGINATED_DATA>;
		}
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,
	>(undefined, {
		name,
		processProviderProps: (props: {
			[key in DATA_PROPERTY]: PaginatedContextModelData<PAGINATED_DATA>;
		}): PaginatedContextModelData<PAGINATED_DATA> => props[dataProperty],
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} as any);
}

type QueryState<DATA extends Record<string, unknown>> = { items: readonly DATA[]; cursor: undefined | null | string };

function concatItems<DATA extends Record<string, unknown>>(
	queryState: QueryState<DATA>,
	data: PaginatedData<DATA>,
): PaginatedData<DATA> {
	return { ...data, items: queryState.items.concat(data.items) };
}

// TODO: try to remove this disable statement
// eslint-disable-next-line max-lines-per-function
export function createPrefetchableAsyncPaginatedDataContext<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	PAGINATED_DATA extends PaginatedData<any>,
	PARAMS extends unknown[] = [],
	DATA_PROPERTY extends string = 'data',
	ERROR extends Map = EmptyIntersection,
>({
	name,
	getUniqueId: getUniqueIdBase = () => '',
	getFirstPageData,
	getNextPageData,
	hasUserData,
	dataProperty: dataPropertyP,
	errorHandler: errorHandlerP,
	hookDeps,
	loadingBetweenQueries,
}: OptionsPrefetchable<PAGINATED_DATA, PARAMS, DATA_PROPERTY, ERROR>): ResultPrefetchable<
	PAGINATED_DATA,
	PARAMS,
	DATA_PROPERTY,
	ERROR
> {
	const dataProperty = dataPropertyP || ('data' as DATA_PROPERTY);
	const { Context, Provider, useContext } = createContext<PAGINATED_DATA, DATA_PROPERTY>(name, dataProperty);

	const useUniqueId = hasUserData
		? (...params: PARAMS) => {
				const id = getUniqueIdBase(...params);
				const queryId = usePrefetchUserDataQueryId();
				return `${id}:${queryId}`;
			}
		: (...params: PARAMS) => getUniqueIdBase(...params);

	const getUniqueId = hasUserData
		? (...params: PARAMS) => {
				const id = getUniqueIdBase(...params);
				const queryId = getInitialPrefetchUserDataQueryId();
				return `${id}:${queryId}`;
			}
		: (...params: PARAMS) => getUniqueIdBase(...params);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const errorHandler: (e: any) => ERROR = (error: any) => {
		// not catching HTTP errors that should instead be handled by the server, or the ErrorBoundary
		if (error instanceof HttpError || error instanceof RedirectError) {
			throw error;
		}
		return errorHandlerP ? errorHandlerP(error) : ({} as ERROR);
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const hookErrorHandler: (e: any) => ERROR = (error: any) => {
		try {
			return errorHandler(error);
		} catch (err) {
			// using a "hidden" field in the returned state, because the error is thrown within a useEffect
			// which would not fall into a ErrorBoundary
			// eslint-disable-next-line @typescript-eslint/naming-convention
			return { __errorToRethrow: err } satisfies RethrowableErrorState as unknown as ERROR;
		}
	};

	const res = {
		Context,
		Provider,
		useContext,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, max-lines-per-function
		useAsyncData: (prefetchedState: any, ...params: PARAMS) => {
			const utilityContext = useUtilityContext();
			const [refreshCount, setRefreshCount] = useState(0);
			const [refreshLoading, setRefreshLoading] = useState(false);

			const uniqueId = useUniqueId(...params);
			const uniqueIdWithRefresh = refreshCount ? `${uniqueId}:refresh_${refreshCount}` : uniqueId;

			const deps = hookDeps ? hookDeps(...params) : params;

			useCheckHookDeps(deps, utilityContext);

			const refreshData = useCallback(() => {
				setRefreshCount((count) => count + 1);
				setRefreshLoading(true);
			}, []);

			// query state is updated by fetchMore to trigger a new query
			const [queryState, setQueryState] = useState<QueryState<PaginatedDataItemType<PAGINATED_DATA>> | undefined>(
				undefined,
			);

			useEffect(() => {
				setQueryState((qs) => {
					return !qs ? qs : { items: [], cursor: undefined };
				});
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [...deps, uniqueIdWithRefresh]);

			const uniqueIdWithRefreshAndCursor = queryState?.cursor
				? `${uniqueIdWithRefresh}:${queryState.cursor}`
				: uniqueIdWithRefresh;

			const dataState = usePrefetchableQuery(
				async () => {
					const resp = queryState?.cursor
						? concatItems(queryState, await getNextPageData(utilityContext, queryState.cursor, ...params))
						: await getFirstPageData(utilityContext, ...params);
					setRefreshLoading(false);

					return {
						// this should be what the prefetch function returns
						[dataProperty]: resp,
					};
				},
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[utilityContext, queryState, uniqueIdWithRefreshAndCursor],
				{
					id: uniqueIdWithRefreshAndCursor,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					prefetchedState,
					errorHandler: hookErrorHandler,
					loadingBetweenQueries,
				},
			);

			const loadMore = useCallback(() => {
				if (!isLoaded(dataState) || !dataState[dataProperty].hasNextPage || !dataState[dataProperty].cursor) return;
				// updating the query state will trigger a new query (due to deps above)
				// and the logic inside the useQuery above will concat the new page to the items from the query state
				setQueryState(dataState[dataProperty]);
			}, [dataState]);

			// eslint-disable-next-line @typescript-eslint/no-explicit-any,
			const hookRes = useMappedLoadedState(dataState, (data: any) => ({
				[dataProperty]: {
					/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
					data: {
						...omit(data[dataProperty], ['cursor']),
						items: data[dataProperty].items,
						hasNextPage: data[dataProperty].hasNextPage,
						// FIXME: queryState might change without triggering this mapping to update hookRes
						// we could add support of deps in useMappedLoadedState()
						nextPageLoading: queryState?.cursor === data[dataProperty].cursor,
						refreshLoading,
					},
					/* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
					actions: {
						loadMore,
						refreshData,
					},
				},
			})) as PrefetchableQueryHookResult<
				{
					[key in DATA_PROPERTY]: PaginatedContextModelData<PAGINATED_DATA>;
				},
				ERROR
			>;

			if (hookRes.status === 'error' && (hookRes as unknown as RethrowableErrorState).__errorToRethrow) {
				throw (hookRes as unknown as RethrowableErrorState).__errorToRethrow;
			}

			return hookRes;
		},
		prefetchAsyncData: async (prefetchContext: PrefetchContext, ...params: PARAMS) => {
			return prefetchQuery(
				async () => ({ [dataProperty || 'data']: await getFirstPageData(prefetchContext.utilityContext, ...params) }),
				{
					id: getUniqueId(...params),
					errorHandler,
				},
			);
		},
	};

	return res;
}
