import type { FrameConfig } from 'src/shared/routes';

import type { DefaultLayoutProps } from './types';

/**
 * Translate a route's FrameConfig into the props that should be passed to the DefaultLayout that wraps the page.
 */
export function getLayoutProps(frame: FrameConfig | undefined): Omit<DefaultLayoutProps, 'hideBrowseLink'> {
	if (frame === true) {
		return {
			hideFooter: false,
			hideHeader: false,
		};
	}

	if (typeof frame === 'object') {
		return {
			hideFooter: !frame.footer,
			hideHeader: !frame.header,
		};
	}

	return {
		hideFooter: true,
		hideHeader: true,
	};
}
