import { useCallback } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import { useCurrentRouteConfig } from 'src/app/routes';
import { useLocale } from 'src/app/shared/hooks/l10n';

import { LOCALES_FOR_SAP_LINK } from '../../../../../../shared/consts';

type Result = ModelHookResult<
	{ isSpecialLocale: boolean; sapLink: string },
	{ trackSapNavigation: (type: 'button' | 'link') => void }
>;

export function useSAPButton(): Result {
	const track = useTracking();
	const currentLocale = useLocale();
	const isSpecialLocale = LOCALES_FOR_SAP_LINK.has(currentLocale);

	const currentRoute = useCurrentRouteConfig();
	const routeId = currentRoute?.config.id;

	return {
		data: {
			isSpecialLocale,
			sapLink: '/start-a-petition?source_location=header',
		},
		actions: {
			trackSapNavigation: useCallback(
				(type) => {
					void track('nav_sap_click', {
						link_type: type,
						route_id: routeId ?? 'unknown',
					});
				},
				[track, routeId],
			),
		},
	};
}
