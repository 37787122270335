import type { Locale } from '@change-corgi/config/locales';
import type { Utilities } from '@change-corgi/core/react/utilityContext';

import {
	SharedPetitionLocale,
	type SharedPetitionLocaleQuery,
	type SharedPetitionLocaleQueryVariables,
} from './petitionLocale.graphql';

export async function getPetitionLocale(
	slugOrId: string,
	utilities: Pick<Utilities, 'gql' | 'http'>,
): Promise<Locale | undefined> {
	const { data } = await utilities.gql.fetch<SharedPetitionLocaleQuery, SharedPetitionLocaleQueryVariables>({
		query: SharedPetitionLocale,
		variables: { slugOrId },
		rejectOnError: false,
	});

	return data?.petition?.originalLocale?.localeCode;
}
