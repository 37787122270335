import { useLocale } from 'src/app/shared/hooks/l10n';

import { processLinkGroup } from './processLinkGroup';
import type { LinkGroup } from './types';

export function useGuideLinks(): LinkGroup | null {
	const locale = useLocale();

	return locale === 'en-US'
		? processLinkGroup({
				title: 'Guides',
				links: [
					{ href: '/petition-guides', title: 'Petition Guides' },
					{ href: '/petition-guides/create-a-petition', title: 'Create Your Petition' },
					{ href: '/petition-guides/share-your-petition', title: 'Collect Signatures' },
					{ href: '/petition-guides/media', title: 'Reach out to Media' },
					{ href: '/petition-guides/engage-decision-makers', title: 'Engage Your Decision Maker' },
				],
			})
		: null;
}
