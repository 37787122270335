import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type {
	PetitionDetailsSupporterVoicesUserIsStaffOrStarterQuery,
	PetitionDetailsSupporterVoicesUserIsStaffOrStarterQueryVariables,
} from './api.graphql';
import { PetitionDetailsSupporterVoicesUserIsStaffOrStarter } from './api.graphql';

export async function userIsStaffOrStarter(utilityContext: UtilityContext, petitionSlugOrId: string): Promise<boolean> {
	const { data } = await utilityContext.gql.fetch<
		NonNullable<PetitionDetailsSupporterVoicesUserIsStaffOrStarterQuery>,
		PetitionDetailsSupporterVoicesUserIsStaffOrStarterQueryVariables
	>({
		query: PetitionDetailsSupporterVoicesUserIsStaffOrStarter,
		variables: {
			petitionSlugOrId,
		},
		rejectOnError: false,
	});

	return data?.petitionBySlugOrId?.viewerCanEdit || false;
}
