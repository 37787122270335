import { shouldPolyfill } from '@formatjs/intl-locale/should-polyfill';

import { loadPolyfill } from './polyfill';

const polyfill = (() => {
	const promises: Partial<Record<string, Promise<void>>> = {};

	return async function innerPolyfill(locale: string) {
		if (promises[locale]) {
			return promises[locale];
		}

		promises[locale] = loadPolyfill(locale, LOCALE_CHECKS.dependencies);
		return promises[locale];
	};
})();

export const LOCALE_CHECKS = {
	dependencies: [],
	checkNativeIntlFeature: (): boolean => !!window.Intl && !shouldPolyfill(),
	polyfill,
};

export const INTL_LOCALE_CHECKS = [LOCALE_CHECKS];
