import type { SessionUser } from 'src/shared/session';

import { useCurrentUserAsync, useLoginStateAsync } from 'src/app/shared/hooks/session';
import { isLoaded, isLoading } from 'src/app/shared/utils/async';

import type { SupporterVideo } from '../../types';

type Result = { canReportVideo: boolean; currentUser: SessionUser | undefined };

export function useCanReportVideo(video: SupporterVideo | null): Result {
	const currentUserBase = useCurrentUserAsync();
	const loginStateBase = useLoginStateAsync();

	let canReportVideo = false;

	if (!isLoading(currentUserBase) && !isLoading(loginStateBase) && video) {
		const isVideoOwner = currentUserBase.value?.id === video.user?.id;
		canReportVideo = !isVideoOwner && loginStateBase.value !== 'GUEST';
	}

	return {
		canReportVideo,
		currentUser: isLoaded(currentUserBase) ? currentUserBase.value : undefined,
	};
}
