import type { PrefetchUserContext } from 'src/shared/prefetch';

import { getExperimentName } from 'src/app/shared/hooks/experiment/fcm/types';

import { FCM_EXPERIMENT_DS2 } from './config';
import type { Ds2ExperimentData } from './types';

const { fcmConfigs, isEnabled, experimentName } = FCM_EXPERIMENT_DS2;

/**
 * Similar to the function returned by the `createFcmExperimentPrefetch` util, except it uses a PrefetchUserContext
 * instead of a PrefetchContext. This allows it to run at the prefetchUserData step, which works even when SSR caching
 * is enabled for the route. The downside is that it can't use the page's FCM cache.
 */
export const prefetchDs2ExperimentUserData = async (context: PrefetchUserContext): Promise<Ds2ExperimentData> => {
	const fcm = await context.utilityContext.fcm.get(fcmConfigs);

	const callbackContext = {
		fcm,
		...context.l10n,
	};

	const getVariation = async (name: string) => {
		const experiment = context.utilityContext.experiments.get(name);
		await experiment.treat();
		return experiment.variation;
	};

	const fcmEnabled = isEnabled(callbackContext);

	const name = getExperimentName(callbackContext, experimentName);

	return {
		ds2FcmExperiment: {
			fcm,
			fcmEnabled,
			name,
			variation: fcmEnabled && name ? await getVariation(name) : 'control',
		},
	};
};
