import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	CancelMembershipEnrollment,
	type CancelMembershipEnrollmentMutation,
	type CancelMembershipEnrollmentMutationVariables,
} from './api.graphql';

export async function cancelMembership(id: string, { gql: { fetch } }: UtilityContext): Promise<void> {
	const { data, errors } = await fetch<CancelMembershipEnrollmentMutation, CancelMembershipEnrollmentMutationVariables>(
		{
			query: CancelMembershipEnrollment,
			variables: { id },
			rejectOnError: false,
		},
	);

	if (errors) throw new Error(JSON.stringify(errors));
	if (!data) throw new Error('No data returned from mutation.');
	if (!data.cancelMembershipEnrollment.success)
		throw new Error(data.cancelMembershipEnrollment.errorReason ?? undefined);
}
