import React from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { Button } from '@change-corgi/design-system/components/actions';
import { iconDelete } from '@change-corgi/design-system/icons';

import type { PageRenderingButton, SupporterComment } from '../../shared/types';

import { useDeleteComment } from './hooks/useDeleteComment';

type DeleteCommentProps = {
	petitionId: string;
	comment: SupporterComment;
	renderedOn: PageRenderingButton;
	/**
	 What happens after successfully deleting a comment differs for different pages.
	 The 'afterDeleteAction' prop allows the page rendering of the delete
	 to specify what it wants to happen upon completion.
	*/
	afterDeleteAction: (commentId?: string) => void;
};
export function DeleteComment({
	petitionId,
	comment,
	renderedOn,
	afterDeleteAction,
}: DeleteCommentProps): React.JSX.Element | null {
	const {
		data: { canDeleteComment },
		actions: { handleDeleteClick },
	} = useDeleteComment({ petitionId, comment, afterDeleteAction, renderedOn });

	const { translate } = useI18n();

	if (!canDeleteComment) return null;

	return (
		<Button
			size="small"
			mode="icon"
			icon={iconDelete}
			variant="secondary"
			aria-label={translate('corgi.components.petition_video.actions.aria_label.delete_comment', {
				user_name: comment.user?.shortDisplayName,
			})}
			data-testid={`supporter-comment-${comment.id}-delete-button`}
			sx={{
				border: 'none',
				background: 'primary-greyBackground',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:hover': {
					background: 'rgba(0,0,0,0.1)',
				},
			}}
			onClick={handleDeleteClick}
		>
			Delete
		</Button>
	);
}
