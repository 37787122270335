import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { EmbeddedMedia, type EmbeddedMediaQuery, type EmbeddedMediaQueryVariables } from './getIframelyData.graphql';

type IframelyResponse = {
	html: string;
};

// https://iframely.com/docs/parameters
type IframelyOptions = Omit<EmbeddedMediaQueryVariables['input'], 'params'>;

// Params are passed to Iframely to customize style.
// Response type is something that extends IframelyResponse - Iframely might respond with different data per URL,
// but there's always at least a "html" property.
// Iframely doc: https://iframely.com/docs/oembed-api
export async function getIframelyData<RESPONSE extends IframelyResponse = IframelyResponse>(
	options: IframelyOptions,
	{ gql: { fetch } }: UtilityContext,
): Promise<RESPONSE> {
	const { data } = await fetch<EmbeddedMediaQuery>({
		query: EmbeddedMedia,
		variables: {
			input: options,
		},
		rejectOnError: true,
	});

	if (!data?.embeddedMedia || !data.embeddedMedia.success) throw new Error('media fetch failed');

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return data.embeddedMedia.objectData as RESPONSE;
}
