import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import type { SubEnvironment } from '@change-corgi/config/environments';
import { getFacebookAppId as getFacebookAppIdBase } from '@change-corgi/config/facebook';
import type { EnvironmentUtils } from '@change-corgi/core/environment';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

function getFacebookAppId(environment: EnvironmentUtils): string {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const env = (environment.getDemoEnvironment() || environment.getEnvironment()) as SubEnvironment;

	return getFacebookAppIdBase(env) || getFacebookAppIdBase('development');
}

export function MetaFacebookContainer(): JSX.Element {
	const { environment } = useUtilityContext();

	return (
		<Helmet>
			<meta property="fb:app_id" content={getFacebookAppId(environment)} />
		</Helmet>
	);
}
