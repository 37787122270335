import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	GammaPetitionDetailsPageStats,
	type GammaPetitionDetailsPageStatsQuery,
	type GammaPetitionDetailsPageStatsQueryVariables,
} from './stats.graphql';

export type QueryVariables = {
	petitionSlugOrId: string;
	maxSigners: number;
	shouldFetchRecruiter: boolean;
	recruiterId: string;
};

export type PetitionStats = NonNullable<GammaPetitionDetailsPageStatsQuery['petitionStats']>;

export async function getPetitionStats(
	petitionSlugOrId: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PetitionStats | undefined> {
	const { data } = await fetch<GammaPetitionDetailsPageStatsQuery, GammaPetitionDetailsPageStatsQueryVariables>({
		query: GammaPetitionDetailsPageStats,
		variables: {
			petitionSlugOrId,
		},
		rejectOnError: false,
	});
	if (!data?.petitionStats) {
		return undefined;
	}
	return data.petitionStats;
}
