import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	RestrictedAccessCanEditPetition,
	type RestrictedAccessCanEditPetitionQuery,
	type RestrictedAccessCanEditPetitionQueryVariables,
} from './canEdit.graphql';

export async function getCanEditPetition(utilities: Pick<UtilityContext, 'gql'>, slugOrId: string): Promise<boolean> {
	const { data } = await utilities.gql.fetch<
		RestrictedAccessCanEditPetitionQuery,
		RestrictedAccessCanEditPetitionQueryVariables
	>({
		query: RestrictedAccessCanEditPetition,
		variables: { slugOrId },
		rejectOnError: true,
	});

	return !!data?.petition?.viewerCanEdit;
}
