import type { Category, Question, QuestionSelect, ReportType, SubCategory, SubSubCategory } from '../types';

export const CATEGORIES: readonly Category[] = ['violates_guidelines', 'illegal_content', 'dislike_content'];

// This allows for questions because there are situations where instead of having a child, the field immediately has questions
type CategoryMapping = Readonly<Record<Category, QuestionSelect>>;
type SubCategoryMapping = Readonly<Record<SubCategory, QuestionSelect>>;
type SubSubCategoryMapping = Readonly<Record<SubSubCategory, Question[]>>;

const contentViolationQuestion: Question = { type: 'text', name: 'content_violation', targetField: 'description' };

const CATEGORIES_MAPPING_DEFAULTS: CategoryMapping = {
	violates_guidelines: {
		type: 'sub_category',
		value: [
			'bullying_harassment',
			'hate_discrimination',
			'defamation_libel',
			'violence',
			'selfharm_suicide',
			'graphic_imagery',
			'privacy_violations',
			'minors_protection',
			'pornography',
			'misleading_information',
			'deception_fraud',
			'excessive_edits',
			'spam',
			'intellectual_property',
		],
	},
	illegal_content: {
		type: 'sub_category',
		value: [
			'defamation_libel',
			'violence',
			'minors_protection',
			'deception_fraud',
			'intellectual_property',
			'other_illegal',
		],
	},
	dislike_content: {
		type: 'question',
		value: [{ type: 'text', name: 'additional_information', targetField: 'description' }],
	},
};

const SUB_CATEGORIES_MAPPING_DEFAULTS: SubCategoryMapping = {
	bullying_harassment: { type: 'sub_sub_category', value: ['bullying', 'vexatious'] },
	hate_discrimination: { type: 'sub_sub_category', value: ['hate_speech', 'hate_group'] },
	defamation_libel: {
		type: 'question',
		value: [
			{
				type: 'options',
				name: 'defamation_target',
				options: ['self', 'acquaintance', 'third_person', 'minor_child', 'legal_representative', 'none_above'],
				targetField: 'targeting',
			},
			contentViolationQuestion,
		],
	},
	violence: {
		type: 'question',
		value: [
			{
				type: 'options',
				name: 'select',
				options: ['potential_violence', 'terrorism'],
				targetField: 'abuseReason',
			},
			contentViolationQuestion,
		],
	},
	selfharm_suicide: {
		type: 'question',
		value: [
			{
				type: 'options',
				name: 'select',
				options: ['self_harm', 'suicide'],
				targetField: 'abuseReason',
			},
			contentViolationQuestion,
		],
	},
	graphic_imagery: {
		type: 'question',
		value: [
			{
				type: 'options',
				name: 'select',
				options: ['photo', 'video'],
				targetField: 'abuseReason',
			},
			contentViolationQuestion,
		],
	},
	privacy_violations: { type: 'sub_sub_category', value: ['pii_adult', 'pii_minor'] },
	minors_protection: {
		type: 'question',
		value: [
			{
				type: 'options',
				name: 'select',
				options: ['privacy', 'csam', 'age_restrictions'],
				targetField: 'abuseReason',
			},
			{
				type: 'options',
				name: 'minor_relation',
				options: ['self', 'parent_legal', 'legal_representative', 'unknown_minor'],
				targetField: 'minorRelation',
			},
			contentViolationQuestion,
		],
	},
	pornography: { type: 'question', value: [{ type: 'text', name: 'policy_violation', targetField: 'description' }] },
	misleading_information: {
		type: 'question',
		value: [
			{
				type: 'options',
				name: 'select',
				options: ['false_information', 'misleading_information'],
				targetField: 'abuseReason',
			},
			{
				type: 'options',
				name: 'information_type',
				options: ['elections', 'public_health', 'current_events', 'private_dispute', 'conspiracy_theory', 'none_above'],
				targetField: 'misleadingInfoDetails',
			},
			contentViolationQuestion,
		],
	},
	deception_fraud: { type: 'sub_sub_category', value: ['impersonation', 'offensive_username', 'fraudulent_link'] },
	excessive_edits: {
		type: 'question',
		value: [
			{
				type: 'options',
				name: 'change_type',
				options: ['ask_changed', 'new_info'],
				targetField: 'abuseReason',
			},
			{
				type: 'text',
				name: 'describe_change',
				targetField: 'description',
			},
		],
	},
	spam: { type: 'question', value: [contentViolationQuestion] },
	intellectual_property: {
		type: 'question',
		value: [],
	},
	other_illegal: {
		type: 'question',
		value: [
			{
				type: 'text',
				name: 'describe_illegal',
				required: true,
				targetField: 'description',
			},
		],
	},
};

const SUB_SUB_CATEGORIES_MAPPING_DEFAULTS: SubSubCategoryMapping = {
	bullying: [
		{
			type: 'options',
			name: 'identify_target',
			options: ['self', 'acquaintance', 'third_person', 'minor_child', 'legal_representative'],
			targetField: 'targeting',
		},
		contentViolationQuestion,
	],
	vexatious: [contentViolationQuestion],
	hate_speech: [
		{
			type: 'options',
			name: 'identify_target',
			options: ['protected_class', 'self', 'minor_child', 'legal_representative', 'none_above'],
			targetField: 'targeting',
		},
		contentViolationQuestion,
	],
	hate_group: [contentViolationQuestion],
	pii_adult: [
		{
			type: 'options',
			name: 'exposure_target',
			options: ['self', 'legal_representative', 'none_above'],
			targetField: 'targeting',
		},
		contentViolationQuestion,
	],
	pii_minor: [
		{
			type: 'options',
			name: 'minor_relation',
			options: ['self', 'parent_legal', 'legal_representative', 'unknown_minor'],
			targetField: 'minorRelation',
		},
		contentViolationQuestion,
	],
	impersonation: [
		{
			type: 'options',
			name: 'impersonation_target',
			options: ['self', 'acquaintance', 'third_person', 'minor_child', 'legal_representative', 'none_above'],
			targetField: 'targeting',
		},
		{ type: 'text', name: 'report_username', required: true, targetField: 'reportUsername' },
		contentViolationQuestion,
	],
	offensive_username: [
		{
			type: 'text',
			name: 'report_username',
			required: true,
			targetField: 'reportUsername',
		},
		contentViolationQuestion,
	],
	fraudulent_link: [contentViolationQuestion],
};

export const CATEGORIES_MAPPING: Readonly<Record<ReportType, CategoryMapping>> = {
	petition: CATEGORIES_MAPPING_DEFAULTS,
};

export const SUB_CATEGORIES_MAPPING: Readonly<Record<ReportType, SubCategoryMapping>> = {
	petition: SUB_CATEGORIES_MAPPING_DEFAULTS,
};

export const SUB_SUB_CATEGORIES_MAPPING: Readonly<Record<ReportType, SubSubCategoryMapping>> = {
	petition: SUB_SUB_CATEGORIES_MAPPING_DEFAULTS,
};
