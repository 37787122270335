import React from 'react';

import { ThemeProvider } from '@change/tomato-core-ui';

import { useDs2Experiment } from './useDs2Experiment';

/**
 * This components renders the Tomato theme if the DS2 FCM is enabled, otherwise the children are returned without
 * wrapping. This allows the FCM to disable our use of DS2's CSS.
 */
export const Ds2ExperimentTheme: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { fcmEnabled } = useDs2Experiment();

	if (fcmEnabled) {
		return <ThemeProvider>{children}</ThemeProvider>;
	}

	return children;
};
