import { getGoogleSignInClientId } from '@change-corgi/config/googleAuth';
import type { EnvironmentUtils } from '@change-corgi/core/environment';
import type { ErrorReporter } from '@change-corgi/core/errorReporter/common';
import type { GoogleAuth } from '@change-corgi/core/googleAuth';
import { createGoogleAuth as _createGoogleAuth } from '@change-corgi/core/googleAuth';
import type { UserAgentUtils } from '@change-corgi/core/userAgent';

export type Options = {
	errorReporter: ErrorReporter;
	environment: EnvironmentUtils;
	userAgent: UserAgentUtils;
};

export function createGoogleAuth({ errorReporter, environment, userAgent }: Options): GoogleAuth {
	const googleAuth = _createGoogleAuth({
		googleAuthClientId: getGoogleSignInClientId(environment.getEnvironment()),
		reportError: errorReporter.createSampledReporter(0.01),
		disabled: userAgent.isWebview,
	});
	void googleAuth.preload();

	return googleAuth;
}
