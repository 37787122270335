import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import { FCM_STICKY_SIGNATURE_BLOCK } from '@change-corgi/fcm/configs';

const STICKY_SIGNATURE_BLOCK_EXPERIMENT_NAME = 'sticky_signature_block_experiment';

export async function isStickySignatureBlockExperimentEnabled(utilityContext: UtilityContext) {
	const fcm = await utilityContext.fcm.get({
		stickySignatureBlockEnabled: FCM_STICKY_SIGNATURE_BLOCK,
	});
	const experiment = utilityContext.experiments.get(STICKY_SIGNATURE_BLOCK_EXPERIMENT_NAME);
	// Verify the FCM is enabled
	if (!fcm.stickySignatureBlockEnabled) return false;
	// Treating the experiment ONLY if the FCM is enabled
	void experiment.treat();
	return experiment.variation === 'sticky';
}
