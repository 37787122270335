// TODO: move to @change-corgi/config?

import type { Category, ReportType, SubCategory } from './types';

const SUBCATEGORIES_MAPPING_DEFAULTS: Readonly<Record<Category, readonly SubCategory[]>> = {
	dont_like: [],
	spam: [
		// force multi-line
		'fake_news',
		'misleading',
		'spam',
	],
	infringes_rights: [],
	abusive_hateful_content: [
		// force multi-line
		'hate_speech',
		'bullying',
		'defamatory_content',
	],
	inappropriate_images: [
		// force multi-line
		'unauthorized_use_of_my_image',
		'image_too_graphic',
	],
	child_abuse: [
		// force multi-line
		'child_pornography',
		'unauthorized_use_of_minor',
	],
	suicide_self_harm: [
		// force multi-line
		'suicide_self_harm',
		'violence_incitement',
	],
	impersonation: [
		// force multi-line
		'did_not_sign',
		'impersonation',
	],
	// DE only
};

const SUBCATEGORIES_MAPPING: Readonly<Record<ReportType, Readonly<Record<Category, readonly SubCategory[]>>>> = {
	petition: {
		...SUBCATEGORIES_MAPPING_DEFAULTS,
		spam: [
			// force multi-line
			...SUBCATEGORIES_MAPPING_DEFAULTS.spam,
			'content_changed',
		],
		infringes_rights: [
			// force multi-line
			...SUBCATEGORIES_MAPPING_DEFAULTS.infringes_rights,
			'defamatory_content',
			'plagiarism',
			'copyright_infringement',
			'trademark_infringement',
			'personal_information_exposure',
		],
	},
	comment: {
		...SUBCATEGORIES_MAPPING_DEFAULTS,
		spam: [
			// force multi-line
			...SUBCATEGORIES_MAPPING_DEFAULTS.spam,
			'against_petition',
		],
		infringes_rights: [
			// force multi-line
			...SUBCATEGORIES_MAPPING_DEFAULTS.infringes_rights,
			'defamatory_content',
			'personal_information_exposure',
		],
	},
	supporter_comment: {
		...SUBCATEGORIES_MAPPING_DEFAULTS,
		spam: [
			// force multi-line
			...SUBCATEGORIES_MAPPING_DEFAULTS.spam,
			'against_petition',
		],
		infringes_rights: [
			// force multi-line
			...SUBCATEGORIES_MAPPING_DEFAULTS.infringes_rights,
			'defamatory_content',
			'personal_information_exposure',
		],
	},
	video_comment: {
		...SUBCATEGORIES_MAPPING_DEFAULTS,
		spam: [
			// force multi-line
			...SUBCATEGORIES_MAPPING_DEFAULTS.spam,
			'against_petition',
		],
		infringes_rights: [
			// force multi-line
			...SUBCATEGORIES_MAPPING_DEFAULTS.infringes_rights,
			'defamatory_content',
			'personal_information_exposure',
		],
	},
	generic: { ...SUBCATEGORIES_MAPPING_DEFAULTS },
};

export function getSubCategories(type: ReportType, category: Category): readonly SubCategory[] {
	return SUBCATEGORIES_MAPPING[type][category];
}

const NA = 'N/A';

const SUB_CATEGORY_API_LABELS_DEFAULTS = {
	// spam
	fake_news: 'Fake news',
	spam: 'Spam',
	// infringes_rights
	defamatory_content: 'Defamatory', // in abusive_hateful_content as well
	personal_information_exposure: 'Personal Information Exposure',
	// abusive_hateful_content
	hate_speech: 'Hate Speech',
	bullying: 'Bullying or Harassment',
	// inappropriate_images
	unauthorized_use_of_my_image: 'Unauthorized Use of my Image',
	image_too_graphic: 'Image is too Graphic',
	// child_abuse
	child_pornography: 'Child Sexual Exploitation',
	unauthorized_use_of_minor: "Unauthorized Use of a Child's Photo or Name",
	// suicide_self_harm
	suicide_self_harm: 'Suicide or Self Harm',
	violence_incitement: 'Incites Violence',
	// impersonation
	did_not_sign: 'I did not sign this petition',
};

const SUB_CATEGORY_API_LABELS: Readonly<Record<ReportType, Readonly<Record<SubCategory, string>>>> = {
	petition: {
		...SUB_CATEGORY_API_LABELS_DEFAULTS,
		misleading: 'Misleading content',
		against_petition: NA,
		content_changed: 'legal___abuse___high_risk__petition_content_dramatically_changed',
		plagiarism: 'Plagiarism',
		copyright_infringement: 'Copyright Infringement',
		trademark_infringement: 'Trademark Infringement',
		impersonation: 'I Did Not Start this Petition',
	},
	comment: {
		...SUB_CATEGORY_API_LABELS_DEFAULTS,
		misleading: 'Misleading comment',
		against_petition: 'Comment goes against the petition',
		content_changed: NA,
		plagiarism: NA,
		copyright_infringement: NA,
		trademark_infringement: NA,
		impersonation: 'I Did Not Leave this Comment',
	},
	supporter_comment: {
		...SUB_CATEGORY_API_LABELS_DEFAULTS,
		misleading: 'Misleading comment',
		against_petition: 'Comment goes against the petition',
		content_changed: NA,
		plagiarism: NA,
		copyright_infringement: NA,
		trademark_infringement: NA,
		impersonation: 'I Did Not Leave this Comment',
	},
	video_comment: {
		...SUB_CATEGORY_API_LABELS_DEFAULTS,
		misleading: 'Misleading video',
		against_petition: 'Video goes against the petition',
		content_changed: NA,
		plagiarism: NA,
		copyright_infringement: NA,
		trademark_infringement: NA,
		impersonation: 'I Did Not Leave this Video',
	},
	generic: {
		...SUB_CATEGORY_API_LABELS_DEFAULTS,
		misleading: 'Misleading content',
		against_petition: NA,
		content_changed: NA,
		plagiarism: NA,
		copyright_infringement: NA,
		trademark_infringement: NA,
		impersonation: 'I Did Not Leave this Comment',
	},
};

export function getSubCategoryApiLabel(type: ReportType, category: SubCategory): string {
	return SUB_CATEGORY_API_LABELS[type][category];
}
