import type { PerimeterXParam } from '@change-corgi/config/perimeterx';
import { getPerimeterXParamVariableName } from '@change-corgi/config/perimeterx';
import { getWindow } from '@change-corgi/core/window';

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		/* eslint-disable @typescript-eslint/naming-convention */
		_pxParam1: unknown;
		_pxParam2: unknown;
		_pxParam3: unknown;
		_pxParam4: unknown;
		_pxParam5: unknown;
		_pxParam6: unknown;
		_pxParam7: unknown;
		_pxParam8: unknown;
		_pxParam9: unknown;
		_pxParam10: unknown;
		/* eslint-enable @typescript-eslint/naming-convention */
	}
}

export function setPxParamVariable(param: PerimeterXParam, value: unknown): void {
	getWindow()[getPerimeterXParamVariableName(param)] = value;
}
