import { normalizeMedia } from './normalizeMedia';
import type { ApiPetitionUpdate, PetitionInfo, PetitionUpdate } from './types';

// eslint-disable-next-line max-lines-per-function
export function normalizeUpdate(update: ApiPetitionUpdate, { slug, starter }: PetitionInfo): PetitionUpdate {
	const common = { id: update.id, createdAt: update.createdAt };

	switch (update.__typename) {
		case 'MilestonePetitionUpdate':
			return {
				...common,
				type: 'milestone',
				signatureCount: update.signatureCount,
			};
		case 'StarterPetitionUpdate':
		case 'StaffPetitionUpdate':
			return {
				...common,
				url: `/p/${slug}/u/${update.id}`,
				type: update.__typename === 'StarterPetitionUpdate' ? 'starter' : 'staff',
				title: update.title,
				description: update.description,
				photo: normalizeMedia(update),
				author: update.__typename === 'StarterPetitionUpdate' ? starter : null,
			};
		case 'FeaturedNewsPetitionUpdate':
			return {
				...common,
				url: `/p/${slug}/u/${update.id}`,
				type: 'featuredNews',
				title: update.title,
				description: update.description,
				photo: normalizeMedia(update),
				author: update.newsSource.name
					? {
							displayName: update.newsSource.name,
							photo: null,
						}
					: null,
			};
		case 'DmResponsePetitionUpdate':
			return {
				...common,
				url: `/p/${slug}/responses/${update.dmResponse.id}`,
				type: 'dmResponse',
				title: update.title,
				description: update.description,
				photo: normalizeMedia(update),
				author: {
					displayName: update.dmResponse.decisionMaker.displayName,
					photo: update.dmResponse.decisionMaker.photo?.userSmall || null,
				},
			};
		default:
			throw new Error(`Invalid update type: ${(update as ApiPetitionUpdate).__typename}`);
	}
}
