import { useMemo } from 'react';

import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { useFcm } from '../../../../shared/fcm';
import { type HeaderNavLink, useHeaderNavLinks } from '../../../../shared/hooks';

type Result = ModelHookResult<{
	showMenu: boolean;
	userIdentified: boolean;
	navLinks: readonly HeaderNavLink[];
}>;

export function useHeader({ hideBrowseLink }: { hideBrowseLink: boolean }): Result {
	const fcmState = useFcm();
	const session = useSessionAsync();

	const navLinks = useHeaderNavLinks();

	const isMembershipEnabled = isLoaded(fcmState) && fcmState.values.membershipEnabled;

	return {
		data: {
			showMenu: isLoaded(session),
			userIdentified: isLoaded(session) && session.value.loginState !== 'GUEST',
			navLinks: useMemo(
				() => [
					navLinks.myPetitions,
					...(hideBrowseLink ? [] : [navLinks.browse]),
					...(isMembershipEnabled ? [navLinks.membership] : []),
					navLinks.search,
				],
				[isMembershipEnabled, navLinks, hideBrowseLink],
			),
		},
	};
}
