import type { UseQueryResult } from '@tanstack/react-query';

import type {
	LoadedStateData,
	MappedLoadedState,
	MappedLoadedStateWithoutError,
	State,
	StateWithoutError,
} from './types';

export function isLoading(
	state: { status: 'loading' | 'pending' } | { status: string },
): state is Readonly<{ status: 'loading' | 'pending' }> {
	return state && (state.status === 'loading' || state.status === 'pending');
}

export function isError(state: { status: 'error' } | { status: string }): state is Readonly<{ status: 'error' }> {
	return state && state.status === 'error';
}

export function isLoaded(
	state: { status: 'loaded' | 'success' } | { status: string },
): state is Readonly<{ status: 'loaded' | 'success' }> {
	return state && (state.status === 'loaded' || state.status === 'success');
}

/**
 * Utility function that allows to remap loaded values from a state
 *
 * @example
 * mapLoadedStateData({ status: 'loaded', foo: 42, bar: 51 }, ({ bar, foo }) => ({ foobar: bar + foo }))
 */
function mapLoadedStateData<S extends StateWithoutError, R extends Record<string, unknown>>(
	state: S,
	mapper: (values: LoadedStateData<S>) => R,
): MappedLoadedStateWithoutError<S, R>;
function mapLoadedStateData<S extends State | UseQueryResult, R extends Record<string, unknown>>(
	state: S,
	mapper: (values: LoadedStateData<S>) => R,
): MappedLoadedState<S, R>;
function mapLoadedStateData<S extends State | UseQueryResult, R extends Record<string, unknown>>(
	state: S,
	mapper: (values: LoadedStateData<S>) => R,
): State {
	/* TODO once 'pending' and 'success' are added to State types, we can return the state from the args
	if (!isLoaded(state)) return state;
	*/
	if (isLoading(state)) return { ...state, status: 'loading' };
	if (isError(state)) return { ...state, status: 'error' };
	const { status, ...rest } = state;
	return {
		status: 'loaded',
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		...mapper(rest as unknown as LoadedStateData<S>),
	};
}

export { mapLoadedStateData };
