import type { AppRedirect } from 'src/shared/routes';

export const REDIRECTS: readonly AppRedirect[] = [
	{
		path: '/u/:slugOrId/edit',

		buildUrl: () => '/account_settings/profile',
		reason: 'deprecated-url',
	},
];
