import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import type { PetitionUpdateType } from '@change-corgi/middle-end-gql-schema';

import {
	GammaPetitionDetailsPagePetitionUpdates,
	type GammaPetitionDetailsPagePetitionUpdatesQuery,
	type GammaPetitionDetailsPagePetitionUpdatesQueryVariables,
} from './getPetitionUpdates.graphql';
import { normalizePetitionInfo } from './normalizePetitionInfo';
import { normalizeUpdates } from './normalizeUpdates';
import type { PetitionUpdate, PetitionUpdates } from './types';

type Options = Readonly<{
	count: number;
	cursor?: string;
	types?: readonly PetitionUpdateType[];
}>;

export async function getPetitionUpdates<U extends PetitionUpdate = PetitionUpdate>(
	petitionId: string,
	{ cursor, count, types }: Options,
	{ gql: { fetch } }: UtilityContext,
): Promise<PetitionUpdates<U> | undefined> {
	const { data } = await fetch<
		GammaPetitionDetailsPagePetitionUpdatesQuery,
		GammaPetitionDetailsPagePetitionUpdatesQueryVariables
	>({
		query: GammaPetitionDetailsPagePetitionUpdates,
		variables: {
			petitionId,
			count,
			types: types || ['MILESTONE', 'STARTER', 'DM_RESPONSE', 'STAFF', 'FEATURED_NEWS'],
			cursor: cursor || null,
		},
		rejectOnError: false,
		// to separate query from other batched queries
		batched: false,
	});
	const petition = data?.petition;
	if (!petition) {
		return undefined;
	}
	const { slug, organization, user } = petition;
	return normalizeUpdates<U>(petition.updatesConnection, normalizePetitionInfo({ slug, organization, user }));
}
