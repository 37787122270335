import type { FcmAccessControlConfigValueType } from '@change-corgi/fcm/configs';

import { useIdentifyUserVariation } from './useIdentifyUserVariation';

type Result = {
	isOpen: boolean;
};

export function useUserInVariant(featureAccessConfigValue: FcmAccessControlConfigValueType, variant: string): Result {
	const { actual } = useIdentifyUserVariation(featureAccessConfigValue);

	return {
		isOpen: actual === variant,
	};
}
