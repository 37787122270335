import { useCallback } from 'react';

import { useLocation } from 'react-router';

import { useNavigate } from '@change-corgi/core/react/router';
import type { TrackingProperties } from '@change-corgi/tracking-properties';

import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';
import { useCurrentUserAsync, useLoginStateAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { useCanDeleteComment } from '../../../shared/hooks/useCanDeleteComment';
import { useDeleteCommentModal } from '../../../shared/hooks/useDeleteCommentModal';
import type { PageRenderingButton, SupporterComment } from '../../../shared/types';

type Result = ModelHookResult<
	{ canDeleteComment: boolean },
	{
		handleDeleteClick: () => Promise<void>;
	}
>;

export function useDeleteComment({
	petitionId,
	comment,
	renderedOn,
	afterDeleteAction,
}: {
	petitionId: string;
	comment: SupporterComment;
	renderedOn: PageRenderingButton;
	afterDeleteAction: (commentId?: string) => void;
}): Result {
	const navigate = useNavigate();
	const { track } = useCampaignTracking();
	const canDeleteComment = useCanDeleteComment(comment);
	const openDeleteModal = useDeleteCommentModal({ petitionId, afterDeleteAction, renderedOn });

	const location = useLocation();

	const loginStateBase = useLoginStateAsync();
	const loginState = isLoaded(loginStateBase) ? loginStateBase.value : undefined;
	const currentUserBase = useCurrentUserAsync();
	const currentUser = isLoaded(currentUserBase) ? currentUserBase.value : undefined;

	const handleDeleteClick = useCallback(async () => {
		let trackingData: TrackingProperties = {
			petition_id: petitionId,
			deleter_id: currentUser?.id,
			page: renderedOn,
		};
		trackingData =
			comment.__typename === 'PetitionVideoComment'
				? { ...trackingData, video_id: comment.id, video_creator_id: comment.user?.id }
				: { ...trackingData, comment_id: comment.id, comment_creator_id: comment.user?.id };
		void track('supporter_voice_delete_button_click', trackingData);
		if (loginState === 'IDENTIFIED') {
			navigate(`/login_or_join/redirected?redirectTo=${location.pathname}`);
		} else {
			await openDeleteModal(comment);
		}
	}, [currentUser, location, loginState, navigate, openDeleteModal, petitionId, renderedOn, track, comment]);

	return {
		data: {
			canDeleteComment,
		},
		actions: {
			handleDeleteClick,
		},
	};
}
