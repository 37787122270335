import { useCallback } from 'react';

import { googleTagManagerTracking } from 'src/app/shared/utils/googleTagManagerTracking';
import type { LoginMethod, LoginOrJoinMethod, LoginType } from 'src/app/shared/utils/loginOrJoin';

import { useSocialAuthTracking } from '../useSocialAuthTracking';

import { useGetNewUserId } from './useGetNewUserId';

type TrackSuccessProps = {
	loginMethod: Extract<LoginMethod, 'facebook' | 'google'>;
	loginOrJoinMethod: Extract<LoginOrJoinMethod, 'manual_fb' | 'manual_google' | 'one_tap_google'>;
	loginType: Extract<LoginType, 'facebook_login' | 'facebook_signup' | 'google_login' | 'google_signup'>;
	newUser: boolean;
	userFlow: string;
};

export function useTrackSuccessSocial(): ({
	loginMethod,
	loginOrJoinMethod,
	loginType,
	newUser,
	userFlow,
}: TrackSuccessProps) => Promise<void> {
	const getNewUserId = useGetNewUserId();
	const { trackSignupSocial, trackSuccessfulLoginOrJoinSocial, trackFbLoginSuccess, trackGoogleConnectSuccess } =
		useSocialAuthTracking();

	const trackSuccessSocial = useCallback(
		async ({ newUser, loginMethod, loginOrJoinMethod, loginType, userFlow }: TrackSuccessProps) => {
			const currentUserId = await getNewUserId();
			if (loginMethod === 'facebook') trackFbLoginSuccess({ userId: currentUserId, newUser, userFlow });
			if (loginMethod === 'google') trackGoogleConnectSuccess({ userId: currentUserId, newUser, userFlow });

			if ((newUser && loginType === 'facebook_signup') || loginType === 'google_signup') {
				trackSignupSocial({
					userId: currentUserId,
					loginMethod,
					loginOrJoinMethod,
					// FIXME type inference could be improved here
					loginType,
					userFlow,
				});
			}

			trackSuccessfulLoginOrJoinSocial({
				newUser,
				userId: currentUserId,
				loginMethod,
				loginOrJoinMethod,
				loginType,
				userFlow,
			});

			if (userFlow === 'future_sap') {
				googleTagManagerTracking.push({ event: 'sap_flow', step: 'login_or_join' });
			}
		},
		[trackFbLoginSuccess, trackGoogleConnectSuccess, trackSignupSocial, trackSuccessfulLoginOrJoinSocial, getNewUserId],
	);

	return trackSuccessSocial;
}
