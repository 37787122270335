import { useCallback } from 'react';

import type { TrackingProperties } from '@change-corgi/tracking-properties';

import type { FormValues } from 'src/app/pages/petitionGamma/details/shared/types';
import { useQueryParamValue } from 'src/app/shared/hooks/location';
import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { usePetitionDetails } from '../../petitionDetails';

export type SignatureIntentTrackingResult = Readonly<{
	trackSignIntent: (formData: FormValues) => void;
	trackSignIntentValid: (formData: FormValues) => void;
}>;

export function useSignatureIntentTrackingData(): (formData: FormValues) => TrackingProperties {
	const petition = usePetitionDetails();
	const session = useSessionAsync();
	const recruiterUuid = useQueryParamValue('recruited_by_uuid');

	return useCallback(
		(formData: FormValues): TrackingProperties => {
			const ipCountryCode = (isLoaded(session) && session.value.ipCountry?.countryCode) || undefined;
			const geoipData = isLoaded(session) ? session.value.geoipData : undefined;
			return {
				user_country_code: formData.countryCode,
				petition_id: petition.id,
				public: formData.public,
				reasons_for_signing_completed: false,
				ip_country_code: ipCountryCode,
				ip_city: geoipData?.city || undefined,
				ip_postal_code: geoipData?.postalCode || undefined,
				ip_region: geoipData?.region || undefined,
				recruited_by_uuid: recruiterUuid,
				context: 'petitions_show',
				phone_number_filled_out: !!formData.phoneNumber,
			};
		},
		[petition, recruiterUuid, session],
	);
}
