import type { ReportableError } from '@change-corgi/core/errorReporter/common';
import type { GqlClient } from '@change-corgi/core/gql';

import {
	FeCoreSession,
	type FeCoreSessionQuery,
	type FeCoreSessionQueryVariables,
	type FeSessionUserFragment,
} from './session.graphql';

type SessionInitial = FeCoreSessionQuery['session'];
export type SessionUser = FeSessionUserFragment & {
	/**
	 * if undefined, value couldn't be retrieved due to permissions
	 *
	 * if null, value is not set in the DB
	 */
	createdAt?: string | null;
};
export type SessionUserRole = keyof Omit<
	SessionUser['roles'],
	'id' | 'countryTeam' | 'countryTeamAdmin' | '__typename'
>;
export const SESSION_USER_ROLES = Object.keys({
	audit: true,
	auditAdmin: true,
	campaigns: true,
	data: true,
	engineering: true,
	petitionAdmin: true,
	policy: true,
	policyAdmin: true,
	policySenior: true,
	product: true,
	staff: true,
	staffAdmin: true,
	support: true,
	supportAdmin: true,
	supportSenior: true,
	userResearch: true,
	communications: false,
} satisfies Record<SessionUserRole, boolean>).sort() as readonly SessionUserRole[];
export type SessionUserScopedRole = keyof Pick<SessionUser['roles'], 'countryTeam' | 'countryTeamAdmin'>;
export const SESSION_USER_SCOPED_ROLES = Object.keys({
	countryTeam: true,
	countryTeamAdmin: true,
} satisfies Record<SessionUserScopedRole, boolean>).sort() as readonly SessionUserScopedRole[];
export type SessionUserScopedRoleDetails = SessionUser['roles']['countryTeam'][0];

type BaseSession = Omit<SessionInitial, 'user' | 'userAuthOnly' | 'loginState' | 'id'>;

export type GuestSession = BaseSession &
	Readonly<{
		loginState: Extract<SessionInitial['loginState'], 'GUEST'>;
		user: null;
	}>;
// TODO possible improvement: use discriminant to only add createdAt for loginState==AUTHENTICATED
export type AuthSession = BaseSession &
	Readonly<{
		loginState: Exclude<SessionInitial['loginState'], 'GUEST'>;
		user: SessionUser;
	}>;

export type Session = GuestSession | AuthSession;

type Options = Readonly<{
	gqlFetch: GqlClient['fetch'];
	reportError: (error: ReportableError) => void;
}>;

export async function getSession({ gqlFetch, reportError }: Options): Promise<Session> {
	const response = await gqlFetch<FeCoreSessionQuery, FeCoreSessionQueryVariables>({
		batched: false,
		query: FeCoreSession,
		rejectOnError: true,
	});
	const sessionWithId = response.data?.session;
	if (!sessionWithId) {
		throw new Error('Cannot retrieve session');
	}
	const { id, ...session } = sessionWithId;
	if (session.loginState !== 'GUEST' && !session.user) {
		// should not happen => let's report it
		const invalidSession = session as GuestSession;
		reportError({
			error: `user info missing in Viewer for ${invalidSession.loginState} user`,
			params: {
				loginState: invalidSession.loginState,
				uuid: invalidSession.uuid,
			},
		});
		// assume the session to be a guest session to avoid issues
		return {
			...invalidSession,
			loginState: 'GUEST' as const,
		} as GuestSession;
	}
	if (session.user && session.userAuthOnly) {
		session.user = {
			...session.user,
			...session.userAuthOnly,
		};
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
	delete (session as any).userAuthOnly;
	return session as Session;
}
