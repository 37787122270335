import { useEffect } from 'react';

import { getLocation } from '@change-corgi/core/window';

import type { AppOptions } from 'src/app/types';

export function useWebappTrackLoad({ utilities, ssrContext, errorId }: AppOptions): void {
	useEffect(() => {
		void utilities.tracker.track('webapp_load', {
			ssr: !!ssrContext,
			pathname: getLocation().pathname,
			route_id: ssrContext?.route?.id,
			error: !!errorId,
			error_id: errorId,
			bundling_target: process.env.BUNDLING_TARGET,
		});
	}, [utilities, ssrContext, errorId]);
}
