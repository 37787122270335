import { useMemo } from 'react';

import { SearchIcon } from '@change/tomato-icons';

import { useI18n } from '@change-corgi/core/react/i18n';

export type HeaderNavLink = {
	href: string;
	label: string;
	qaAttribute: string;
	onClick?: () => void;
	leadingIcon?: React.ReactNode;
};

type Result = Record<
	'myPetitions' | 'myPetitionsFromDropdown' | 'membership' | 'browse' | 'search' | 'settings',
	HeaderNavLink
>;

export function useHeaderNavLinks(): Result {
	const { translate } = useI18n();
	return useMemo(
		() => ({
			// TODO check if we can merge those following 2 links
			myPetitions: {
				href: '/u/me',
				label: translate('fe.components.header.my_petitions'),
				qaAttribute: 'header-my-petitions-link',
			},
			myPetitionsFromDropdown: {
				href: '/u/me?source_location=my_petitions_dropdown',
				label: translate('fe.components.header.my_petitions'),
				qaAttribute: 'header-my-petitions-link',
			},
			membership: {
				href: '/member/join?source_location=member_link_header',
				label: translate('fe.components.header.membership'),
				// TODO: https://change.slack.com/archives/C080XM670A2/p1737574691735969?thread_ts=1737389161.876639&cid=C080XM670A2
				// onClick: () => undefined,
				qaAttribute: 'header-membership-link',
			},
			browse: {
				href: '/browse',
				label: translate('fe.components.header.browse'),
				// TODO: https://change.slack.com/archives/C080XM670A2/p1737574691735969?thread_ts=1737389161.876639&cid=C080XM670A2
				// onClick: () => undefined,
				qaAttribute: 'header-browse-link',
			},
			search: {
				href: '/search',
				label: translate('fe.components.header.search'),
				// TODO: https://change.slack.com/archives/C080XM670A2/p1737574691735969?thread_ts=1737389161.876639&cid=C080XM670A2
				// onClick: () => undefined,
				qaAttribute: 'header-search-link',
				leadingIcon: <SearchIcon />,
			},
			settings: {
				href: '/account_settings',
				label: translate('fe.components.header.settings'),
				qaAttribute: 'header-settings-link',
			},
		}),
		[translate],
	);
}
