import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { UserAlreadyExistsError } from '../UserAlreadyExistError';

import {
	CreateAppUser,
	type CreateAppUserFieldsFragment,
	type CreateAppUserMutation,
	type CreateAppUserMutationVariables,
} from './index.graphql';

export type User = CreateAppUserFieldsFragment['user'];

export type CreateUserParams = CreateAppUserMutationVariables['input'];

export async function createUser(
	{ firstName, lastName, email, countryCode, signupMethod, signupContext, phoneNumber }: CreateUserParams,
	{ gql: { fetch } }: UtilityContext,
): Promise<User | boolean> {
	const input = { email, firstName, lastName, countryCode, signupMethod, signupContext, phoneNumber };
	const { data, errors } = await fetch<CreateAppUserMutation, CreateAppUserMutationVariables>({
		path: '/createUser',
		query: CreateAppUser,
		variables: { input },
		rejectOnError: false,
		batched: false, // to ensure this rate limited mutation is not batched
	});
	if (errors) throw new Error(JSON.stringify(errors));
	if (!data) throw new Error('No data returned from mutation.');
	if (data.createUser.__typename === 'PasswordBlocklistedError') {
		throw new Error('PasswordBlocklistedError');
	}

	if (data.createUser.__typename === 'UserAlreadyExistsError') throw new UserAlreadyExistsError(data.createUser);
	if (data.createUser.__typename === 'CreatePendingUserSuccess') return data.createUser.success;
	return data.createUser.user;
}
