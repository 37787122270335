import { useCallback } from 'react';

import qs from 'qs';
import { useLocation } from 'react-router';

import { useNavigate } from '@change-corgi/core/react/router';
import { useTracking } from '@change-corgi/core/react/tracking';

import { useLoginStateAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { useReportAbuseConfirmModal } from '../containers/ReportAbuseConfirmModal';
import { useReportAbuseModal } from '../containers/ReportAbuseModal';
import type { ReportContext } from '../shared/types';

export function useOpenReportAbuseModal({
	v2,
	reportContext,
}: {
	v2?: boolean;
	reportContext: ReportContext;
}): () => Promise<undefined> {
	const loginState = useLoginStateAsync();
	const location = useLocation();
	const navigate = useNavigate();
	const openResultModal = useReportAbuseModal();
	const openConfirmModal = useReportAbuseConfirmModal();
	const track = useTracking();

	return useCallback(async (): Promise<undefined> => {
		// non-auth users must authenticate before reporting
		// so we're redirecting to the login page, with a redirection back to this page
		if (!isLoaded(loginState) || loginState.value !== 'AUTHENTICATED') {
			const redirectUrl = `/login_or_join?${qs.stringify(
				{ user_flow: 'report_abuse', redirect_to: `${location.pathname}${location.search}` },
				{ encodeValuesOnly: true },
			)}`;
			navigate(redirectUrl);
			return;
		}

		const { type } = reportContext;
		void track('report_abuse_click', {
			type,
			experiment_variation: v2 ? 'variant' : 'control',
			// This property is used to monitor the v2 experiment
			...(type === 'petition' ? { petition_id: reportContext.petitionId } : {}),
		});
		const { source } = await openResultModal({ v2, reportContext }).waitForClosed();

		if (source === 'result') {
			openConfirmModal({});
		}
	}, [location, loginState, navigate, openConfirmModal, openResultModal, reportContext, track, v2]);
}
