import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	RestrictedAccessCountryTeamRoleUserId,
	type RestrictedAccessCountryTeamRoleUserIdQuery,
	type RestrictedAccessCountryTeamRoleUserIdQueryVariables,
} from './getUserCountryCode.graphql';

export async function getUserCountryCode(
	utilities: Pick<UtilityContext, 'gql'>,
	userSlugOrId: string,
): Promise<string | undefined> {
	const { data } = await utilities.gql.fetch<
		RestrictedAccessCountryTeamRoleUserIdQuery,
		RestrictedAccessCountryTeamRoleUserIdQueryVariables
	>({
		query: RestrictedAccessCountryTeamRoleUserId,
		variables: { userSlugOrId },
		rejectOnError: true,
	});

	return data?.user?.country?.countryCode || undefined;
}
