import type {
	FormValues,
	NormalizedFormValues,
	SignFormExperiments,
} from 'src/app/pages/petitionGamma/details/shared/types';

import { isGdprEnabled } from '../isGdprEnabled';

export function normalizeGdprConsent(
	{ countryCode, gdprConsent }: Pick<FormValues, 'countryCode' | 'gdprConsent'>,
	experiments: SignFormExperiments,
): NormalizedFormValues['marketingCommsConsent'] {
	const gdpr = isGdprEnabled(countryCode, experiments);
	const { copy, overridden } = experiments.gdpr;
	return gdpr
		? {
				consentGiven: gdprConsent === 'true',
				name: overridden ? 'global_consent_initial_eu_exp_control' : 'global_consent_initial_eu_default',
				text: copy.prompt || copy.opt_in,
			}
		: null;
}
