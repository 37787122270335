import type { Utilities } from '@change-corgi/core/react/utilityContext';

import {
	PetitionDetailsPageSetRecruiter,
	type PetitionDetailsPageSetRecruiterMutation,
	type PetitionDetailsPageSetRecruiterMutationVariables,
} from './setRecruiter.graphql';

export async function setPetitionRecruiter(
	input: PetitionDetailsPageSetRecruiterMutationVariables['input'],
	utilities: Pick<Utilities, 'gql' | 'errorReporter'>,
): Promise<void> {
	try {
		await utilities.gql.fetch<
			PetitionDetailsPageSetRecruiterMutation,
			PetitionDetailsPageSetRecruiterMutationVariables
		>({
			query: PetitionDetailsPageSetRecruiter,
			variables: {
				input,
			},
			rejectOnError: false,
		});
	} catch (e) {
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		void utilities.errorReporter.report(new Error(`Error storing petition recruiter data: ${e}`));
	}
}
