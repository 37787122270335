import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	TrackPromotionShare,
	type TrackPromotionShareMutation,
	type TrackPromotionShareMutationVariables,
} from './api.graphql';

type Input = { token: string; placement?: string; algorithm?: string; countryCode: string };
type QueryData = TrackPromotionShareMutation;
type QueryVariables = TrackPromotionShareMutationVariables;

export async function trackPromotionShare(
	{ token, placement, algorithm, countryCode }: Input,
	{ gql: { fetch } }: UtilityContext,
	{
		silent,
	}: {
		// if true, won't throw on error
		silent?: boolean;
	} = {},
): Promise<void> {
	let data: QueryData | null | undefined;
	try {
		const result = await fetch<QueryData, QueryVariables>({
			query: TrackPromotionShare,
			variables: {
				input: {
					eventType: 'SHARE',
					promotionToken: token,
					placement: placement || null,
					algorithm,
					countryCode,
				},
			},
			rejectOnError: false,
			batched: false,
		});
		({ data } = result);
	} catch (err) {
		if (silent) return;
		throw new Error('Promotion tracking failed');
	}

	if (silent) return;
	if (!data?.trackPromotedEvent.success) {
		throw new Error('Promotion tracking failed');
	}
}
