import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	DeleteSupporterVideo,
	type DeleteSupporterVideoMutation,
	type DeleteSupporterVideoMutationVariables,
} from './index.graphql';

export async function deleteSupporterVideo({
	videoId,
	utilityContext: {
		gql: { fetch },
	},
}: {
	videoId: string;
	utilityContext: UtilityContext;
}): Promise<DeleteSupporterVideoMutation | null | undefined> {
	const { data: deleteResult } = await fetch<
		NonNullable<DeleteSupporterVideoMutation>,
		DeleteSupporterVideoMutationVariables
	>({
		query: DeleteSupporterVideo,
		variables: {
			input: {
				commentId: videoId,
			},
		},
		rejectOnError: false,
		batched: false,
	});
	return deleteResult;
}
