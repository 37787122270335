import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { RedirectError } from 'src/shared/error';

import { getPetitionId } from 'src/app/shared/api/petitions';

import type { SupporterVoicesConfig } from '../supporterVoicesConfig/types';

import { PetitionPageVideos, type PetitionPageVideosQuery, type PetitionPageVideosQueryVariables } from './api.graphql';

export type SupporterVoicesData = {
	supporterVoicesEnabled: boolean;
	videos?: PetitionPageVideosQuery | null;
	openVideoCreationFromPetitionDetails?: boolean;
};

export const getSupporterVoices = async (
	utilityContext: UtilityContext,
	slugOrId: string,
	supporterVoicesConfig: SupporterVoicesConfig,
	disableDisplay?: boolean | null,
): Promise<SupporterVoicesData> => {
	let petitionId: string;

	if (Number(slugOrId)) petitionId = slugOrId;
	else petitionId = (await getPetitionId(slugOrId, utilityContext)) || '';

	if (!petitionId) throw new RedirectError({ url: '/?petition_not_found=true' });

	/**
	 * The supporter_voices_petition_details FCM is fetched first and if the feature should be
	 * disabled for the user, the fetch for the supporter videos is not called.
	 */
	if (!supporterVoicesConfig.supporterVoicesEnabled || disableDisplay) {
		return {
			supporterVoicesEnabled: false,
		};
	}

	const { data: videos } = await utilityContext.gql.fetch<PetitionPageVideosQuery, PetitionPageVideosQueryVariables>({
		query: PetitionPageVideos,
		variables: { petitionId, pageOffset: 0, pageSize: 10, orderBy: 'voting_score' },
		rejectOnError: false,
		batched: false,
	});

	return {
		videos,
		...supporterVoicesConfig,
	};
};
