import { useMemo } from 'react';

import blackHistory from 'src/assets/wordmark/blackHistory.svg';
import climateStrike from 'src/assets/wordmark/climateStrike.svg';
import pride from 'src/assets/wordmark/pride.svg';

import { useFcm } from 'src/app/app/layout/shared/fcm';
import { isLoading } from 'src/app/shared/utils/async';

type Result = ModelHookResult<{
	logoUrl: string | undefined;
}>;

export function useLogo(): Result {
	const fcmState = useFcm();

	return {
		data: {
			logoUrl: useMemo(() => {
				if (isLoading(fcmState)) return undefined;
				if (fcmState.values.showBlackHistoryLogo) return blackHistory;
				if (fcmState.values.showPrideLogo) return pride;
				if (fcmState.values.showClimateStrikeLogo) return climateStrike;
				return undefined;
			}, [fcmState]),
		},
	};
}
