import { useMemo } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { isMobile } from 'src/app/shared/utils/userAgent';

export function useIsMobile(): boolean {
	const utilityContext = useUtilityContext();

	return useMemo(() => isMobile(utilityContext), [utilityContext]);
}
