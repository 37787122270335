import { CustomError } from 'ts-custom-error';

export class UserAlreadyExistsError extends CustomError {
	readonly userId: string;
	readonly userPasswordSet: boolean;

	constructor({ userId, userPasswordSet }: { userId: string; userPasswordSet: boolean }) {
		super('User already exists');
		this.userId = userId;
		this.userPasswordSet = userPasswordSet;
	}
}
