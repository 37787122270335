import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { SharedUserCurrency, type SharedUserCurrencyQuery, type SharedUserCurrencyQueryVariables } from './api.graphql';

export type CurrencyInfo = Omit<
	NonNullable<NonNullable<SharedUserCurrencyQuery['country']>['currency']>,
	'id' | '__typename'
>;

export async function getUserCurrency(countryCode: string, { gql: { fetch } }: UtilityContext): Promise<CurrencyInfo> {
	const { data } = await fetch<SharedUserCurrencyQuery, SharedUserCurrencyQueryVariables>({
		query: SharedUserCurrency,
		variables: { countryCode },
		rejectOnError: false,
	});

	if (!data?.country?.currency) return { code: 'USD', exchangeRate: 1 };

	return { code: data.country.currency.code, exchangeRate: data.country.currency.exchangeRate };
}
