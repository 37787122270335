import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import type { LoginState } from '@change-corgi/middle-end-gql-schema';

import type { PhoneNumber } from 'src/app/shared/utils/phoneCollection';

import { ViewerPhoneNumber, type ViewerPhoneNumberQuery, type ViewerPhoneNumberQueryVariables } from './api.graphql';

export type PhoneNumberData = {
	phoneNumber: PhoneNumber;
	serverLoginState: LoginState;
};

export async function getViewerPhoneNumber(
	currentUserLoginState: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PhoneNumberData | undefined> {
	// return early if the current user is not authenticated
	if (currentUserLoginState !== 'AUTHENTICATED') return undefined;

	const { data, errors } = await fetch<ViewerPhoneNumberQuery, ViewerPhoneNumberQueryVariables>({
		query: ViewerPhoneNumber,
		rejectOnError: false,
	});

	const serverLoginState = data?.viewer?.loginState;

	// if the server's login state is not authenticated, then there will be no user
	if (serverLoginState !== 'AUTHENTICATED') return undefined;

	// if the user is authenticated, but some errors happened we just return undefined
	if (errors) return undefined;

	// user is authenticated and there are no other errors, return the (maybe) phone number
	return { phoneNumber: data?.viewer?.user?.phoneNumber, serverLoginState };
}
