import type { Utilities, UtilityContext } from '@change-corgi/core/react/utilityContext';

import { TopicFromSlug, type TopicFromSlugQuery } from './index.graphql';

export async function getTopicSlug(
	utilities: UtilityContext | Pick<Utilities, 'gql'>,
	slug: string,
): Promise<{ slug: string | undefined }> {
	const { data } = await utilities.gql.fetch<TopicFromSlugQuery>({
		query: TopicFromSlug,
		variables: { slug },
		rejectOnError: false,
	});
	return { slug: data?.topic?.slug ?? undefined };
}
