import { withI18nPlaceholders } from '@change-corgi/core/react/i18n';

import I18N_PLACEHOLDERS from 'config/i18n/placeholders/mobileAppDownload/bottom-banner.yml';

import { Banner } from '../component/Banner';

type AppDownloadBottomBannerProps = {
	sourceLocation: string;
	ownPetition: boolean;
	appDownloadBottomBannerEnabled: boolean;
};

export const AppDownloadBottomBanner = withI18nPlaceholders(
	I18N_PLACEHOLDERS,
	function AppDownloadBottomBannerEntry({
		sourceLocation,
		ownPetition,
		appDownloadBottomBannerEnabled,
	}: AppDownloadBottomBannerProps): React.JSX.Element | null {
		if (!ownPetition || !appDownloadBottomBannerEnabled) return null;

		return <Banner sourceLocation={sourceLocation} />;
	},
);
