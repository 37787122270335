import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { getPetitionStarterFreePromotions, type PetitionStarterFreePromotionsResponse } from './api';
import { isQueryEnabled } from './utils';

const REFETCH_INTERVAL_MS: number = 1000;

type PollPetitionStarterFreePromotionsParams = {
	utilityContext: UtilityContext;
	slugOrId: string;
	pollingStartTime: number;
	data: PetitionStarterFreePromotionsResponse | undefined;
};

export async function pollPetitionStarterFreePromotions({
	utilityContext,
	slugOrId,
	pollingStartTime,
	data,
}: PollPetitionStarterFreePromotionsParams) {
	if (isQueryEnabled(pollingStartTime, data)) {
		const startTime = Date.now();
		const response = await getPetitionStarterFreePromotions(utilityContext, { slugOrId });
		const endTime = Date.now();
		const remainingTime = REFETCH_INTERVAL_MS - (endTime - startTime);

		if (remainingTime > 0) {
			// eslint-disable-next-line promise/avoid-new
			await new Promise((resolve) => {
				setTimeout(resolve, remainingTime);
			});
		}

		return pollPetitionStarterFreePromotions({
			utilityContext,
			slugOrId,
			pollingStartTime,
			data: response,
		});
	}
	return data;
}
