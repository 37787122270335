const MAX_POLLING_TIME: number = 10000;
const MIN_POLLING_TIME: number = 5000;

export function isQueryEnabled(
	pollingStartTime: number,
	data: ReadonlyArray<Readonly<Record<string, unknown>>> | undefined,
): boolean {
	const timeElapsed = Date.now() - pollingStartTime;
	const isTimeout = timeElapsed >= MAX_POLLING_TIME;
	const isDataAvailable = timeElapsed >= MIN_POLLING_TIME && data;

	return !isTimeout && !isDataAvailable;
}
