import { useCallback, useEffect } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import type { CountryBannerConfig, CountryBannerContext } from 'src/app/shared/hooks/banners/country';

export function useTrackCountryBannerView(
	countryBanner: CountryBannerConfig,
	context: CountryBannerContext,
	petitionId?: string,
): void {
	const track = useTracking();

	useEffect(() => {
		const bannerPetitionId = countryBanner.banner_petition_id;
		void track('country_banner_view', {
			petition_id: petitionId,
			context,
			banner_petition_id: bannerPetitionId || undefined,
		});
	}, [track, petitionId, countryBanner, context]);
}

export function useCountryBannerClickTracking(
	countryBanner: CountryBannerConfig,
	context: CountryBannerContext,
	petitionId?: string,
): () => void {
	const track = useTracking();

	return useCallback(() => {
		const bannerPetitionId = countryBanner.banner_petition_id;
		void track('country_banner_click', {
			petition_id: petitionId,
			context,
			banner_petition_id: bannerPetitionId || undefined,
		});
	}, [track, petitionId, countryBanner, context]);
}
