import type { FocusFallbackListenerContext } from '../types';

const OBSERVER_CONFIG = { childList: true };

export type ListenerContext = Pick<FocusFallbackListenerContext, 'removedElement' | 'removedFocusedElement'> & {
	element: HTMLElement;
};

function contains(parentNode: Node, node: Node) {
	try {
		return parentNode.contains(node);
	} catch (e) {
		// IE11 doesn't provide a contains() method on some elements (e.g. SVGElement)
		return false;
	}
}

export function listenToElementRemoval(focusElt: Node, listener: (context: ListenerContext) => void): () => void {
	/* As the focusout event is not triggered on Safari when the focused element is removed from the DOM, we have to use a MutationObserver instead */
	if (typeof MutationObserver === 'undefined') {
		// just so that older browsers don't fail here

		return () => {};
	}
	/* eslint-disable @typescript-eslint/consistent-type-assertions */
	const observer = new MutationObserver((mutationsList) => {
		for (const mutation of mutationsList) {
			if (mutation.type !== 'childList') return;

			const focusEltRemovedChildElt = Array.from(mutation.removedNodes).find((n) =>
				contains(n, focusElt),
			) as HTMLElement;

			if (focusEltRemovedChildElt)
				listener({
					element: mutation.target as HTMLElement,
					removedElement: focusEltRemovedChildElt,
					removedFocusedElement: focusElt as HTMLElement,
				});
		}
	});
	/* eslint-enable @typescript-eslint/consistent-type-assertions */

	// observe the full parent tree of the focused element
	let elt = focusElt;
	while (elt.parentNode) {
		observer.observe(elt.parentNode, OBSERVER_CONFIG);
		elt = elt.parentNode;
	}

	return () => {
		observer.disconnect();
	};
}
