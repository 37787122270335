import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	SetPhoneNumberAndConsent,
	type SetPhoneNumberAndConsentMutation,
	type SetPhoneNumberAndConsentMutationVariables,
} from './api.graphql';

export type SetPhoneNumberAndConsentInput = SetPhoneNumberAndConsentMutationVariables['input'];

export async function setPhoneNumberAndConsent(
	{ phoneNumber, consentResponse }: SetPhoneNumberAndConsentInput,
	{ gql: { fetch } }: UtilityContext,
): Promise<boolean> {
	const { data } = await fetch<SetPhoneNumberAndConsentMutation, SetPhoneNumberAndConsentMutationVariables>({
		query: SetPhoneNumberAndConsent,
		variables: {
			input: {
				phoneNumber,
				consentResponse: {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					text: consentResponse.text,
					consentGiven: consentResponse.consentGiven,
					name: consentResponse.name,
				},
			},
		},
		rejectOnError: false,
	});

	return !!data?.setPhoneNumberAndConsent.success;
}
