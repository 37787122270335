import type { Utilities, UtilityContext } from '@change-corgi/core/react/utilityContext';

import type {
	PetitionIdFromSlugQuery,
	PetitionIdFromSlugQueryVariables,
	PetitionSlugFromIdQuery,
	PetitionSlugFromIdQueryVariables,
} from './petition.graphql';
import { PetitionIdFromSlug, PetitionSlugFromId } from './petition.graphql';

export async function getPetitionId(
	slug: string,
	utilities: UtilityContext | Pick<Utilities, 'gql'>,
): Promise<string | undefined> {
	const { data } = await utilities.gql.fetch<PetitionIdFromSlugQuery, PetitionIdFromSlugQueryVariables>({
		query: PetitionIdFromSlug,
		variables: { slug },
		rejectOnError: false,
	});
	return data?.petition?.id ?? undefined;
}

export async function getPetitionSlug(
	slugOrId: string,
	// Utilities support is currently necessary for this to be called by route config
	utilities: UtilityContext | Pick<Utilities, 'gql'>,
): Promise<string | undefined> {
	const { data } = await utilities.gql.fetch<PetitionSlugFromIdQuery, PetitionSlugFromIdQueryVariables>({
		query: PetitionSlugFromId,
		variables: { slugOrId },
		rejectOnError: false,
	});
	return data?.petition?.slug ?? undefined;
}
