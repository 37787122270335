import { useMemo } from 'react';

import type { SignPetitionInput } from 'src/app/shared/api/signature';
import { useCurrentUserAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

export function useCurrentUserSignData(): Pick<
	SignPetitionInput,
	'firstName' | 'lastName' | 'email' | 'city' | 'postalCode' | 'stateCode' | 'countryCode' | 'phoneNumber'
> {
	const currentUserState = useCurrentUserAsync();

	return useMemo(() => {
		if (!isLoaded(currentUserState) || !currentUserState.value) {
			return {
				firstName: '',
				lastName: '',
				email: '',
				city: '',
				postalCode: '',
				stateCode: '',
				countryCode: '',
				// Phone number input is currently only enabled for guests
				phoneNumber: null,
			};
		}

		const { country, city, stateCode, postalCode, firstName, lastName, email } = currentUserState.value;

		// weirdly, non-guest users are allowed to sign without a city/postalCode/stateCode/countryCode, while guests cannot...
		return {
			firstName,
			lastName,
			email,
			city: city || '',
			postalCode: postalCode || '',
			stateCode: stateCode || '',
			countryCode: country?.countryCode || '',
			// Phone number input is currently only enabled for guests
			phoneNumber: null,
		};
	}, [currentUserState]);
}
