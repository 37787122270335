import type { Locale } from '@change-corgi/config/locales';

export const LOCALES_FOR_SAP_LINK = new Set<Locale>([
	//
	'tr-TR',
	'pt-PT',
	'ja-JP',
	'pt-BR',
	'ru-RU',
	'fr-FR',
	'de-DE',
]);
