import { useMemo } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import { useTrackingCommonProps } from './useTrackingCommonProps';

type Result = {
	trackLoginOrJoinAttemptSocial: (context: {
		loginOrSignupContext: 'combined';
		loginOrJoinMethod: 'manual_fb' | 'manual_google' | 'one_tap_google';
		hasSetPassword: boolean;
		userFlow: string;
	}) => void;
};

export function useLoginOrJoinAttemptTracking(): Result {
	const track = useTracking();
	const getCommonProps = useTrackingCommonProps();

	return useMemo(
		() => ({
			trackLoginOrJoinAttemptSocial: ({ loginOrSignupContext, loginOrJoinMethod, hasSetPassword, userFlow }) => {
				void track('login_or_join_attempt', {
					...getCommonProps(),
					login_or_join_method: loginOrJoinMethod,
					context: loginOrSignupContext,
					has_set_password: hasSetPassword,
					user_flow: userFlow,
				});
			},
		}),
		[getCommonProps, track],
	);
}
