import { shareCopy } from 'src/app/shared/share/copy';
import { shareEmail } from 'src/app/shared/share/email';
import { shareFacebook } from 'src/app/shared/share/facebook';
import { shareFacebookMessenger } from 'src/app/shared/share/facebookMessenger';
import { shareFlyer } from 'src/app/shared/share/flyer';
import { shareInstagram } from 'src/app/shared/share/instagram';
import { shareLine } from 'src/app/shared/share/line';
import { shareNative } from 'src/app/shared/share/mobileNativeShare';
import { shareNextdoor } from 'src/app/shared/share/nextdoor';
import { shareQRCode } from 'src/app/shared/share/qrcode';
import { shareReddit } from 'src/app/shared/share/reddit';
import { shareSms } from 'src/app/shared/share/sms';
import { shareTwitter } from 'src/app/shared/share/twitter';
import { shareVk } from 'src/app/shared/share/vk';
import { shareWhatsapp } from 'src/app/shared/share/whatsapp';

import type { ShareChannel } from '../../shared/types';
import type { ShareActionContext } from '../actionContext';

type ShareActionOptions = {
	url: string;
	text: Readonly<{
		title: string;
		body: string;
	}>;
	trackingEventName: string;
	trackingData: Readonly<Record<string, boolean | string | number | undefined>>;
};

type ShareAction = (options: ShareActionOptions, context: ShareActionContext) => Promise<boolean>;

const SHARE_ACTIONS: Readonly<Record<ShareChannel, ShareAction>> = {
	facebook: async ({ url, trackingData, trackingEventName }, { utilityContext, track }) =>
		shareFacebook(
			url,
			{
				trackingData,
				trackingEvents: {
					success: trackingEventName,
					intent: `${trackingEventName}_intent`,
					cancel: `${trackingEventName}_cancel`,
					failure: `${trackingEventName}_failure`,
				},
			},
			utilityContext,
			track,
		),
	facebookMessenger: async ({ url, trackingData, trackingEventName }, { utilityContext, track }) => {
		shareFacebookMessenger(
			url,
			{
				trackingData,
				trackingEvents: {
					success: trackingEventName,
				},
			},
			utilityContext,
			track,
		);
		return Promise.resolve(true);
	},
	twitter: async ({ url, text: { title }, trackingData, trackingEventName }, { track, countryCode, locale }) => {
		shareTwitter(
			url,
			title,
			{
				trackingData,
				trackingEvents: {
					success: trackingEventName,
				},
				countryCode,
				locale,
			},
			track,
		);
		return Promise.resolve(true);
	},
	whatsapp: async ({ url, text: { title }, trackingData, trackingEventName }, { track }) => {
		shareWhatsapp(
			title,
			url,
			{
				trackingData,
				trackingEvents: {
					success: trackingEventName,
				},
			},
			track,
		);
		return Promise.resolve(true);
	},
	reddit: async ({ url, text: { title }, trackingData, trackingEventName }, { utilityContext, track }) => {
		await shareReddit(
			title,
			url,
			{
				trackingData,
				trackingEvents: {
					success: trackingEventName,
				},
			},
			track,
			utilityContext.i18n,
		);
		return Promise.resolve(true);
	},
	vk: async ({ url, text: { title }, trackingData, trackingEventName }, { track }) => {
		shareVk(
			url,
			title,
			{
				trackingData,
				trackingEvents: {
					success: trackingEventName,
				},
			},
			track,
		);
		return Promise.resolve(true);
	},
	line: async ({ url, text: { title }, trackingData, trackingEventName }, { track }) => {
		shareLine(
			url,
			title,
			{
				trackingData,
				trackingEvents: {
					success: trackingEventName,
				},
			},
			track,
		);
		return Promise.resolve(true);
	},
	email: async ({ text: { title, body }, trackingData, trackingEventName }, { track }) => {
		shareEmail(
			title,
			body,
			{
				trackingData,
				trackingEvents: {
					success: trackingEventName,
				},
			},
			track,
		);
		return Promise.resolve(true);
	},
	sms: async ({ url, text: { title }, trackingData, trackingEventName }, { utilityContext, track }) => {
		shareSms(
			url,
			title,
			{
				trackingData,
				trackingEvents: {
					success: trackingEventName,
				},
			},
			utilityContext,
			track,
		);
		return Promise.resolve(true);
	},
	instagram: async (
		{ url, trackingData, trackingEventName },
		{ track, utilityContext, instagram: { openEducationModal } },
	) =>
		shareInstagram(
			url,
			{
				trackingData,
				trackingEvents: {
					success: trackingEventName,
					intent: 'instagram_share_story_modal_view',
					continue: 'instagram_share_story_modal_continue',
					cancel: 'instagram_share_story_modal_close',
				},
				openEducationModal,
			},
			track,
			utilityContext.i18n,
		),
	nextdoor: async ({ url, text: { title }, trackingData, trackingEventName }, { track }) => {
		shareNextdoor(
			url,
			title,
			{
				trackingData,
				trackingEvents: {
					success: trackingEventName,
				},
			},
			track,
		);
		return Promise.resolve(true);
	},
	copy: async ({ url, trackingData, trackingEventName }, { utilityContext, track }) =>
		shareCopy(
			url,
			{
				trackingData,
				trackingEvents: {
					success: trackingEventName,
					failure: `${trackingEventName}_failure`,
				},
			},
			track,
			utilityContext.i18n,
		),
	mobileNativeShare: async ({ url, trackingData, trackingEventName }, { track }) =>
		shareNative(
			url,
			{
				trackingData,
				trackingEvents: {
					success: trackingEventName,
					failure: `${trackingEventName}_failure`,
				},
			},
			track,
		),
	qrcode: async ({ trackingData, trackingEventName }, { track }) => {
		shareQRCode({ trackingData, trackingEvents: { success: trackingEventName } }, track);
		return Promise.resolve(true);
	},
	flyer: async ({ trackingData, trackingEventName }, { track }) => {
		shareFlyer({ trackingData, trackingEvents: { success: trackingEventName } }, track);
		return Promise.resolve(true);
	},
};

export function getShareAction(channel: ShareChannel): ShareAction {
	return SHARE_ACTIONS[channel];
}
