import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	AdminEditTagsPageTags,
	type AdminEditTagsPageTagsQuery,
	type AdminEditTagsPageTagsQueryVariables,
} from './getPetitionTags.graphql';

export type PartialPetition = NonNullable<AdminEditTagsPageTagsQuery['petition']>;

export async function getPetitionTags(
	slug: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PartialPetition | undefined> {
	const { data } = await fetch<AdminEditTagsPageTagsQuery, AdminEditTagsPageTagsQueryVariables>({
		query: AdminEditTagsPageTags,
		variables: { slug },
		rejectOnError: false,
	});

	return data?.petition ?? undefined;
}
