import { getCountryInfo } from '@change-corgi/config/countries';

import type {
	Category,
	FormValues,
	NormalizedFormValues,
	PetitionReport,
	PetitionReportContext,
	QuestionAnswer,
	Report,
	ReportContext,
	ReportType,
	SubCategory,
	SubSubCategory,
} from '../../shared/types';

type CategoryLabels = Record<Category, string>;
type SubCategoryLabels = Record<SubCategory, string>;
type SubSubCategoryLabels = Record<SubSubCategory, string>;
type QuestionLabels = Record<Category | SubCategory | SubSubCategory, Record<string, string>>;

const CATEGORY_API_LABELS: Readonly<Record<ReportType, Readonly<CategoryLabels>>> = {
	petition: {
		violates_guidelines: "The content violates Change.org's Community Guidelines",
		illegal_content: 'The content is illegal',
		dislike_content: 'I dislike or disagree with this content',
	},
};

const SUB_CATEGORY_API_LABELS: Readonly<Record<ReportType, Readonly<SubCategoryLabels>>> = {
	petition: {
		bullying_harassment: 'Bullying or Harassment',
		hate_discrimination: 'Hate or Discrimination',
		defamation_libel: 'Defamation or Libel',
		violence: 'Violence',
		selfharm_suicide: 'Self-Harm or Suicide',
		graphic_imagery: 'Graphic or Disturbing Imagery',
		privacy_violations: 'Privacy Violations (Adult)',
		minors_protection: 'Protection of Minors',
		pornography: 'Pornography or Sexualized Content',
		misleading_information: 'False or Misleading Information',
		deception_fraud: 'Deception or Fraud',
		excessive_edits: 'Excessive Petition Edits',
		spam: 'Spam and Spam-Like Behavior',
		intellectual_property: 'Intellectual Property Infringement',
		other_illegal: 'Other Illegal Content or Activity',
	},
};

const SUB_SUB_CATEGORY_API_LABELS: Readonly<Record<ReportType, Readonly<SubSubCategoryLabels>>> = {
	petition: {
		bullying: 'Bullying or Harassment',
		vexatious: 'Petition is vexatious',
		hate_speech: 'Hate Speech or Discriminatory',
		hate_group: 'Content affiliated with hate groups',
		pii_adult: 'Personal Information Exposure (Adult)',
		pii_minor: 'Violates privacy of a minor',
		impersonation: "Profile created using someone else's identity",
		offensive_username: "Account's username intentionally offensive/abusive",
		fraudulent_link: 'Links to fraudulent website/fundraiser',
	},
};

const QUESTION_API_LABELS: Readonly<Record<ReportType, Readonly<QuestionLabels>>> = {
	petition: {
		defamation_libel: {
			self: "It's targeting me",
			acquaintance: "It's targeting someone I know",
			third_person: "It's targeting someone I don't know personally",
			minor_child: 'Parent/guardian of child being targeted',
			legal_representative: 'I am the legal representative for the person being targeted',
			none_above: 'Other',
		},
		violence: {
			potential_violence: 'Incites Violence',
			terrorism: 'Contains terrorist content',
		},
		selfharm_suicide: {
			self_harm: 'Petition promotes self-harm',
			suicide: 'Petition promotes suicide',
		},
		graphic_imagery: {
			photo: 'Graphic imagery (photo)',
			video: 'Graphic imagery (video)',
		},
		minors_protection: {
			privacy: 'Violates privacy of a minor',
			csam: 'Contains child sexual abuse material (CSAM)',
			age_restrictions: 'Violates age-specific restrictions',
			self: 'I am the minor',
			parent_legal: "I am the minor's parent or legal guardian",
			legal_representative: "I am acting as the minor's legal representative",
			unknown_minor: 'I do not know the minor',
		},
		misleading_information: {
			false_information: 'Contains false information',
			misleading_information: 'Misleading content',
			elections: 'Elections',
			public_health: 'Public Health',
			current_events: 'Current Events',
			private_dispute: 'Private Dispute',
			conspiracy_theory: 'Conspiracy Theory',
			none_above: 'Other',
		},
		excessive_edits: {
			ask_changed: "Petition's main ask has changed",
			new_info: 'Same ask but new info added',
		},
		bullying: {
			self: "It's targeting me",
			acquaintance: "It's targeting someone I know",
			third_person: "It's targeting someone I don't know personally",
			minor_child: 'Parent/guardian of child being targeted',
			legal_representative: 'I am the legal representative for the person being targeted',
		},
		hate_speech: {
			protected_class: "It's targeting an entire protected class of people",
			self: "It's targeting me",
			minor_child: 'Parent/guardian of child being targeted',
			legal_representative: 'I am the legal representative for the person being targeted',
		},
		pii_adult: {
			self: 'My personal information is exposed',
			legal_representative: 'I am a legal representative for the person whose info is exposed',
			none_above: 'Other',
		},
		pii_minor: {
			self: 'I am the minor',
			parent_legal: "I am the minor's parent or legal guardian",
			legal_representative: "I am acting as the minor's legal representative",
			unknown_minor: 'I do not know the minor',
		},
		impersonation: {
			self: 'Someone is impersonating me',
			acquaintance: 'Someone I know personally is being impersonated',
			third_person: "A person I don't know personally is being impersonated",
			minor_child: 'I am the parent or legal guardian of the minor who is being impersonated',
			legal_representative: 'I am the legal representative for the person being targeted',
			none_above: 'Other',
		},
		// These currently don"t need to be handled because they are either text only, or they"re already handled by their children
		violates_guidelines: {},
		illegal_content: {},
		dislike_content: {},
		vexatious: {},
		hate_group: {},
		offensive_username: {},
		fraudulent_link: {},
		bullying_harassment: {},
		hate_discrimination: {},
		privacy_violations: {},
		pornography: {},
		deception_fraud: {},
		spam: {},
		intellectual_property: {},
		other_illegal: {},
	},
};
export function getCategoryApiLabel(type: ReportType, category: Category): string {
	return CATEGORY_API_LABELS[type][category];
}

export function getSubCategoryApiLabel(type: ReportType, subCategory: SubCategory): string {
	return SUB_CATEGORY_API_LABELS[type][subCategory];
}

export function getSubSubCategoryApiLabel(type: ReportType, subSubCategory: SubSubCategory): string {
	return SUB_SUB_CATEGORY_API_LABELS[type][subSubCategory];
}

function getNormalizedAnswer(type: ReportType, { answer, targetField, parent }: QuestionAnswer): string {
	switch (targetField) {
		case 'targeting':
		case 'abuseReason':
		case 'minorRelation':
		case 'misleadingInfoDetails':
			return QUESTION_API_LABELS[type][parent][answer] || '';
		case 'reportUsername':
		case 'description':
		default:
			return answer;
	}
}

function processQuestions(
	type: ReportType,
	questions: Pick<FormValues, 'question1' | 'question2' | 'question3'>,
): Partial<
	Pick<
		NormalizedFormValues,
		'abuseReason' | 'targeting' | 'minorRelation' | 'misleadingInfoDetails' | 'reportUsername' | 'description'
	>
> {
	return Object.entries(questions).reduce((acc, [_key, questionAnswer]) => {
		if (!questionAnswer.answer) return acc;
		const normalizedAnswer = getNormalizedAnswer(type, questionAnswer);
		return { ...acc, [questionAnswer.targetField]: normalizedAnswer };
	}, {});
}

export function normalizeFormValues(type: ReportType, formValues: FormValues): NormalizedFormValues {
	const country = formValues.country ? getCountryInfo(formValues.country) : undefined;

	return {
		country: country ? country.display : '',
		reportingReason: formValues.category ? getCategoryApiLabel(type, formValues.category) : '',
		// The only situation where subCategory can be empty is when the Category is "I dislike or disagree with this content",
		// therefore we set the abuseCategory with that same value so Salesforce can properly process it
		abuseCategory: formValues.subCategory
			? getSubCategoryApiLabel(type, formValues.subCategory)
			: CATEGORY_API_LABELS[type].dislike_content,
		abuseReason: formValues.subSubCategory ? getSubSubCategoryApiLabel(type, formValues.subSubCategory) : '',
		// Adding this so all the properties have a value instead of undefined
		targeting: '',
		minorRelation: '',
		misleadingInfoDetails: '',
		reportUsername: '',
		description: '',
		...processQuestions(type, formValues),
	};
}

const petitionPayload = (reportContext: PetitionReportContext): PetitionReport => ({
	petitionId: reportContext.petitionId,
	petitionUrl: `/p/${reportContext.petitionSlug}`,
});

export function normalizePayload(reportContext: ReportContext): Report {
	switch (reportContext.type) {
		case 'petition':
			return petitionPayload(reportContext);
		default:
			throw new Error('Unsupported report context type');
	}
}
