import type { FormValues, NormalizedFormValues } from 'src/app/pages/petitionGamma/details/shared/types';

import { isPostalCodeRequired } from '../isPostalCodeRequired';

export function normalizePostalCode({
	countryCode,
	postalCode,
}: Pick<FormValues, 'countryCode' | 'postalCode'>): NormalizedFormValues['postalCode'] {
	return (isPostalCodeRequired({ countryCode }) && postalCode) || null;
}
