import { useCallback, useEffect, useMemo, useState } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import { Button } from '@change-corgi/design-system/components/actions';
import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { createModalHook } from '@change-corgi/design-system/modals';
import { Heading, Text } from '@change-corgi/design-system/typography';
import type { TrackingProperties } from '@change-corgi/tracking-properties';

import { deleteSupporterVideo } from 'src/app/shared/api/supporterVoices';
import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';
import { useCurrentUserAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { DeleteMessage } from '../../../components/DeleteMessage';
import type { PageRenderingButton, SupporterComment } from '../../types';

export const useDeleteCommentModalBase = createModalHook<
	{
		petitionId: string;
		comment: SupporterComment;
		renderedOn: PageRenderingButton;
	},
	{ value: 'success' | 'error' | undefined }
>({
	name: 'DeleteCommentConfirmationModal',
	heading: ({ options: { comment } }) => {
		return (
			<Heading as="h1" size="h5" mb={16}>
				<Translate
					value="corgi.components.petition_video.delete.heading"
					replacements={{
						type: comment.__typename === 'PetitionVideoComment' ? 'Video' : 'Comment',
					}}
				/>
			</Heading>
		);
	},
	body: function DeleteModalBody({ options: { comment, petitionId, renderedOn }, closeModal }) {
		const [isDeleting, setIsDeleting] = useState(false);
		const [deleteMessage, setDeleteMessage] = useState<'success' | 'error' | undefined>(undefined);

		const utilityContext = useUtilityContext();

		const { track, trackError } = useCampaignTracking();
		const currentUser = useCurrentUserAsync();
		const userId = isLoaded(currentUser) ? currentUser?.value?.id : undefined;

		useEffect(() => {
			if (deleteMessage === 'success') {
				setTimeout(() => {
					closeModal({ value: deleteMessage });
				}, 1000);
			}
		}, [deleteMessage, closeModal]);

		const trackingData = useMemo(() => {
			let tData: TrackingProperties = {
				petition_id: petitionId,
				deleter_id: userId,
				page: renderedOn,
			};
			tData =
				comment.__typename === 'PetitionVideoComment'
					? { ...tData, video_id: comment.id, video_creator_id: comment.user?.id }
					: { ...tData, comment_id: comment.id, comment_creator_id: comment.user?.id };
			return tData;
		}, [petitionId, comment, renderedOn, userId]);

		const handleCancel = useCallback(() => {
			track('supporter_voices_delete_modal_cancel_button_click', {
				...trackingData,
			});
			closeModal({ value: deleteMessage });
		}, [closeModal, deleteMessage, track, trackingData]);

		const handleDelete = useCallback(async () => {
			track('supporter_voices_delete_modal_delete_button_click', {
				...trackingData,
			});
			setIsDeleting(true);
			try {
				const deleteResult = await deleteSupporterVideo({ videoId: comment.id, utilityContext });
				if (deleteResult?.deleteComment?.success) {
					setDeleteMessage('success');
					track('supporter_voice_delete_success', {
						...trackingData,
					});
				} else {
					setDeleteMessage('error');
					track('supporter_voice_delete_error', {
						...trackingData,
						...(deleteResult?.deleteComment?.reason ? { errors: deleteResult.deleteComment.reason } : {}),
					});
				}
			} catch (err) {
				setDeleteMessage('error');
				trackError('supporter_voice_delete_error', err, { params: trackingData });
			} finally {
				setIsDeleting(false);
			}
		}, [comment, track, trackError, trackingData, utilityContext]);

		return (
			<Box data-testid="delete-confirmation" sx={{ flexDirection: 'column' }}>
				<Text as="p" mb={32}>
					<Translate
						value="corgi.components.petition_video.delete.title"
						replacements={{
							type: comment.__typename === 'PetitionVideoComment' ? 'video' : 'comment',
							id: comment.id,
						}}
					/>
				</Text>
				<Flex sx={{ justifyContent: 'center' }}>{isDeleting && <Loader mb={16} data-testid="delete-loader" />}</Flex>
				<DeleteMessage message={deleteMessage} comment={comment} />
				{deleteMessage !== 'success' && (
					<Flex sx={{ width: '100%', flexDirection: ['column', 'row'], gap: 16, justifyContent: 'flex-end' }}>
						<Button
							variant="secondaryEmphasis"
							data-testid="confirm-cancel-btn"
							onClick={handleCancel}
							disabled={isDeleting}
						>
							<Translate value="corgi.components.petition_video.delete.cancel" />
						</Button>
						<Button
							variant="primary"
							data-testid="confirm-delete-btn"
							onClick={handleDelete}
							disabled={isDeleting}
							sx={{ textTransform: 'uppercase' }}
						>
							<Translate value="corgi.components.petition_video.delete.confirm" />
						</Button>
					</Flex>
				)}
			</Box>
		);
	},

	closeButton: false,
	closeOnClickOutside: false,
});
