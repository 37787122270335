import type { ChangeEvent } from 'react';
import { useCallback, useMemo } from 'react';

import { translationKey } from '@change-corgi/core/i18n';

import { useFormValueSetter } from 'src/app/shared/form/mutators';
import type { I18nFieldValidator } from 'src/app/shared/form/validators';
import { requiredValidator } from 'src/app/shared/form/validators';

import { useQuestionLabel } from '../../../../../../../../hooks/useQuestionLabel';
import { useQuestionOptionsLabel } from '../../../../../../../../hooks/useQuestionOptionsLabel';
import type { FieldType, FormValues, OptionsQuestion, ReportType } from '../../../../../../../../shared/types';

type Result = ModelHookResult<
	{
		fieldName: keyof FormValues;
		questionLabel: string;
		validator: I18nFieldValidator<string | undefined> | undefined;
	},
	{
		getQuestionOptionsLabel: ReturnType<typeof useQuestionOptionsLabel>['actions']['getQuestionOptionsLabel'];
		changeFieldValue: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	}
>;

type Props = {
	type: ReportType;
	parent: FieldType;
	question: OptionsQuestion;
	idx: number;
};
export function useOptionsQuestionField({ type, parent, question, idx }: Props): Result {
	const {
		data: { questionLabel },
	} = useQuestionLabel(type, parent, question.name);
	const {
		actions: { getQuestionOptionsLabel },
	} = useQuestionOptionsLabel(type, parent, question.name);

	const validator = useMemo(() => {
		if (question.required)
			return requiredValidator({
				i18n: { key: translationKey('corgi.policy_abuse.generic.errors.question.required') },
			});
		return undefined;
	}, [question.required]);

	// This is used by the form to know which property we're accessing
	const fieldName = `question${idx}` as keyof FormValues;

	const valueSetter = useFormValueSetter();
	const changeFieldValue = useCallback(
		(event: ChangeEvent<HTMLSelectElement>) => {
			event.preventDefault();
			valueSetter(fieldName, {
				answer: event.target.value,
				parent,
				targetField: question.targetField,
			});
		},
		[fieldName, parent, question.targetField, valueSetter],
	);

	return {
		data: {
			fieldName,
			questionLabel,
			validator,
		},
		actions: {
			getQuestionOptionsLabel,
			changeFieldValue,
		},
	};
}
