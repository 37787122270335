import { Field } from 'react-final-form';

import { Input } from '@change-corgi/design-system/components/forms';
import { Box } from '@change-corgi/design-system/layout';

import { useFormI18n } from 'src/app/shared/form/hooks';

import type { FieldType, ReportType, TextQuestion } from '../../../../../../shared/types';

import { useTextQuestionField } from './hooks/useTextQuestionField';

type Props = {
	type: ReportType;
	parent: FieldType;
	question: TextQuestion;
	idx: number;
};

export const TextQuestionField: React.FC<Props> = ({ type, parent, question, idx }) => {
	const { getErrorMessage } = useFormI18n();
	const {
		data: { fieldName, questionLabel, validator },
		actions: { changeFieldValue },
	} = useTextQuestionField({ type, parent, question, idx });

	return (
		<Box>
			<Field<string | undefined> name={`${fieldName}.answer`} validate={validator}>
				{({ input, meta }) => (
					<Input
						name={input.name}
						value={input.value}
						onChange={changeFieldValue}
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
						error={meta.touched && getErrorMessage(meta.error)}
						label={questionLabel}
						data-testid="reportAbuse-text-input"
						data-qa="reportAbuse-text-input"
					/>
				)}
			</Field>
		</Box>
	);
};
