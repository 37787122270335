import { useMemo } from 'react';

import qs from 'qs';

import type { NonBetaLocale } from '@change-corgi/config/locales';
import { getLocalesInfo } from '@change-corgi/config/locales';
import { getLocation } from '@change-corgi/core/window';

import { useLocale } from 'src/app/shared/hooks/l10n';

import { getCurrentYear } from '../../../../shared/date-utils';
import { type FooterLinkGroup, useFooterLinks } from '../../../../shared/hooks';

type Result = ModelHookResult<
	{
		links: readonly FooterLinkGroup[];
		currentLocale: string;
		currentYear: number;
		locales: ReadonlyArray<{ label: string; value: NonBetaLocale }>;
	},
	{
		handleLocaleChange: (newLocale: string) => void;
	}
>;

const handleLocaleChange = (newLocale: string) => {
	const location = getLocation();
	const search = qs.parse(location.search, { ignoreQueryPrefix: true });
	search.lang = newLocale;
	location.assign(`${location.origin}${location.pathname}?${qs.stringify(search)}${location.hash}`);
};

export function useFooter(): Result {
	return {
		data: {
			currentLocale: useLocale(),
			locales: useMemo(
				() => getLocalesInfo('nonBeta').map(({ locale, display }) => ({ label: display, value: locale })),
				[],
			),
			currentYear: useMemo(() => getCurrentYear(), []),
			links: useFooterLinks(),
		},
		actions: {
			handleLocaleChange,
		},
	};
}
