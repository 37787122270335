import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

import { handleTopicsRedirect } from './utils';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'tag',
		path: '/t/:slug',
		beforeServerRender: handleTopicsRedirect,
		component: loadable(async () => import(/* webpackChunkName: "pages/tag" */ './index'), {
			exportName: 'Tag',
			fallback: Loading,
		}),
		frame: true,
		ssr: {
			// switch this to true to enable SSR
			enabled: true,
			seo: true,
		},
	},
];
