/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */

import { getWindowSafe } from '@change-corgi/core/window';

function getFontsReadyPromise(): Promise<void> | undefined {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return (getWindowSafe() as any)?.document?.fonts?.ready as Promise<void> | undefined;
}

const FONTS_READY_SUPPORTED = !!getFontsReadyPromise();

export async function waitForFonts(): Promise<true> {
	if (!FONTS_READY_SUPPORTED) {
		return Promise.resolve(true);
	}

	await getFontsReadyPromise();
	return true;
}
