import { QueryClient } from '@tanstack/react-query';

export function getQueryClient(): QueryClient {
	return new QueryClient({
		defaultOptions: {
			queries: {
				// disable refetching when window is re-focused
				refetchOnWindowFocus: false,
				// disable refetching if the app reconnects from an offline state
				refetchOnReconnect: false,
				// ignore stale-time when component mounts and always perform a fetch, regardless of what is in the cache
				refetchOnMount: 'always',
				// disable retries of failed queries
				retry: false,
				// default is 0
				staleTime: 0,
				// default is 5 minutes, setting to zero disables caching
				gcTime: 0,
			},
		},
	});
}
