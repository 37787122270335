import { useCallback, useMemo } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import { useTrackView } from 'src/app/shared/hooks/tracking';

type Result = ModelHookResult<
	undefined,
	{
		onClickClose: () => void;
		onClickDownload: () => void;
	}
>;

export function useBanner({
	sourceLocation,
	setBannerClosed,
}: {
	sourceLocation: string;
	setBannerClosed: () => void;
}): Result {
	const commonProperties = useMemo(
		() => ({
			context: sourceLocation,
			content: 'announcement_banner',
			content_version: 'track_your_petitions_growth',
		}),
		[sourceLocation],
	);
	const track = useTracking();

	useTrackView('app_promo_banner_view', commonProperties);

	const onClickClose = useCallback(() => {
		void track('app_promo_banner_closed', commonProperties);
		setBannerClosed();
	}, [track, setBannerClosed, commonProperties]);

	const onClickDownload = useCallback(() => {
		void track('app_promo_banner_click', commonProperties);
	}, [track, commonProperties]);

	return {
		actions: {
			onClickClose,
			onClickDownload,
		},
	};
}
