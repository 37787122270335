import cloneDeep from 'lodash/cloneDeep';

import type { CsrfUtils } from '@change-corgi/core/csrf';
import { createFakeHistory } from '@change-corgi/core/react/router';
import { getLocation } from '@change-corgi/core/window';

import { shouldUseFrontEndServerTranslations } from 'config/shouldUseFrontEndServerTranslations';

import type { Session } from 'src/shared/session';

import { createEmotionCache } from 'src/app/shared/emotion';

import type { CsrAppOptions, HydrationData } from '../types';

import { decorateWindowWithInfo } from './info';
import { setupIntl } from './intl';
import { createEnvironmentUtils } from './utilities/environment';
import { createErrorReporter } from './utilities/errorReporter';
import { getQueryClient } from './utilities/getQueryClient';
import { setupI18n } from './utilities/setupI18n';
import { getUtilitiesAndSession } from './utilitiesAndSession';

export type BootstrapResults = CsrAppOptions &
	Readonly<{
		error: boolean;
	}>;

async function setupCsrf(csrf: CsrfUtils, session: Session) {
	// making sure we have a fresh CSRF token for event tracking
	// as the one from SSR might have expired when browser is only doing a client-side refresh
	// TODO: [improvement] do not make this call when session is retrieved client-side?
	const token = await csrf.getCsrfToken({ nocache: true });
	if (!token) {
		// refresh the token to the one from the session, to avoid forcing a new retrieval attempt
		// that could impact tracking efficiency
		// it's also consistent with fe
		// TODO: we might want to move that behavior to getCsrfToken as an option
		csrf.refreshCsrfToken(session.csrfToken);
	}
}

// eslint-disable-next-line max-lines-per-function, complexity
export async function bootstrap({ hydrationData }: { hydrationData?: HydrationData }): Promise<BootstrapResults> {
	decorateWindowWithInfo();

	// temporarily using a memory history that is being updated by router.subscribe()
	const history = createFakeHistory({
		initialEntries: [getLocation().pathname + getLocation().search],
	});

	const environment = createEnvironmentUtils();
	const errorReporter = createErrorReporter({ environment });

	const { utilities, session, sessionError } = await getUtilitiesAndSession({
		hydrationData,
		history,
		environment,
		errorReporter,
	});

	const { http, csrf } = utilities;

	const [{ i18n, i18nError }] = await Promise.all([
		setupI18n({
			errorReporter,
			session,
			http,
			environment,
			useServerTranslations: shouldUseFrontEndServerTranslations(),
			translationsIdentifier: hydrationData?.translationsIdentifier,
			translations: hydrationData?.translations,
		}),
		setupIntl({ session }),
		setupCsrf(csrf, session),
	]);

	const {
		country: { countryCode },
		locale: { localeCode: locale },
		loginState,
	} = session;

	errorReporter.setSessionInfo({ countryCode, locale, loginState });

	// for an explanation of how caching works: https://tanstack.com/query/latest/docs/framework/react/guides/caching
	// disabling query options to match our existing behavior
	const queryClient = getQueryClient();

	return {
		session,
		ssr: false,
		history,
		utilities: { ...utilities, i18n },
		l10n: { locale, countryCode },
		error: !!sessionError || !!i18nError,
		// cloning the cache in a deep manner because we are mutating the cache in the code
		// to optimize the react rendering lifecycle
		// unless we clone the cache, we would end up mutating __HYDRATION_DATA__, which could be confusing
		cache: cloneDeep(hydrationData?.cache),
		prefetchedSession: hydrationData?.session,
		prefetchedData: hydrationData?.prefetchedData,
		prefetchedUserData: hydrationData?.prefetchedUserData,
		errorId: hydrationData?.errorId,
		errorStatus: hydrationData?.errorStatus,
		ssrContext: hydrationData?.ssrContext,
		emotionCache: createEmotionCache(),
		queryClient,
		dehydratedQueryClient: hydrationData?.dehydratedQueryClient,
		hideBrowseLink: hydrationData?.hideBrowseLink,
		// if no hydration data, there was no server-side rendering, so the session should be available at first render
		// if hydration data, but no session within it, it means there was server-caching, so the session should not be available at first render
		sessionAvailableAtFirstRender: !hydrationData || !!hydrationData.session,
	};
}
