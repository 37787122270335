import JSONCrush from 'jsoncrush';
import { nanoid } from 'nanoid';

import { toBase64Url } from 'src/app/shared/utils/base64url';

export function generateAiSapParam(): string {
	const stepState = {
		id: nanoid(),
		petition: {
			aiPromptChoice: 'ai',
		},
		metadata: {
			currentStepIndex: 0,
			currentSubStepIndex: 0,
			direction: 'forward',
			steps: [
				{
					name: 'reach',
				},
				{
					name: 'location',
				},
				{
					name: 'ai_description',
				},
				{
					name: 'ai_results_draft',
				},
				{
					name: 'ask',
				},
				{
					name: 'description',
				},
				{
					name: 'media',
				},
				{
					name: 'phone_collection',
				},
				{
					name: 'publish',
				},
			],
			stepsKey: 'reach,location,ai_description,ai_results_draft,ask,description,media,phone_collection,publish',
		},
	};
	return toBase64Url(JSONCrush.crush(JSON.stringify(stepState)));
}
