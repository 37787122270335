import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	AdminEditTagsPageAddTagsToPetition,
	type AdminEditTagsPageAddTagsToPetitionMutation,
	type AdminEditTagsPageAddTagsToPetitionMutationVariables,
} from './addTagsToPetition.graphql';

// FIXME should return AdminEditTagsPageAddTagsToPetitionMutation['addTagsToPetition']
export async function addTagsToPetition(
	{ petitionId, tags }: AdminEditTagsPageAddTagsToPetitionMutationVariables,
	utilityContext: UtilityContext,
): Promise<AdminEditTagsPageAddTagsToPetitionMutation> {
	const {
		gql: { fetch },
	} = utilityContext;
	const { data, errors } = await fetch<
		AdminEditTagsPageAddTagsToPetitionMutation,
		AdminEditTagsPageAddTagsToPetitionMutationVariables
	>({
		query: AdminEditTagsPageAddTagsToPetition,
		variables: { petitionId, tags },
		rejectOnError: false,
	});

	if (errors) throw new Error(JSON.stringify(errors));
	if (!data) throw new Error('No data returned from mutation.');

	return data;
}
