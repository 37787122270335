import { getLastChildElementRecursive } from './getLastChildElementRecursive';

type Options = Readonly<{
	checkContentsFit: () => boolean;
	ellipsisEl: HTMLElement;
	contentsEl: HTMLElement;
}>;

// eslint-disable-next-line complexity
export function getElementToTruncate({ checkContentsFit, ellipsisEl, contentsEl }: Options): {
	elementToTruncate: Text | undefined;
	reset: () => void;
} {
	// Remove elements until we get the one that causes us to get within acceptable size
	let child: Node | undefined;
	let parent: Node | undefined;

	const ellipsisParent = ellipsisEl.parentNode;

	const resets: Array<() => void> = [() => ellipsisParent?.appendChild(ellipsisEl)];

	while (!checkContentsFit() || (child && !child.nodeValue)) {
		ellipsisEl.remove();
		child = getLastChildElementRecursive(contentsEl);
		parent = child.parentNode || undefined;
		parent?.removeChild(child);
		parent?.appendChild(ellipsisEl);

		const currentParent = parent;
		const currentChild = child;
		resets.unshift(() => currentParent?.appendChild(currentChild));
	}

	// Add that element back and start working on its text content
	ellipsisEl.remove();
	child && parent?.appendChild(child);
	parent?.appendChild(ellipsisEl);
	resets.shift();

	return {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		elementToTruncate: child?.nodeType === Node.TEXT_NODE ? (child as Text) : undefined,
		reset: () => resets.forEach((reset) => reset()),
	};
}
