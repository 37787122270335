import { createFcmExperimentsContext } from 'src/app/shared/contexts/experiment';
import type { LoadedStateData } from 'src/app/shared/utils/async';

export const {
	FcmExperimentsContext: MembershipBottomBannerFcmExperimentsContext,
	FcmExperimentsProvider: MembershipBottomBannerFcmExperimentsContextProvider,
	useFcmExperiments: useMembershipBottomBannerFcmExperiments,
	useAsyncFcmExperiments: useAsyncMembershipBottomBannerFcmExperiments,
	prefetchFcmExperiments: prefetchMembershipBottomBannerFcmExperiments,
} = createFcmExperimentsContext({
	name: 'MembershipBottomBannerFcmExperimentsContext',
	dataProperty: 'fcmExperiments',
	configs: {},
	prefetch: true,
});

export type MembershipBottomBannerFcmExperimentsState = ReturnType<typeof useAsyncMembershipBottomBannerFcmExperiments>;
export type MembershipBottomBannerFcmExperiments = LoadedStateData<MembershipBottomBannerFcmExperimentsState>;
